import Config from 'common/Config';
import RouteUtil from 'common/RouteUtil';
import API from 'common/API';
import store from 'store/index';
import {configLoaded, configLoadError, requestConfig} from 'store/root/rootActions';

class RootService {
    async getConfiguration(params = null, additionalHeaders = null) {
        try {
            store.dispatch(requestConfig());
            const endpoint = API.resolveRoute('auth', 'config');
            const configResponse = await API.request(endpoint.url, params, endpoint.method, false, additionalHeaders);
            store.dispatch(configLoaded(configResponse));
            return true;
        }
        catch (e) {
            store.dispatch(configLoadError(e));
            return false;
        }
    }
}

export default new RootService();
