import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {CircularProgress, Dialog, withStyles} from '@material-ui/core';
import UISelector from 'store/ui/UISelectors';
import {connect} from 'react-redux';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';

import LogoSpinner from 'views/components/LogoSpinner';

function Loading(props) {
    let {message} = props;
    const ns = useRef(['messages', 'common', 'mfa']);
    const {nonblocking, theme, classes} = props;

    // add necessary namespaces
    if (message?.includes(':')) {
        const translationNS = message.split(':')[0];
        if (!ns.current.includes(translationNS)) {
            ns.current.push(translationNS);
        }
    }
    if (isArray(message)) {
        const [text, params] = message;
        const translationNS = [];

        if (text.includes(':')) {
            translationNS.push(text.split(':')[0]);
        }
        if (params) {
            Object.values(params).forEach(param => {
                if (param.includes(':')) {
                    translationNS.push(param.split(':')[0]);
                }
            });
        }

        ns.current = uniq(ns.current.concat(translationNS));
    }

    const {t} = useTranslation(ns.current);

    if (message && isArray(message)) {
        let [text, params] = message;
        params = params && Object.keys(params).map(p => ({[p]: t(params[p])})).reduce((a, b) => ({...a, ...b}), {});
        message = t(text, {...params});
    }

    return (
        <Dialog
            classes={{
                paper: classes.paper
            }}
            open={Boolean(message)}
            hideBackdrop={nonblocking}
            disableEscapeKeyDown
        >
            {message ? (
                <div className={classes.messageContainer}>
                    <div
                        style={{
                            position: 'relative',
                            padding: theme.spacing(0, 1, 0, 2)
                        }}
                    >
                        <LogoSpinner
                            classes={{root: classes.logoSpinner}}
                        />
                    </div>
                    <div
                        className={classes.message}
                        dangerouslySetInnerHTML={{__html: t(message)}}
                    />
                </div>
            ) : (
                <span></span>
            )}
        </Dialog>
    );
}

const styles = (theme) => ({
    root: {},
    paper: {
        borderRadius: theme.shape.borderRadius * 9,
        overflow: 'hidden',
        padding: theme.spacing(0, 1)
    },
    messageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        padding: theme.spacing(2)
    },
    message: {
        paddingLeft: theme.spacing()
    },
    logoSpinner: {
        position: 'relative',
        left: theme.spacing(0.5),
        width: theme.spacing(5),
        height: theme.spacing(5)
    }
});

Loading.defaultProps = {
    nonblocking: false,
    type: 'circle'
};

Loading.propTypes = {
    nonblocking: PropTypes.bool,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    type: PropTypes.string,
    theme: PropTypes.object,
    classes: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
    return {
        message: UISelector.getLoading(state)
    };
};

export default connect(mapStateToProps)(
    withStyles(styles, {withTheme: true})(Loading)
);
