import {alpha, Divider, IconButton, List, ListItem, Popover, Typography, withStyles} from '@material-ui/core';
import {getInitials} from 'common/helpers/Str';
import {History} from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isArray} from 'lodash/lang';
import sortBy from 'lodash/sortBy';
import {connect} from 'react-redux';
import AuthSelectors from 'store/auth/AuthSelectors';
import Avatar from 'views/components/Avatar';
import Scroller from 'views/components/Scroller';
import get from 'lodash/get';

const FieldHistoryComponent = ({theme, classes, parser, isPatient, ...props}) => {
    const {t} = useTranslation(['errors', 'patient-history']);
    const [anchorEl, setAnchor] = useState(null);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        const values = get(props, 'history', []).filter(h => h.previous_value !== h.current_value);
        setHistory(sortBy(values, 'createdAt'));
    }, [props.history]);

    const parseValue = val => {
        if (val !== null) {
            return t(parser(val));
        }
        return t('patient-history:empty');
    };

    const handleClickHistory = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchor(e.currentTarget);
    };

    const handleCloseHistory = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchor(null);
    };

    if (!history || !isArray(history) || history.length <= 0 || isPatient) {
        return null;
    }

    return (
        <>
            <IconButton disableRipple disableFocusRipple disableTouchRipple size={'small'} onClick={handleClickHistory}>
                <History className={classes.icon}/>
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseHistory}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                style={{
                    padding: theme.spacing(2)
                }}
                PaperProps={{
                    style: {
                        overflow: 'hidden'
                    },
                    className: classes.modalPaper
                }}
            >
                <div style={{
                    minWidth: 500,
                    padding: theme.spacing(1),
                    background: theme.palette.primary.main,
                    color: theme.palette.common.white
                }}>
                    <Typography variant={'caption'}>
                        {t('patient-history:change-logs')}
                    </Typography>
                </div>
                <div style={{height: '100%'}}>
                    <Scroller autoHide={false} AutoSizerProps={{style: {height: 200}}}>
                        <List disablePadding>
                            {history.map((h, index) => (
                                <React.Fragment key={`${h.entity_type}-${h.id}`}>
                                    {index !== 0 && (<Divider/>)}
                                    <ListItem style={{
                                        padding: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch',
                                        justifyContent: 'center'
                                    }}>

                                        <div style={{
                                            padding: theme.spacing(1),
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start'
                                        }}>
                                            <div style={{padding: theme.spacing(1)}}>
                                                <Avatar size={'sm'}>{getInitials(h.user)}</Avatar>
                                            </div>
                                            <div style={{paddingLeft: theme.spacing(1)}}>
                                                <Typography variant={'body2'} display={'block'}>
                                                    {h.action === 'update' ? parseValue(h.previous_value) + ' ➡ ' : ''} {parseValue(h[h.action === 'delete' ? 'previous_value' : 'current_value'])}
                                                </Typography>
                                                <blockquote style={{margin: theme.spacing(1, 1, 1, 0)}}>
                                                    <Typography variant={'caption'} display={'block'} style={{fontStyle: 'italic'}}>
                                                        &ldquo;{t(h.reason)}&rdquo;
                                                    </Typography>

                                                    <Typography variant={'caption'} color={'textSecondary'} display={'block'} style={{fontSize: '.7em'}}>
                                                        &mdash; {t(`patient-history:${h.action}`)} by <strong>{h.user}</strong> on {h.createdAt}
                                                    </Typography>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </Scroller>
                </div>
            </Popover>
        </>
    );
};

FieldHistoryComponent.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    history: PropTypes.array,
    parser: PropTypes.func,
    isPatient: PropTypes.bool
};

FieldHistoryComponent.defaultProps = {
    parser: val => {
        return val;
    }
};

const styles = theme => ({
    root: {},
    icon: {
        transition: theme.transitions.create('color', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        color: alpha(theme.palette.primary.main, .2),
        '&:hover': {
            color: alpha(theme.palette.primary.main, 1)
        }
    },
    modalPaper: {
        '&::before': {
            backgroundColor: 'white',
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 12,
            height: 12,
            top: -6,
            transform: 'rotate(-45deg)',
            left: 'calc(50% - 6px)'
        }
    }
});
export default connect((state) => ({
    isPatient: AuthSelectors.getIsPatient(state)
}))(withStyles(styles, {withTheme: true})(FieldHistoryComponent));
