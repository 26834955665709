import {createTheme, alpha} from '@material-ui/core';

const rawTheme = createTheme();

const palette = (theme) => ({
    type: theme,
    primary: rawTheme.palette.augmentColor({
        main: '#291987',
        text: '#6F6F6B',
        contrastText: '#fff'
    }),
    secondary: rawTheme.palette.augmentColor({
        main: '#49B862',
        contrastText: '#131313'
    }),
    error: rawTheme.palette.augmentColor({
        main: '#515151',
        contrastText: '#fff'
    }),
    danger: rawTheme.palette.augmentColor({
        main: '#FC5E5E'
    }),
    lightError: rawTheme.palette.augmentColor({
        main: '#F8949A',
        contrastText: '#fff'
    }),
    backgroundError: rawTheme.palette.augmentColor({
        main: '#FAFAEE',
        contrastText: '#291987'
    }),
    success: rawTheme.palette.augmentColor({
        main: '#49B862',
        contrastText: '#fff'
    }),
    warning: rawTheme.palette.augmentColor({
        main: '#ff8c00',
        contrastText: '#fff'
    }),
    info: rawTheme.palette.augmentColor({
        main: '#42a5f5',
        contrastText: '#fff'
    }),
    backgroundColor: rawTheme.palette.augmentColor({
        main: '#fbfbf1',
        contrastText: '#1C1B71'
    }),
    white: rawTheme.palette.augmentColor({
        main: '#fff',
        contrastText: '#000'
    }),
    gray: rawTheme.palette.augmentColor({
        main: '#aaa',
        contrastText: '#000',
        linkText: '#6F6F6B'
    }),
    disabled: rawTheme.palette.augmentColor({
        main: 'rgba(0, 0, 0, 0.1)',
        contrastText: 'rgba(0, 0, 0, 0.8)'
    }),
    chartDangerBackground: rawTheme.palette.augmentColor({
        main: alpha('#FC5E5E', .2),
        contrastText: 'rgba(0, 0, 0, 0.8)'
    }),
    chartSuccessBackground: rawTheme.palette.augmentColor({
        main: alpha('#42D864', .2),
        contrastText: 'rgba(0, 0, 0, 0.8)'
    })
});

const variables = () => ({
    fontPrimary: 'sans-serif',
    fontSecondary: 'Publico Text',
    fontRegular: 'GT America'
});

const themes = (theme) =>
    createTheme({
        palette: palette(theme),
        variables: variables(),
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    'html, body': {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                    },
                    html: {
                        fontFamily: variables().fontPrimary,
                        lineHeight: 1.15,
                        webkitTextSizeAdjust: '100%',
                        webkitTapHighlightColor: 'transparent'
                    },
                    body: {
                        margin: 0,
                        fontSize: '1rem',
                        fontWeight: 400,
                        lineHeight: 1.5,
                        color: '#515151',
                        textAlign: 'left',
                        backgroundColor:
                            palette(theme).backgroundColor[palette(theme).type]
                    },
                    '.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${palette(theme).danger.main} !important`
                    }
                }
            },
            MuiListItemIcon: {
                root: {
                    paddingRight: 16,
                    paddingLeft: 8,
                    minWidth: 'auto'
                }
            },
            MuiButton: {
                root: {
                    minWidth: 'inherit',
                    whiteSpace: 'nowrap'
                },
                containedPrimary: {
                    color: '#FFF',
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    }
                }
            },
            MuiOutlinedInput: {
                root: {
                    borderRadius: rawTheme.shape.borderRadius
                },
                input: {
                    borderRadius: rawTheme.shape.borderRadius,
                    background: rawTheme.palette.common.white,
                    color: palette(theme).primary.main
                },
                notchedOutline: {
                    borderColor: palette(theme).primary.main
                }
            },
            MuiFormLabel: {
                root: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(100% - 24px)',
                    lineHeight: '1.1'
                }
            },
            MuiIconButton: {
                root: {
                    '&.nofocus': {
                        '&:hover': {
                            backgroundColor: 'transparent',
                            transition:
                                'color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            color:
                                theme === 'light'
                                    ? 'rgba(0, 0, 0, 0.8)'
                                    : 'rgba(255, 255, 255, 0.8)'
                        }
                    }
                }
            },
            MuiPickersModal: {
                root: {
                    zIndex: rawTheme.zIndex.modal + 10
                }
            },
            MuiListItem: {
                secondaryAction: {
                    paddingRight: 70
                }
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: '#fff',
                    color: 'rgba(0, 0, 0, 0.87)',
                    boxShadow:
                        '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                    fontSize: '1em'
                },
                arrow: {
                    color: '#fff'
                }
            },
            MuiCardHeader: {
                title: {
                    fontSize: '1.2em'
                }
            },
            MuiAlert: {
                root: {
                    margin: '8px 0 8px 0'
                },
                outlinedError: {
                    background: '#ffffff'
                },
                outlinedSuccess: {
                    background: '#ffffff'
                },
                outlinedWarning: {
                    background: '#ffffff'
                },
                outlinedInfo: {
                    background: '#ffffff'
                }
            },
            MuiFormHelperText: {
                root: {
                    marginBottom: 8
                }
            }

        }
    });

export default themes;
