import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles, Avatar as MaterialAvatar} from '@material-ui/core';

class Avatar extends Component {
    state = {
        src: null
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.path) {
            fetch(this.props.path, {
                mode: 'no-cors'
            })
                .then(response => {
                    if (response && response.status < 400) {
                        this.setState({src: this.props.path});
                    }
                })
                .catch(err => this.setState({src: null}));
        }
        else {
            this.setState({src: null});
        }
    }

    render() {
        const {
            classes,
            theme,
            className,
            path,
            size,
            children,
            ...props
        } = this.props;

        if (this.state.src) {
            return (
                <MaterialAvatar className={classNames(classes.root, classes[size], className)} src={this.state.src} {...props}/>
            );
        }
        return (
            <MaterialAvatar className={classNames(classes.root, classes[size], className)} style={{fontSize: 0}} {...props}>
                <span className={classes[size]} style={{
                    lineHeight: 0,
                    width: 'auto',
                    height: 'auto'
                }}>{children}</span>
            </MaterialAvatar>
        );
    }
}

Avatar.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    className: PropTypes.string,
    path: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xlg'])
};

Avatar.defaultProps = {
    size: 'md'
};

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    xs: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 12
    },
    sm: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: 15
    },
    md: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        fontSize: 24
    },
    lg: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        fontSize: 42
    },
    xlg: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        fontSize: 58
    }
});

export default withStyles(styles, {withTheme: true})(Avatar);
