/**
 * hobby Reducer definitions
 */

export const CLEAR_HOBBIES = 'hobbies/clear';
export const GET_HOBBIES_REQUEST = 'hobbies/request';
export const GET_HOBBIES_ERROR = 'hobbies/error';
export const GET_HOBBIES_SUCCESS = 'hobbies/success';
export const CLEAR_HOBBY = 'hobby/clear';
export const GET_HOBBY_REQUEST = 'hobby/get/request';
export const GET_HOBBY_ERROR = 'hobby/get/error';
export const GET_HOBBY_SUCCESS = 'hobby/get/success';
export const UPDATE_HOBBY_REQUEST = 'hobby/update/request';
export const UPDATE_HOBBY_ERROR = 'hobby/update/error';
export const UPDATE_HOBBY_SUCCESS = 'hobby/update/success';
export const CREATE_HOBBY_REQUEST = 'hobby/create/request';
export const CREATE_HOBBY_ERROR = 'hobby/create/error';
export const CREATE_HOBBY_SUCCESS = 'hobby/create/success';
export const DELETE_HOBBY_REQUEST = 'hobby/delete/request';
export const DELETE_HOBBY_ERROR = 'hobby/delete/error';
export const DELETE_HOBBY_SUCCESS = 'hobby/delete/success';

