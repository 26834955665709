import {
    CLOSE_ALERT,
    CLOSE_LAZY_DIALOG,
    CLOSE_NOTIFICATION,
    HIDE_LOADING,
    RESET_WIZARD,
    SET_MENU,
    SET_SUB_MENU,
    STICKY_HEADER_HEIGHT,
    STICKY_SUB_HEADER_HEIGHT,
    SET_WIZARD,
    SHOW_ALERT,
    SHOW_LAZY_DIALOG,
    SHOW_LOADING,
    SHOW_NOTIFICATION,
    TOGGLE_THEME
} from 'store/ui/UIActionTypes';

export const setMenu = (menuConfig) => ({
    type: SET_MENU,
    menu: menuConfig
});

export const setSubMenu = (subMenuConfig) => ({
    type: SET_SUB_MENU,
    menu: subMenuConfig
});

export const toggleTheme = (theme) => {
    return {
        type: TOGGLE_THEME,
        theme: theme === 'light' ? 'dark' : 'light'
    };
};

export const showNotification = (notification) => {
    return {
        type: SHOW_NOTIFICATION,
        notification: notification
    };
};

export const successNotification = (notification) => {
    return {
        type: SHOW_NOTIFICATION,
        notification: {
            severity: 'success',
            timeout: 6000,
            ...notification
        }
    };
};

export const errorNotification = (error) => {
    return {
        type: SHOW_NOTIFICATION,
        notification: {
            severity: 'error',
            timeout: 6000,
            text:
                typeof error.message === 'object' && error.message.length > 0
                    ? error.message.join('<br/>')
                    : error.message
        }
    };
};

export const closeNotification = (index) => {
    return {
        type: CLOSE_NOTIFICATION,
        index: index
    };
};

export const setHeaderHeight = (height) => {
    return {
        type: STICKY_HEADER_HEIGHT,
        payload: height
    };
};
export const setSubHeaderHeight = (height) => {
    return {
        type: STICKY_SUB_HEADER_HEIGHT,
        payload: height
    };
};

export const setWizardData = (data) => {
    return {
        type: SET_WIZARD,
        data: data
    };
};

export const resetWizardData = () => {
    return {
        type: RESET_WIZARD
    };
};

export const showAlert = (data) => {
    return {
        type: SHOW_ALERT,
        data: data
    };
};

export const closeAlert = (data) => {
    return {
        type: CLOSE_ALERT
    };
};

export const loading = (data) => {
    if (typeof data !== 'string') {
        return {
            type: SHOW_LOADING,
            key: data.key,
            data: data
        };
    }
    return {
        type: HIDE_LOADING,
        data: data
    };
};

export const showLazyDialog = (data) => {
    return {
        type: SHOW_LAZY_DIALOG,
        data: data
    };
};

export const closeLazyDialog = (data) => {
    return {
        type: CLOSE_LAZY_DIALOG
    };
};

export default {};
