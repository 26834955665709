/**
 * oauth Reducer definitions
 */

export const CLEAR_OAUTHS = 'oauths/clear';
export const GET_OAUTHS_REQUEST = 'oauths/request';
export const GET_OAUTHS_ERROR = 'oauths/error';
export const GET_OAUTHS_SUCCESS = 'oauths/success';
export const CLEAR_OAUTH = 'oauth/clear';
export const GET_OAUTH_REQUEST = 'oauth/get/request';
export const GET_OAUTH_ERROR = 'oauth/get/error';
export const GET_OAUTH_SUCCESS = 'oauth/get/success';
export const UPDATE_OAUTH_REQUEST = 'oauth/update/request';
export const UPDATE_OAUTH_ERROR = 'oauth/update/error';
export const UPDATE_OAUTH_SUCCESS = 'oauth/update/success';
export const CREATE_OAUTH_REQUEST = 'oauth/create/request';
export const CREATE_OAUTH_ERROR = 'oauth/create/error';
export const CREATE_OAUTH_SUCCESS = 'oauth/create/success';
export const DELETE_OAUTH_REQUEST = 'oauth/delete/request';
export const DELETE_OAUTH_ERROR = 'oauth/delete/error';
export const DELETE_OAUTH_SUCCESS = 'oauth/delete/success';

