import 'cross-fetch';
import {getTranslation} from 'common/helpers/i18n';
import Config from 'common/Config';
import SessionStorage from 'common/SessionStorage';
import Messages from 'common/constants/Messages';
import SystemError from 'core/Error/SystemError';
// import SubmissionError from 'core/Error/SubmissionError';
import kebabCase from 'lodash/kebabCase';
import RouteUtil from 'common/RouteUtil';
import store from 'store';
import {
    clearToken,
    logout,
    refreshingToken,
    setToken
} from 'store/auth/AuthActions';
import AuthSelectors from 'store/auth/AuthSelectors';
import moment from 'moment';
import {Headers} from 'cross-fetch';

import {parseParamsFromLocation} from 'common/helpers/location';

// let DEBUG_TAG = 'API';

const API = function () {
    let _self = this;
    /**
     * Timer property to track session timeout. This property is set to a setTimeout instance after oauth token is retrieved from API server.
     * Timer will automatically start until it hits expires_in time period. Right before token expires system will send a request to API server
     * with refresh_token to retrieve a new token.
     */
    this.token_timer = null;
    this.scopes = [];

    return _self.initializeScopes();
};

API.prototype.refreshToken = async function () {
    try {
        let params = {};
        store.dispatch(refreshingToken(true));
        const refresh_token = AuthSelectors.getRefreshToken(store.getState());
        if (!refresh_token) {
            throw new Error('Invalid refresh token');
        }
        const endpoint = this.resolveRoute('auth', 'refresh');
        const token = await this.request(
            endpoint.url,
            params,
            endpoint.method,
            true,
            {
                Authorization: refresh_token
            }
        );

        if (token.code) {
            throw token;
        }

        await this.saveToken(token);
        return token;
    }
    catch (e) {
        store.dispatch(clearToken());
        store.dispatch(refreshingToken(false));
        store.dispatch(logout());
    }
};

API.prototype.getToken = function () {
    let token = AuthSelectors.getAuth(store.getState());
    if (!token || !token.access_token) {
        if (SessionStorage.has('token') && SessionStorage.get('token') !== '') {
            token = JSON.parse(SessionStorage.get('token'));
        }
    }
    return token;
};

API.prototype.saveToken = async function (token) {
    store.dispatch(setToken(token));
};

API.prototype.hasToken = function () {
    return Boolean(
        AuthSelectors.getAccessToken(store.getState()) ||
        SessionStorage.get('token')
    );
};

API.prototype.isTokenExpired = function () {
    const token = this.getToken();
    return !token || moment.unix(token.expires).utc().isBefore(moment().utc());
};

API.prototype.isSpecialToken = function () {
    const token = this.getToken();
    return token && !token.refresh_token;
};

API.prototype.ensureTokenIsNotRefreshing = function () {
    return new Promise((resolve, reject) => {
        (function waitForNewToken() {
            if (!AuthSelectors.getIsTokenRefreshing(store.getState())) {
                return resolve();
            }
            setTimeout(waitForNewToken, 500);
        })();
    });
};

API.prototype.initializeScopes = function () {
    if (SessionStorage.has('token')) {
        try {
            const token = JSON.parse(SessionStorage.get('token'));

            this.scopes = token.scopes;
        }
        catch (e) {
            console.error('Unable to parse user token');
        }
    }
};

API.prototype.validate = function (service, endpoint, params) {
    const scopes = Config.get(`api.routes.${service}.${endpoint}.scopes`, {}) || [];

    if (scopes?.length && this.scopes?.length) {
        const scopesIntersection = scopes.filter(scope => this.scopes.includes(scope));

        if (!scopesIntersection.length) {
            const endpointPath = Config.get(
                `api.routes.${service}.${endpoint}.url`,
                false
            );
            const path = RouteUtil.resolveRoute(endpointPath, params);

            console.error(`Not enough permissions to send the request: ${path}`);
            throw new TypeError(
                'You don\'t have enough permissions to send the request'
            );
        }
    }
};

API.prototype.resolveRoute = function (service, endpoint, params) {
    if (!service || !endpoint) {
        throw new TypeError(
            'service or endpoint parameters missing in api configuration'
        );
    }

    this.validate(service, endpoint, params);

    const endpointPath = Config.get(
        `api.routes.${service}.${endpoint}.url`,
        false
    );
    if (!endpointPath) {
        throw new TypeError('Invalid API Configuration');
    }
    const paramsFromLocation = parseParamsFromLocation(window.location.pathname, endpointPath);
    let url = Config.get('api.url', '');
    const method = Config.get(
        `api.routes.${service}.${endpoint}.method`,
        'GET'
    );
    const version = Config.get('api.version', false);
    const headers = Config.get(`api.routes.${service}.${endpoint}.headers`, {});
    const path = RouteUtil.resolveRoute(endpointPath, {...paramsFromLocation, ...params});
    url = `${url}${version ? '/' + version : ''}${path}`;
    return {url, method, headers};
};

API.prototype.request = async function (
    endpoint,
    params,
    method,
    isPublic,
    additionalHeaders,
    isDownloadable = false,
    isCancellable = false
) {
    if (!additionalHeaders) {
        additionalHeaders = {};
    }
    let _self = this;

    if (!this.scopes) {
        this.initializeScopes();
    }

    if (!isPublic) {
        await this.ensureTokenIsNotRefreshing();
        if (this.isTokenExpired()) {
            await this.refreshToken();
        }

        if (
            this.hasToken() &&
            !Object.keys(additionalHeaders).includes('Authorization')
        ) {
            const token = this.getToken();
            if (typeof token !== 'undefined' && token && token.access_token) {
                Object.assign(additionalHeaders, {
                    authorization: `${token.token_type || 'Bearer'} ${token.access_token}`
                });
            }
        }
    }

    if (!endpoint.startsWith('http')) {
        endpoint = `${Config.get('api.url')}${endpoint}`;
    }

    const response = await _self.httprequest(
        endpoint,
        params,
        method,
        isPublic,
        additionalHeaders,
        isDownloadable,
        isCancellable
    );
    // fatal error, user logged in with same credentials on same device. This session is abandoned.
    if (response?.error && response?.error === 'invalid-token') {
        // the only case we will allow this is when screen is locked due inactivity and user reloads the page
        // token we are using in that case is a special token and other endpoints will fail. Once user unlocks
        // we will be able to make the request. so until then skip logout
        if (!this.isSpecialToken()) {
            store.dispatch(refreshingToken(false));
            store.dispatch(clearToken());
            // forced logout without replacing history
            window.location.href = RouteUtil.getRoutePath('base.login');
        }
    }
    if (response.statusCode && response.statusCode === 404) {
        const {t} = getTranslation(['messages']);

        throw new SystemError({
            error: Messages.NOT_FOUND,
            message: response.errorDescription || t('not-found')
        });
    }

    if (response.statusCode && response.statusCode === 500) {
        throw response;
    }

    if (response instanceof DOMException) {
        throw response;
    }

    if (isDownloadable && response instanceof SystemError) {
        throw response;
    }
    // for any error we handle them manually in their respective service calls.
    return response;
};

API.prototype.httprequest = async function (
    url,
    params,
    method,
    isPublic,
    additionalHeaders,
    isDownloadable = false,
    isCancellable = false
) {
    let _self = this;

    if (!additionalHeaders) {
        additionalHeaders = {};
    }

    if (!params) {
        params = {};
    }
    const requestSlug = kebabCase(`${method}_${url}`);

    /* istanbul ignore next */
    if (typeof _self[requestSlug] !== 'undefined' && isCancellable) {
        _self[requestSlug].abort('');
    }

    const requestHeaders = {
        ['Content-Type']: 'application/json',
        ['X-Requested-With']: 'XMLHttpRequest',
        ...additionalHeaders
    };
    if (params.document) {
        delete requestHeaders['Content-Type'];
    }

    const headers = new Headers();
    Object.entries(requestHeaders).map(([key, value], index) => {
        headers.append(key, value);
    });

    let options = {
        method: method ? method : 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: headers
    };

    if (isCancellable) {
        _self[requestSlug] = new AbortController();

        options.signal = _self[requestSlug].signal;
    }

    if (Object.keys(params).length > 0) {
        if (!method || method === 'GET') {
            const query = Object.keys(params)
                .map(
                    (key) =>
                        encodeURIComponent(key) +
                        '=' +
                        encodeURIComponent(
                            typeof params[key] === 'object' ?
                                JSON.stringify(params[key]) :
                                params[key]
                        )
                )
                .join('&')
                .replace(/%20/g, '+');
            url = `${url}?${query}`;
        }
        else {
            if (params.document) {
                const data = new FormData();
                data.append('document', params.document[0]);
                data.append('file_name', params.file_name);
                Object.assign(options, {body: data});
            }
            else {
                Object.assign(options, {body: JSON.stringify(params)});
            }
        }
    }
    if (Array.isArray(params) && params.length === 0) {
        Object.assign(options, {body: JSON.stringify(params)});
    }

    try {
        const response = await fetch(url, options);
        const {t} = getTranslation(['messages']);

        if (response instanceof DOMException && response.name === 'AbortError') {
            throw response;
        }

        if (response.status && response.status === 404) {
            const message = isDownloadable ? t('file-not-found') : response.errorDescription || t('not-found');

            throw new SystemError({
                error: Messages.NOT_FOUND,
                message
            });
        }

        if (response.status && response.status === 500) {
            throw {
                statusCode: response.status,
                error: 'internal-system-error',
                message: t('system-error')
            };
        }

        if (isDownloadable) {
            const blob = await response.blob();
            return blob;
        }
        delete _self[requestSlug];
        const result = await response.json();
        return result;


    }
    catch (e) {
        if (e instanceof TypeError) {
            throw e;
        }
        return e;
    }
};
export default new API();
