/**
 * Language Reducer definitions
 */

export const CLEAR_LANGS = 'langs/clear';
export const GET_LANGS_REQUEST = 'langs/request';
export const GET_LANGS_ERROR = 'langs/error';
export const GET_LANGS_SUCCESS = 'langs/success';
export const CLEAR_LANG = 'lang/clear';
export const GET_LANG_REQUEST = 'lang/get/request';
export const GET_LANG_ERROR = 'lang/get/error';
export const GET_LANG_SUCCESS = 'lang/get/success';
export const UPDATE_LANG_REQUEST = 'lang/update/request';
export const UPDATE_LANG_ERROR = 'lang/update/error';
export const UPDATE_LANG_SUCCESS = 'lang/update/success';
export const CREATE_LANG_REQUEST = 'lang/create/request';
export const CREATE_LANG_ERROR = 'lang/create/error';
export const CREATE_LANG_SUCCESS = 'lang/create/success';
export const DELETE_LANG_REQUEST = 'lang/delete/request';
export const DELETE_LANG_ERROR = 'lang/delete/error';
export const DELETE_LANG_SUCCESS = 'lang/delete/success';

