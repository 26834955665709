/**
 * location Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';

import {getNormalizeState, updateNormalizeState} from 'common/helpers/State';

import {
    CLEAR_VITALS,
    GET_VITALS_REQUEST,
    GET_VITALS_ERROR,
    GET_VITALS_SUCCESS,
    CLEAR_VITAL,
    GET_VITAL_REQUEST,
    GET_VITAL_ERROR,
    GET_VITAL_SUCCESS,
    UPDATE_VITAL_REQUEST,
    UPDATE_VITAL_ERROR,
    UPDATE_VITAL_SUCCESS,
    CREATE_VITAL_REQUEST,
    CREATE_VITAL_ERROR,
    CREATE_VITAL_SUCCESS,
    DELETE_VITAL_REQUEST,
    DELETE_VITAL_ERROR,
    DELETE_VITAL_SUCCESS,
    VITAL_SELECT_COUNTRY,
    VITAL_RESET_COUNTRY
} from 'store/vitals/VitalsActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function vitals(state = initialState, action) {
    let newState = {};

    switch (action.type) {
        case CLEAR_VITALS: {
            newState = {
                ...initialState
            };
            break;
        }
        case VITAL_SELECT_COUNTRY: {
            newState = {
                ...state,
                countryId: action.countryId
            };
            break;
        }
        case VITAL_RESET_COUNTRY: {
            newState = {
                ...state,
                countryId: null
            };
            break;
        }
        case GET_VITALS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_VITALS_SUCCESS: {
            newState = {
                ...initialState,
                status: action.status,
                data: getNormalizeState(action.data, 'vital_id'),
                patientId: action.patientId,
                error: null
            };
            break;
        }
        case GET_VITALS_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_VITAL: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_VITAL_REQUEST:
        case UPDATE_VITAL_REQUEST:
        case CREATE_VITAL_REQUEST:
        case DELETE_VITAL_REQUEST: {
            //Object.assign(newData, {[action.id]: {status: action.status}});
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                status: action.status,
                error: null
            };
            break;
        }
        case GET_VITAL_SUCCESS:
        case CREATE_VITAL_SUCCESS:
        case UPDATE_VITAL_SUCCESS:
        case DELETE_VITAL_SUCCESS: {
            newState = {
                ...state,
                status: action.status,
                data: updateNormalizeState(state.data, action.id, action.data, 'vital_id'),
                error: null
            };
            break;
        }
        case GET_VITAL_ERROR:
        case UPDATE_VITAL_ERROR:
        case CREATE_VITAL_ERROR:
        case DELETE_VITAL_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
