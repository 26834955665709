/**
 * Documents Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';

import {
    CLEAR_CATEGORIES,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_ERROR,
    GET_CATEGORIES_SUCCESS,
    CLEAR_DOCUMENTS,
    GET_DOCUMENTS_REQUEST,
    GET_DOCUMENTS_ERROR,
    GET_DOCUMENTS_SUCCESS,
    CLEAR_DOCUMENT,
    GET_DOCUMENT_REQUEST,
    GET_DOCUMENT_ERROR,
    GET_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_REQUEST,
    UPDATE_DOCUMENT_ERROR,
    UPDATE_DOCUMENT_SUCCESS,
    CREATE_DOCUMENT_REQUEST,
    CREATE_DOCUMENT_ERROR,
    CREATE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_ERROR,
    DELETE_DOCUMENT_SUCCESS
} from 'store/document/DocumentsActionTypes';
import {DOWNLOAD_DOCUMENT_ERROR, DOWNLOAD_DOCUMENT_REQUEST, DOWNLOAD_DOCUMENT_SUCCESS} from './DocumentsActionTypes';

const emptyArray = [];
const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function documents(state = initialState, action) {
    let newState = {};
    switch (action.type) {
        case CLEAR_CATEGORIES: {
            newState = {
                ...state,
                categories: {},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_CATEGORIES_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_CATEGORIES_SUCCESS: {
            newState = {
                ...state,
                status: action.status,
                categories: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_CATEGORIES_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_DOCUMENTS: {
            newState = {
                ...state,
                data: {},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_DOCUMENTS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_DOCUMENTS_SUCCESS: {
            newState = {
                ...state,
                status: action.status,
                patientId: action.patientId,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_DOCUMENTS_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_DOCUMENT: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_DOCUMENT_REQUEST:
        case UPDATE_DOCUMENT_REQUEST:
        case CREATE_DOCUMENT_REQUEST:
        case DELETE_DOCUMENT_REQUEST:
        case DOWNLOAD_DOCUMENT_REQUEST: {
            const newData = {...state.data};
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_DOCUMENT_SUCCESS:
        case UPDATE_DOCUMENT_SUCCESS:
        case CREATE_DOCUMENT_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_DOCUMENT_ERROR:
        case UPDATE_DOCUMENT_ERROR:
        case CREATE_DOCUMENT_ERROR:
        case DELETE_DOCUMENT_ERROR:
        case DOWNLOAD_DOCUMENT_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_DOCUMENT_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }

        case DOWNLOAD_DOCUMENT_SUCCESS:{
            const newData = {};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                downloadDoc: {...newData},
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}

