import camelCase from 'lodash/camelCase';
import toUpper from 'lodash/toUpper';
import random from 'lodash/random';
import shuffle from 'lodash/shuffle';

export const getInitials = (str, glue = '') => {
    if (!str || str === '') {
        return '';
    }
    return str.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g).join(glue).toUpperCase();
};

export const makeID = (length) => {
    let result = '';
    const characters = 'bcdfghjkmpqrtvwxyBCDFGHJKMPQRTVWXY346789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
};

export const makePassword = (length = 10, rules = {lowerCase: 1, upperCase: 1, numbers: 1, characters: 1}) => {
    const chars = {
        lowerCase: 'bcdfghjkmpqrtvwxy',
        upperCase: 'BCDFGHJKMPQRTVWXY',
        numbers: '346789',
        characters: '=+{}[]?/<>~!@#$%&^*()|'
    };
    let password = [];
    const randomChar = (count, type = 'lowerCase') => {
        let result = '';
        const characters = chars[type];
        const charactersLength = characters.length;
        for (let i = 0; i < count; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    Object.keys(rules).map(ruleName => {
        if (rules[ruleName] > 0) {
            password.push(randomChar(rules[ruleName], ruleName));
        }
    });

    // if we don't have enough characters, fill the rest with random characters from rules
    if (password.length < length) {
        const remaining = length - password.length;
        const fillerRules = Object.keys(rules).filter(name => name !== 'characters');
        for (let i = 0; i < remaining; i++) {
            const r = random(0, fillerRules.length - 1);
            const ruleName = fillerRules[r];
            password.push(randomChar(rules[ruleName], ruleName));
        }
    }

    return shuffle(password).join('');
};

export const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export default null;
