import {Button, Link, withStyles} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import Config from 'common/Config';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import JiraIssueCollector from 'views/components/ErrorHandler/JiraIssueCollector';

const ErrorMessage = (props) => {
    const {theme, classes} = props;
    const {t} = useTranslation(['common', 'errors']);
    const [openReport, setOpenReport] = useState(false);
    return (
        <div className={classes.root}>
            <Alert classes={{
                root: classes.alertRoot
            }} color={'error'} variant={'filled'} icon={false} title={t('common:error')}>
                {Config.get('app.reportJira') ? (
                    <span>Module Failed. Please <Link style={{cursor: 'pointer'}} onClick={e => setOpenReport(true)}>click here</Link> to report a bug.</span>
                ) : t('errors:generic-module-error')}
            </Alert>
            {openReport && <JiraIssueCollector {...props}/>}
        </div>
    );
};
ErrorMessage.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object

};
const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        padding: theme.spacing(2)
    },
    alertRoot: {
        '&.MuiAlert-filledError': {
            background: 'rgb(206, 17, 38)'
        },
        '& .MuiAlert-message': {
            padding: theme.spacing(0)
        }
    }
});

export default withStyles(styles, {withTheme: true})(ErrorMessage);
