import {createSelector} from 'reselect';
import get from 'lodash/get';
import {parse} from 'query-string';
import omit from 'lodash/omit';

const emptyObject = {};

const getRouterState = state => get(state, 'router', null);
const getLocationState = state => get(state, 'router.location', null);

const getQueryString = (state, param) => param || null;

const getRootStateRouter = createSelector(
    getRouterState,
    router => router || null
);

const RouterSelectorFactory = getState => ({
    getState,
    getLocation: createSelector(
        getLocationState,
        location => omit(location, 'state')
    ),
    getLocationState: createSelector(
        getLocationState,
        location => get(location, 'state', emptyObject)
    ),
    getQueryString: createSelector(
        getState,
        getQueryString,
        (router, queryString) => {
            const query = parse(get(location, 'search', emptyObject));
            return get(query, queryString, null);
        }
    )
});

export default RouterSelectorFactory(getRootStateRouter);
