/**
 * patient action definitions
 */
import {SubmissionError} from 'redux-form';
import {
    CLEAR_PATIENTS,
    CLEAR_PATIENTS_PAGES,
    GET_PATIENTS_REQUEST,
    GET_PATIENTS_ERROR,
    GET_PATIENTS_SUCCESS,
    CLEAR_PATIENT,
    GET_PATIENT_REQUEST,
    GET_PATIENT_ERROR,
    GET_PATIENT_SUCCESS,
    UPDATE_PATIENT_REQUEST,
    UPDATE_PATIENT_ERROR,
    UPDATE_PATIENT_SUCCESS,
    CREATE_PATIENT_REQUEST,
    CREATE_PATIENT_ERROR,
    CREATE_PATIENT_SUCCESS,
    DELETE_PATIENT_REQUEST,
    DELETE_PATIENT_ERROR,
    DELETE_PATIENT_SUCCESS,
    PARTIAL_UPDATE_PATIENT_SUCCESS,
    PARTIAL_UPDATE_PATIENT_ERROR
} from 'store/patient/PatientActionTypes';

import {errorNotification} from 'store/ui/UIActions';

export const clearPatientsAction = () => ({type: CLEAR_PATIENTS});
const clearPatientAction = (id) => ({type: CLEAR_PATIENT, id});
export const clearPatientsPagesAction = () => ({type: CLEAR_PATIENTS_PAGES});

const getPatientsRequestAction = () => ({
    type: GET_PATIENTS_REQUEST,
    status: 'loading'
});
const getPatientsErrorAction = (e) => ({
    type: GET_PATIENTS_ERROR,
    error: e,
    status: 'error'
});
const getPatientsSuccessAction = (data, params) => ({
    type: GET_PATIENTS_SUCCESS,
    data,
    params,
    status: 'idle'
});

const getPatientRequestAction = (id) => ({
    type: GET_PATIENT_REQUEST,
    id,
    status: 'loading'
});
const getPatientErrorAction = (e, id) => ({
    type: GET_PATIENT_ERROR,
    error: e,
    status: 'error',
    id
});
const getPatientSuccessAction = (data, id) => ({
    type: GET_PATIENT_SUCCESS,
    data,
    id,
    status: 'idle'
});

const updatePatientRequestAction = (id) => ({
    type: UPDATE_PATIENT_REQUEST,
    id,
    status: 'loading'
});
const updatePatientErrorAction = (e, id) => ({
    type: UPDATE_PATIENT_ERROR,
    error: e,
    status: 'error',
    id
});
const updatePatientSuccessAction = (data, id) => ({
    type: UPDATE_PATIENT_SUCCESS,
    data,
    id,
    status: 'idle'
});

const updatePartialPatientErrorAction = (e, id) => ({
    type: PARTIAL_UPDATE_PATIENT_ERROR,
    error: e,
    status: 'error',
    id
});

const updatePartialPatientSuccessAction = (data, id) => ({
    type: PARTIAL_UPDATE_PATIENT_SUCCESS,
    data,
    id,
    status: 'idle'
});

const createPatientRequestAction = () => ({
    type: CREATE_PATIENT_REQUEST,
    status: 'loading'
});
const createPatientErrorAction = (e) => ({
    type: CREATE_PATIENT_ERROR,
    error: e,
    status: 'error'
});
const createPatientSuccessAction = (data, id) => ({
    type: CREATE_PATIENT_SUCCESS,
    data,
    id,
    status: 'idle'
});

const deletePatientRequestAction = (id) => ({
    type: DELETE_PATIENT_REQUEST,
    id,
    status: 'loading'
});
const deletePatientErrorAction = (e, id) => ({
    type: DELETE_PATIENT_ERROR,
    error: e,
    status: 'error',
    id
});
const deletePatientSuccessAction = (id) => ({
    type: DELETE_PATIENT_SUCCESS,
    id,
    status: 'idle'
});

export function getPatientsRequest() {
    return (dispatch) => {
        dispatch(getPatientsRequestAction());
    };
}

export function getPatientsSuccess(data, params) {
    return (dispatch) => {
        dispatch(getPatientsSuccessAction(data, params));
    };
}

export function getPatientsError(e) {
    return (dispatch) => {
        dispatch(clearPatientsAction());
        dispatch(getPatientsErrorAction(e));
        dispatch(errorNotification(e));
    };
}

export function getPatientRequest(id) {
    return (dispatch) => {
        dispatch(clearPatientAction(id));
        dispatch(getPatientRequestAction(id));
    };
}

export function getPatientSuccess(data, id) {
    return (dispatch) => {
        dispatch(getPatientSuccessAction(data, id));
    };
}

export function getPatientError(e, id) {
    return (dispatch) => {
        dispatch(clearPatientAction(id));
        dispatch(getPatientErrorAction(e, id));
        dispatch(errorNotification(e));
    };
}

export function savePatientRequest(id) {
    return (dispatch) => {
        if (id) {
            dispatch(updatePatientRequestAction(id));
        }
        else {
            dispatch(createPatientRequestAction());
        }
    };
}

export function savePatientError(e, id) {
    return (dispatch) => {
        if (id) {
            dispatch(updatePatientErrorAction(e, id));
        }
        else {
            dispatch(createPatientErrorAction(e));
        }
        if (e.error === 'validation-error' && e.fields) {
            Object.entries(e.fields).forEach(([fieldName, message]) => {
                dispatch(errorNotification({message}));
            });
        }
        else {
            dispatch(errorNotification(e));
        }
    };
}

export function savePatientSuccess(data, id) {
    return (dispatch) => {
        if (data) {
            if (id) {
                dispatch(updatePatientSuccessAction(data, id));
            }
            else {
                dispatch(createPatientSuccessAction(data, id));
            }
        }
    };
}

export function updatePartialPatientSuccess(data, id) {
    return (dispatch) => {
        if (data) {
            dispatch(updatePartialPatientSuccessAction(data, id));
        }
    };
}

export function updatePartialPatientError(e, id) {
    return (dispatch) => {
        dispatch(updatePartialPatientErrorAction(e, id));
        dispatch(errorNotification(e));
    };
}

export function deletePatientRequest(id) {
    return (dispatch) => {
        dispatch(deletePatientRequestAction(id));
    };
}

export function deletePatientSuccess(id) {
    return (dispatch) => {
        dispatch(deletePatientSuccessAction(id));
    };
}

export function deletePatientError(e, id) {
    return (dispatch) => {
        dispatch(deletePatientErrorAction(e, id));
        dispatch(errorNotification(e));
    };
}

export default {
    getPatientsRequest,
    getPatientsSuccess,
    getPatientsError,
    getPatientRequest,
    getPatientSuccess,
    getPatientError,
    savePatientRequest,
    savePatientError,
    savePatientSuccess,
    deletePatientRequest,
    deletePatientSuccess,
    deletePatientError
};
