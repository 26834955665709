/**
 * user service definitions
 */
import API from 'common/API';
import ServiceFactory from 'core/ServiceFactory';
import camelCase from 'lodash/camelCase';
import pluralize from 'pluralize';
import store from 'store';
import {logout, setUserScopes} from 'store/auth/AuthActions';
import {clearPatientsPagesAction} from 'store/patient/PatientActions';
import {loading} from 'store/ui/UIActions';
import AuthSelectors from 'store/auth/AuthSelectors';
import UserActions, {
    getUserError,
    getUserRequest,
    getUserSuccess,
    setUserOrganization,
    setUserRecertifyError,
    setUserRecertifyRequest,
    setUserRecertifySuccess
} from 'store/user/UserActions';

class UserService extends ServiceFactory {
    store = 'user';
    service = 'users';
    isPublic = false;

    messages = {
        loading_one: ['common:loading', {entity: 'users:user'}],
        loading_list: ['common:loading', {entity: 'users:users'}],
        saving: ['common:saving', {entity: 'users:user'}],
        deleting: ['common:deleting', {entity: 'users:user'}],
        creating: ['common:creating', {entity: 'users:user'}],
        updating: ['common:updating', {entity: 'users:user'}]
    };

    actions = UserActions;

    getMe = async (params = null, additionalHeaders) => {
        const userId = AuthSelectors.getCurrentUser(store.getState());
        try {
            store.dispatch(getUserRequest(userId));
            const {url, method} = API.resolveRoute('user', 'profile', {
                userId: 'me'
            });
            const response = await API.request(url, params, method, false, additionalHeaders);

            if (response && response.id) {
                store.dispatch(getUserSuccess(response, response.id));
                store.dispatch(setUserOrganization(response.organization, response.id));

                return {
                    ...response
                };
            }
            else {
                store.dispatch(getUserError(response));
                store.dispatch(logout(response));
            }
        }
        catch (e) {
            store.dispatch(getUserError(e));
        }
    };

    setOrg = async (body, {userId}, showLoading = true) => {
        try {
            showLoading && store.dispatch(
                loading({
                    type: `loading-${pluralize(camelCase(this.store), 1)}`,
                    message:
                        this.messages.loading_one ||
                        `Loading ${pluralize(camelCase(this.store), 1)}`
                })
            );

            const {url, method} = API.resolveRoute('users', 'setOrg', {
                userId
            });
            const response = await API.request(url, body, method, false);

            if (response && response.id) {
                store.dispatch(setUserOrganization(response, userId));
                store.dispatch(clearPatientsPagesAction());
                if (response.scopes) {
                    store.dispatch(setUserScopes(response.scopes));
                }
                return response;
            }
            else {
                store.dispatch(logout());
                store.dispatch(getUserError(response));
            }
        }
        catch (e) {
            store.dispatch(logout());
            store.dispatch(getUserError(e));
        }
        finally {
            showLoading && store.dispatch(loading(`loading-${pluralize(this.service, 1)}`));
        }
    };

    bulkRecertify = async (data = []) => {
        if (!data || data.length === 0) {
            return;
        }
        try {
            store.dispatch(setUserRecertifyRequest());
            const {url, method} = API.resolveRoute('users', 'bulkRecertification');
            const response = await API.request(url, data, method, false);
            if (response && !response.error) {
                store.dispatch(setUserRecertifySuccess(response));
            }
            else {
                store.dispatch(setUserRecertifyError(response));
            }
        }
        catch (e) {
            store.dispatch(setUserRecertifyError(e));
        }
    };
}

export default new UserService();
