export default ({
    'limitFileSize': 50 * 1024 * 1024 * 1024, // upload limit is 50GB (53 687 091 200 bytes)
    'acceptFileTypes': {
        'mage/bmp': [],
        'image/gif': [],
        'image/jpeg': [],
        'image/png': [],
        'image/x-icon': [],
        'image/emf': [],
        'image/x-olympus-orf': [],
        'image/tiff': [],
        'image/dcm': [],
        'image/dicom': [],
        'application/x-msmetafile': [],
        'application/zip': [],
        'application/gzip': [],
        'application/x-gzip': [],
        'application/vnd.rar': [],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/vnd.ms-powerpoint': [],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
        'application/pdf': [],
        'text/csv': [],
        'text/plain': []
    }
});
