const bean = require('bean');

const Events = {
    on: bean.on,
    off: bean.off,
    once: bean.one,
    clone: bean.clone,
    trigger: bean.trigger
};

export default Events;
