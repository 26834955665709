/**
 * vital Reducer definitions
 */

export const CLEAR_VITALS = 'vitals/clear';
export const GET_VITALS_REQUEST = 'vitals/request';
export const GET_VITALS_ERROR = 'vitals/error';
export const GET_VITALS_SUCCESS = 'vitals/success';
export const CLEAR_VITAL = 'vital/clear';
export const GET_VITAL_REQUEST = 'vital/get/request';
export const GET_VITAL_ERROR = 'vital/get/error';
export const GET_VITAL_SUCCESS = 'vital/get/success';
export const UPDATE_VITAL_REQUEST = 'vital/update/request';
export const UPDATE_VITAL_ERROR = 'vital/update/error';
export const UPDATE_VITAL_SUCCESS = 'vital/update/success';
export const CREATE_VITAL_REQUEST = 'vital/create/request';
export const CREATE_VITAL_ERROR = 'vital/create/error';
export const CREATE_VITAL_SUCCESS = 'vital/create/success';
export const DELETE_VITAL_REQUEST = 'vital/delete/request';
export const DELETE_VITAL_ERROR = 'vital/delete/error';
export const DELETE_VITAL_SUCCESS = 'vital/delete/success';
export const VITAL_SELECT_COUNTRY = 'vital/select/country';
export const VITAL_RESET_COUNTRY = 'vital/reset/country';
