import moment from 'moment';
import {DATE_TIME_FORMAT, API_DATE_FORMAT, MONTH_YEAR_FORMAT} from 'common/constants/common';

export default ({
    formatDates: (values, dateFormat = DATE_TIME_FORMAT) => {
        const cleanValues = {...values};
        for (let key in cleanValues) {
            if (
                typeof cleanValues[key] === 'string' &&
                moment(cleanValues[key], dateFormat, true).isValid()
            ) {
                cleanValues[key] = moment(cleanValues[key]).format(API_DATE_FORMAT);
            }
        }
        return cleanValues;
    },
    filterFiles: (files) => files.filter(file => file instanceof File),
    clearValues: (values, options = {}) => {
        
        const {removeNull} = options;
        const cleanValues = {...values};

        Object.keys(cleanValues).map(key => {
            if (
                (removeNull && cleanValues[key] === null) ||
                cleanValues[key] === undefined
            ) {
                delete cleanValues[key];
            }
        });
        return cleanValues;
    },
    /**
     *
     * @param {Object} values
     * @returns {Object}
     */
    prepareInitialValues: function removeNumbers(values) {
        if (!values) {
            return values;
        }
        return Object.entries(values).reduce((acc, [name, value])=> {
            // Prepare an initial value for the month and year picker so that we don't show a reason change error when
            // nothing has changed and the field works correctly in Firefox
            if (typeof value === 'string' && moment(value, MONTH_YEAR_FORMAT, true).isValid()) {
                const momentDate = moment(value, MONTH_YEAR_FORMAT);

                acc[name] = momentDate.format(API_DATE_FORMAT);
            }
            else {
                acc[name] = value;
            }

            return acc;
        }, {});
    }
});
