/* eslint-disable no-prototype-builtins */
import API from 'common/API';
import Config from 'common/Config';
import store from 'store/index';
import {loading} from 'store/ui/UIActions';
import pluralize from 'pluralize';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';

import {pascalCase} from 'common/helpers/Str';

class ServiceFactory {
    store = '';
    service = '';
    isPublic = false;
    showLoading = true;

    messages = {
        loading_one: '',
        loading_list: '',
        saving: '',
        deleting: '',
        creating: '',
        updating: ''
    };


    actions = {};

    constructor() {
        if (this.constructor.name === 'ServiceFactory') {
            throw new TypeError(
                'ServiceFactory is an abstraction class. Can not create an instance'
            );
        }
    }

    _validate() {
        if (
            typeof this.constructor.hasOwnProperty('store') === 'undefined' ||
            !camelCase(this.store) ||
            camelCase(this.store) === ''
        ) {
            throw new TypeError(
                `${this.constructor.name} is missing store definition`
            );
        }
        if (
            typeof this.constructor.hasOwnProperty('service') === 'undefined' ||
            !this.service ||
            this.service === ''
        ) {
            throw new TypeError(
                `${this.constructor.name} is missing service definition`
            );
        }
        if (!Config.get(
            `api.routes.${this.service}`,
            false
        )) {
            throw new TypeError(
                `This ${this.service} service doesn't exist in the API config`
            );
        }
        if (
            typeof this.constructor.hasOwnProperty('actions') === 'undefined' ||
            !this.actions ||
            this.actions === {}
        ) {
            throw new TypeError(
                `${this.constructor.name} is missing actions definition`
            );
        }

        if (typeof this.isPublic !== 'object') {
            this.isPublic = {
                get: this.isPublic,
                create: this.isPublic,
                list: this.isPublic,
                update: this.isPublic,
                delete: this.isPublic
            };
        }
    }

    async getById(id = null, showLoading = true) {
        this._validate();
        try {
            store.dispatch(
                this.actions[`get${pluralize(pascalCase(camelCase(this.store)), 1)}Request`]()
            );
            this.showLoading && showLoading && store.dispatch(
                loading({
                    type: `loading-${pluralize(camelCase(this.store), 1)}`,
                    message:
                        this.messages.loading_one ||
                        `Loading ${pluralize(camelCase(this.store), 1)}`
                })
            );
            const endpoint = API.resolveRoute(this.service, 'get', {
                [`${pluralize(camelCase(this.store), 1)}Id`]: id
            });
            const result = await API.request(
                endpoint.url,
                {},
                endpoint.method,
                get(this.isPublic, 'get', this.isPublic)
            );
            store.dispatch(
                this.actions[`get${pluralize(pascalCase(camelCase(this.store)), 1)}Success`](result, result.id)
            );
            return result;
        }
        catch (e) {
            console.log(e);
            store.dispatch(
                this.actions[`get${pluralize(pascalCase(camelCase(this.store)), 1)}Error`](e, id)
            );
        }
        finally {
            this.showLoading && store.dispatch(loading(`loading-${pluralize(this.service, 1)}`));
        }
    }

    async list(params = {}, showLoading = false) {
        this._validate();
        try {
            store.dispatch(
                this.actions[`get${pluralize(pascalCase(camelCase(this.store)), 2)}Request`](params)
            );


            if (this.showLoading && showLoading) {
                store.dispatch(
                    loading({
                        type: `loading-${pluralize(this.service, 2)}`,
                        message:
                            this.messages.loading_list ||
                            `Loading ${pluralize(camelCase(this.store), 2)}`
                    })
                );
            }
            const endpoint = API.resolveRoute(this.service, 'list', params);

            let result = await API.request(
                endpoint.url,
                params,
                endpoint.method,
                get(this.isPublic, 'list', this.isPublic),
                undefined,
                false,
                true
            );
            if (result && result.error) {
                store.dispatch(
                    this.actions[`get${pluralize(pascalCase(camelCase(this.store)), 2)}Error`](result, params)
                );
            }
            else {
                store.dispatch(
                    this.actions[`get${pluralize(pascalCase(camelCase(this.store)), 2)}Success`](result, params)
                );
            }
            return result;
        }
        catch (e) {
            console.log(e);
            store.dispatch(
                this.actions[`get${pluralize(pascalCase(camelCase(this.store)), 2)}Error`](e)
            );
        }
        finally {
            if (this.showLoading && showLoading) {
                store.dispatch(loading(`loading-${pluralize(this.service, 2)}`));
            }
        }
    }

    async save(values = {}, params = {}, showLoading) {
        this._validate();
        const {id, ...data} = values;
        const {endpoint: endpointName = id ? 'update' : 'create'} = params;
        try {
            store.dispatch(
                this.actions[`save${pluralize(pascalCase(camelCase(this.store)), 1)}Request`](params)
            );
            this.showLoading && showLoading && store.dispatch(
                loading({
                    type: `${id ? 'saving' : 'creating'}-${pluralize(camelCase(this.store), 1)}`,
                    message:
                        this.messages.saving ||
                        `${id ? 'Saving' : 'Creating new '} ${pluralize(camelCase(this.store), 1)}`
                })
            );
            params[`${pluralize(camelCase(this.store), 1)}Id`] = id;
            // Don't add new parameters, use the "params" argument instead !!!
            if (!params.patientId && data.patientId) {
                params.patientId = data.patientId;
            }

            if (!params.questionnaireId && data.questionnaireId) {
                params.questionnaireId = data.questionnaireId;
            }

            const endpoint = API.resolveRoute(
                this.service,
                endpointName,
                {
                    ...params
                }
            );
            const result = await API.request(
                endpoint.url,
                data,
                endpoint.method,
                get(this.isPublic, id ? 'update' : 'create', this.isPublic)
            );
            if (result && result.error) {
                throw result;
            }
            else {
                store.dispatch(
                    this.actions[`save${pluralize(pascalCase(camelCase(this.store)), 1)}Success`](result, result.id, params)
                );
            }
            return result;
        }
        catch (e) {
            store.dispatch(
                this.actions[`save${pluralize(pascalCase(camelCase(this.store)), 1)}Error`](e, id)
            );
            throw e;
        }
        finally {
            this.showLoading && showLoading && store.dispatch(
                loading(
                    `${id ? 'saving' : 'creating'}-${pluralize(camelCase(this.store), 1)}`
                )
            );
        }
    }

    async delete(id = null, params = {}) {
        this._validate();
        try {
            store.dispatch(
                this.actions[`delete${pluralize(pascalCase(camelCase(this.store)), 1)}Request`](params)
            );
            this.showLoading && store.dispatch(
                loading({
                    type: `delete-${pluralize(camelCase(this.store), 1)}`,
                    message:
                        this.messages.deleting ||
                        `Deleting ${pluralize(camelCase(this.store), 1)}...`
                })
            );
            const endpoint = API.resolveRoute(this.service, 'delete', {
                [`${pluralize(camelCase(this.store), 1)}Id`]: id,
                ...params
            });
            await API.request(
                endpoint.url,
                {id},
                endpoint.method,
                get(this.isPublic, 'delete', this.isPublic)
            );
            store.dispatch(
                this.actions[`delete${pluralize(pascalCase(camelCase(this.store)), 1)}Success`](id, params)
            );
        }
        catch (e) {
            console.log(e);
            store.dispatch(
                this.actions[`delete${pluralize(pascalCase(camelCase(this.store)), 1)}Error`](e, id)
            );
        }
        finally {
            this.showLoading && store.dispatch(loading(`delete-${pluralize(camelCase(this.store), 1)}`));
        }
    }
}

export default ServiceFactory;
