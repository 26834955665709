/**
 * Visits ActionTypes definitions
 */


export const CLEAR_VISITS_LIST = 'visits/list/clear';
export const CLEAR_VISIT = 'visit/clear';

export const VISITS_LIST_SUCCESS = 'visits/list/request';
export const VISIT_LOADING = 'visit/loading';
export const VISIT_ERROR = 'visit/error';
export const VISIT_SUCCESS = 'visit/success';
export const VISIT_DELETE_SUCCESS = 'visit/delete/request';
