/**
 * Visits Reducer definitions
 */

import keyBy from 'lodash/keyBy';

import {LOGOUT} from 'store/auth/AuthActionTypes';
import {
    CLEAR_VISITS_LIST,
    CLEAR_VISIT,
    VISITS_LIST_SUCCESS,
    VISIT_LOADING,
    VISIT_ERROR,
    VISIT_SUCCESS,
    VISIT_DELETE_SUCCESS
} from 'store/visit/VisitsActionTypes';

const emptyObject = {};
const initialState = {
    data: emptyObject,
    status: 'idle',
    error: null
};

export default function visits(state = initialState, action) {
    switch (action.type) {
        case CLEAR_VISITS_LIST: {
            return initialState;
        }
        case CLEAR_VISIT: {
            const {[action.id]: removed, ...newData} = state.data;
            return {
                ...state,
                data: newData,
                status: 'idle',
                error: null
            };
        }
        case VISITS_LIST_SUCCESS: {
            return {
                ...initialState,
                status: action.status,
                patientId: action.patientId,
                data: keyBy(action.data, 'id'),
                error: null
            };
        }
        case VISIT_LOADING: {
            return {
                ...state,
                status: 'loading',
                error: null
            };
        }
        case VISIT_SUCCESS: {
            return {
                ...state,
                status: 'idle',
                data: {...state.data, [action.id]: action.data},
                error: null
            };
        }
        case VISIT_DELETE_SUCCESS: {
            const {[action.id]: removed, ...restData} = state.data;
            return {
                ...state,
                data: restData,
                status: 'idle',
                error: null
            };
        }
        case VISIT_ERROR: {
            return {
                ...state,
                status: 'error',
                error: action.error
            };
        }

        case LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

