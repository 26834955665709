import store from 'store';
import {errorNotification} from 'store/ui/UIActions';

class SystemError {
    constructor({...props}) {
        const {
            error,
            message,
            ...rest
        } = props;

        this.error = error;
        this.message = message;

        store.dispatch(errorNotification({message}));
    }
}

export default SystemError;
