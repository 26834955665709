/**
 * hobby Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';

import {
    CLEAR_HOBBIES,
    GET_HOBBIES_REQUEST,
    GET_HOBBIES_ERROR,
    GET_HOBBIES_SUCCESS,
    CLEAR_HOBBY,
    GET_HOBBY_REQUEST,
    GET_HOBBY_ERROR,
    GET_HOBBY_SUCCESS,
    UPDATE_HOBBY_REQUEST,
    UPDATE_HOBBY_ERROR,
    UPDATE_HOBBY_SUCCESS,
    CREATE_HOBBY_REQUEST,
    CREATE_HOBBY_ERROR,
    CREATE_HOBBY_SUCCESS,
    DELETE_HOBBY_REQUEST,
    DELETE_HOBBY_ERROR,
    DELETE_HOBBY_SUCCESS
} from 'store/hobbies/HobbyActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function hobbies(state = initialState, action) {

    let newState = {};

    switch (action.type) {
        case CLEAR_HOBBIES: {
            newState = {
                ...initialState
            };
            break;
        }
        case GET_HOBBIES_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_HOBBIES_SUCCESS: {
            newState = {
                ...initialState,
                status: action.status,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_HOBBIES_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_HOBBY: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_HOBBY_REQUEST:
        case UPDATE_HOBBY_REQUEST:
        case CREATE_HOBBY_REQUEST:
        case DELETE_HOBBY_REQUEST: {
            const newData = {...state.data};
            //Object.assign(newData, {[action.id]: {status: action.status}});
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_HOBBY_SUCCESS:
        case UPDATE_HOBBY_SUCCESS:
        case CREATE_HOBBY_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_HOBBY_ERROR:
        case UPDATE_HOBBY_ERROR:
        case CREATE_HOBBY_ERROR:
        case DELETE_HOBBY_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_HOBBY_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
