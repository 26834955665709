/* eslint-disable no-useless-escape */
import _ from 'lodash';
import i18next from 'core/i18next';
import AuthSelectors from 'store/auth/AuthSelectors';
import store from 'store/index';

export {dateLater} from 'common/validators/dateLater';

const isEmptyValue = _.cond([
    [_.isNil, () => true],
    [_.isString, value => !_.trim(value)],
    [_.isObject, value => _.isEmpty(value)],
    [_.isNumber, _.isNaN]
]);
export const required = value => {
    return isEmptyValue(value) ?
        i18next.t('errors:required-field') :
        undefined;
};

export const maxLength = max => value =>
    value && value.length > max ?
        i18next.t('errors:max-length', {max}) :
        undefined;


export const minLength = min => value =>
    value && value.length < min ?
        i18next.t('errors:min-length', {min}) :
        undefined;

export const oneTimePassCode = value =>
    value && (isNaN(Number(value)) || value.length !== 6) ?
        i18next.t('errors:one-time-pass') :
        undefined;

export const number = value =>
    value && isNaN(Number(value)) ?
        i18next.t('errors:number') :
        undefined;

export const textOnly = value =>
    value && !value.match(/^[A-Z]+$/i) ?
        i18next.t('errors:textOnly') :
        undefined;

export const textOnlyExtended = value =>
    value && !value.match(/^[A-Z- ]+$/i) ?
        i18next.t('errors:textOnly') :
        undefined;

export const textOnlyWithUmlautExtended = value =>
    value && !value.match(/^[A-Z- äöüèéÄÖÜÈÉ]+$/i) ?
        i18next.t('errors:textOnly') :
        undefined;

export const umlaut = value =>
    value && value.match(/^[äöüèéÄÖÜÈÉ]+$/i) ?
        i18next.t('errors:umlaut') :
        undefined;

export const integer = value =>
    value && typeof value === 'string' && !value.match(/^([+-]?[1-9]\d*|0)$/) ?
        i18next.t('errors:integer') :
        undefined;

const decimal = value =>
    value && !/^-?(0|[1-9]\d*)(\.\d+)?$/.test(value) ?
        i18next.t('errors:number') :
        undefined;

export const decimalExtended = value =>
    value && !/^[<>]?-?(0|[1-9]\d*)([.,]\d+)?$/.test(value) ?
        i18next.t('errors:number') :
        undefined;

export const maxDecimal = (before = 0, after = 0) => value => {
    const [integer, decimal = ''] = String(value).split('.');

    return value && (integer.length > before || decimal.length > after) ?
        i18next.t('errors:max-decimal', {before, after}) :
        undefined;
};

export const minValue = min => value =>
    value && value < min ?
        i18next.t('errors:min-value', {min}) :
        undefined;

export const maxValue = max => value =>
    value && value > max ?
        i18next.t('errors:max-value', {max}) :
        undefined;

export const email = value =>
    value && !/^[A-Z0-9\.\_\%\+\-äöüèéÄÖÜÈÉ]+@[A-Z0-9\.\-äöüèéÄÖÜÈÉ]+\.[A-Z]{2,4}$/i.test(value) ?
        i18next.t('errors:invalid-email') :
        undefined;

export const noPublicEmail = value =>
    value && /.+@(aol\.com|yahoo\.com|msn\.com|gmail\.com|outlook\.com|hotmail\.com)/.test(value) ?
        i18next.t('errors:no-public-email') :
        undefined;

export const alphaNumeric = value =>
    value && /[^a-zA-Z0-9]/i.test(value) ?
        i18next.t('errors:only-alpha-numeric') :
        undefined;

export const slug = value =>
    value && /[^a-zA-Z0-9_-]/i.test(value) ?
        i18next.t('errors:only-alpha-numeric') :
        undefined;


export const phoneNumber = value =>
    value && !/^\+[1-9]\d{1,14}$/gi.test(value) ?
        i18next.t('errors:invalid-phone-number') :
        undefined;

export const zip = value =>
    value && !/^\d{5}(-\d{4})?$/gi.test(value) ?
        i18next.t('errors:invalid-zip') :
        undefined;

export const domain = value =>
    value && !/^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/i.test(value) ?
        i18next.t('errors:invalid-domain') :
        undefined;

export const password = value =>
    value && !/^(?=.{10,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(value) ?
        i18next.t('errors:invalid-password') :
        undefined;

export const shouldMatch = (value, allValues, rules, fieldName) => {
    const confirmAgainstFieldName = fieldName.replace('_confirmation', '');
    const confirmAgainstField = allValues[confirmAgainstFieldName];
    return value !== confirmAgainstField ?
        i18next.t('errors:should-match', {confirmAgainst: confirmAgainstFieldName}) : undefined;
};

export const shouldExplain = (value, allValues, rules, fieldName) => {
    const state = store.getState();
    const isPatient = AuthSelectors.getIsPatient(state);
    if (isPatient) {
        return undefined;
    }
    const confirmAgainstFieldName = `${fieldName}_reason`;
    const confirmAgainstField = _.get(allValues, confirmAgainstFieldName);

    return typeof confirmAgainstField !== 'undefined' && confirmAgainstField === null || confirmAgainstField?.trim() === '' ?
        i18next.t('patients:change-need-icon-tooltip') : undefined;
};

export default {
    required,
    maxLength,
    minLength,
    number,
    minValue,
    maxValue,
    email,
    noPublicEmail,
    alphaNumeric,
    phoneNumber,
    password,
    shouldMatch,
    slug
};
