import {CONFIG_ERROR, CONFIG_LOADED, REQUEST_CONFIG} from 'store/root/rootActionTypes';
import {loading, errorNotification} from 'store/ui/UIActions';

const requestConfigAction = () => ({
    type: REQUEST_CONFIG
});

const configLoadErrorAction = e => ({
    type: CONFIG_ERROR,
    error: e
});

const configLoadedAction = data => ({
    type: CONFIG_LOADED,
    data: data
});

export function requestConfig() {
    return dispatch => {
        dispatch(requestConfigAction());
        dispatch(loading({
            type: 'config-loading',
            message: 'Loading core configuration and preferences'
        }));
    };
}

export function configLoaded(data) {
    return dispatch => {
        dispatch(configLoadedAction(data));
        dispatch(loading('config-loading'));
    };
}

export function configLoadError(error) {
    return dispatch => {
        dispatch(configLoadErrorAction(error));
        dispatch(loading('config-loading'));
        dispatch(errorNotification(error));
    };
}
