/**
 * patient Reducer definitions
 */

export const CLEAR_PATIENTS = 'patients/clear';
export const CLEAR_PATIENTS_PAGES = 'patients/clear/pages';
export const GET_PATIENTS_REQUEST = 'patients/request';
export const GET_PATIENTS_ERROR = 'patients/error';
export const GET_PATIENTS_SUCCESS = 'patients/success';
export const CLEAR_PATIENT = 'patient/clear';
export const GET_PATIENT_REQUEST = 'patient/get/request';
export const GET_PATIENT_ERROR = 'patient/get/error';
export const GET_PATIENT_SUCCESS = 'patient/get/success';
export const UPDATE_PATIENT_REQUEST = 'patient/update/request';
export const UPDATE_PATIENT_ERROR = 'patient/update/error';
export const UPDATE_PATIENT_SUCCESS = 'patient/update/success';
export const PARTIAL_UPDATE_PATIENT_ERROR = 'patient/update/partial/error';
export const PARTIAL_UPDATE_PATIENT_SUCCESS = 'patient/update/partial/success';
export const CREATE_PATIENT_REQUEST = 'patient/create/request';
export const CREATE_PATIENT_ERROR = 'patient/create/error';
export const CREATE_PATIENT_SUCCESS = 'patient/create/success';
export const DELETE_PATIENT_REQUEST = 'patient/delete/request';
export const DELETE_PATIENT_ERROR = 'patient/delete/error';
export const DELETE_PATIENT_SUCCESS = 'patient/delete/success';

export const SET_PATIENT_FILTERS = 'patient/set/filters';
