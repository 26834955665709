import {combineReducers} from 'redux';
import {reducer as reduxFormReducer} from 'redux-form';
import {connectRouter} from 'connected-react-router';
import auth from 'store/auth/AuthReducer';
import patient_history from 'store/patient_history/PatientHistoryReducer';
import user from 'store/user/UserReducer';
import ui from 'store/ui/UIReducer';
import RootReducer from 'store/root/rootReducer';
import permissions from 'store/permission/PermissionReducer';
import organizations from 'store/organization/OrganizationReducer';
import locations from 'store/location/LocationReducer';
import roles from 'store/role/RoleReducer.js';
import langs from 'store/lang/LangReducer';
import translations from 'store/translation/TranslationReducer';
import patients from 'store/patient/PatientReducer';
import medications from 'store/medication/MedicationReducer';
import medical_history from 'store/medical_history/MedicalHistoryReducer';
import documents from 'store/document/DocumentsReducers';
import visits from 'store/visit/VisitsReducers';
import visitActivities from 'store/visit/ActivitiesReducers';
import questionnaires from 'store/questionnaires/QuestionnaireReducer';
import imaging from 'store/imagingResults/ImagingResultsReducer';
import tests from 'store/testResults/TestResultReducer';
import labs from 'store/lab/LabReducer';
import allergies from 'store/allergy/AllergyReducer';
import vitals from 'store/vitals/VitalsReducer';
import hobbies from 'store/hobbies/HobbyReducer';
import oauth from 'store/oauth/OAuthReducer';
import modules from 'store/module/ModuleReducer';
import cognitiveStatus from 'store/cognitiveStatus/CognitiveStatusReducers';
import patientHistory from 'store/patient_history/PatientHistoryReducer';

const createRootReducer = (history) => ({
    router: connectRouter(history),
    config: RootReducer,
    ui,
    user,
    allergies,
    auth,
    permissions,
    roles,
    organizations,
    locations,
    langs,
    translations,
    patients,
    medications,
    medical_history,
    visits,
    visitActivities,
    documents,
    questionnaires,
    imaging,
    tests,
    labs,
    vitals,
    hobbies,
    oauth,
    modules,
    cognitiveStatus,
    patient_history,
    form: reduxFormReducer
});

export default createRootReducer;
