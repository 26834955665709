import {LOGOUT} from 'store/auth/AuthActionTypes';
import {
    CONFIG_LOADED,
    CONFIG_ERROR,
    REQUEST_CONFIG
} from 'store/root/rootActionTypes';

const initialState = {
    status: 'idle'
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REQUEST_CONFIG:
            return {
                ...state,
                status: 'loading'
            };
        case CONFIG_ERROR:
            return {
                ...initialState,
                status: 'error',
                error: action.error
            };
        case CONFIG_LOADED:
            console.log('config: ', action.data);
            return {
                ...initialState.config,
                ...action.data
            };
        case LOGOUT:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
