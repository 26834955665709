export default {
    url: 'https://api.altoida.com',
    routes: {
        auth: {
            config: {
                url: '/config',
                method: 'GET'
            },
            logout: {
                url: '/auth/logout',
                method: 'POST'
            },
            login: {
                url: '/auth/login',
                method: 'POST'
            },
            refresh: {
                url: '/auth/refresh',
                method: 'POST'
            },
            activate: {
                url: '/auth/activate/:activateToken?',
                method: 'POST'
            },
            reactivate: {
                url: '/auth/reactivate/:activateToken?',
                method: 'POST'
            },
            confirmEmail: {
                url: '/auth/confirm_email/:confirmToken?',
                method: 'POST'
            },
            forgotPassword: {
                url: '/auth/forgot_password',
                method: 'POST'
            },
            resetPassword: {
                url: '/auth/reset_password/:resetToken?',
                method: 'POST'
            },
            requestNewCodeActivation: {
                url: '/auth/generate/activate',
                method: 'POST'
            },
            changePassword: {
                url: '/auth/change-password',
                method: 'POST'
            },
            validateMFA: {
                url: '/auth/mfa/verify',
                method: 'POST'
            },
            enableMFA: {
                url: '/auth/mfa/enable',
                method: 'POST'
            },
            sendMFACode: {
                url: '/auth/mfa/sendCode',
                method: 'POST'
            },
            unlock: {
                url: '/auth/unlock',
                method: 'POST'
            },
            lock: {
                url: '/auth/lock',
                method: 'POST'
            },
            recertification: {
                url: '/auth/recertification/:token',
                method: 'POST'
            },
            recertificationReactivate: {
                url: '/auth/recertification/reactivate/:token',
                method: 'POST'
            },
            requestNewCodeRecertification: {
                url: '/auth/generate/recertification',
                method: 'POST'
            }
        },
        user: {
            profile: {
                url: '/users/:userId',
                method: 'GET'
            },
            getBudget: {
                url: '/user/budget',
                method: 'POST'
            }
        },
        users: {
            list: {
                url: '/admin/users',
                method: 'GET',
                scopes: ['list-users']
            },
            create: {
                url: '/admin/users',
                method: 'PUT',
                scopes: ['create-user']
            },
            get: {
                url: '/admin/users/:userId',
                method: 'GET',
                scopes: ['view-user']
            },
            update: {
                url: '/admin/users/:userId',
                method: 'POST',
                scopes: ['update-user']
            },
            delete: {
                url: '/admin/users/:userId',
                method: 'DELETE',
                scopes: ['delete-user']
            },
            setOrg: {
                url: '/admin/users/:userId/set-organization',
                method: 'PATCH'
            },
            validate: {
                url: '/patient/validate-email',
                method: 'POST'
            },
            bulkRecertification: {
                url: '/admin/recertifications/bulk-users',
                method: 'POST'
            },
            block: {
                url: '/users/:userId/block',
                method: 'PATCH'
            },
            deactivate: {
                url: '/users/:userId/deactivate',
                method: 'PATCH'
            },
            reactivate: {
                url: '/users/:userId/reactivate',
                method: 'PATCH'
            }
        },
        permission: {
            list: {
                url: '/admin/permissions',
                method: 'GET',
                scopes: ['list-permissions']
            },
            create: {
                url: '/admin/permissions',
                method: 'PUT',
                scopes: ['create-permission']
            },
            get: {
                url: '/admin/permissions/:permissionId',
                method: 'GET',
                scopes: ['view-permission']
            },
            update: {
                url: '/admin/permissions/:permissionId',
                method: 'POST',
                scopes: ['update-permission']
            },
            delete: {
                url: '/admin/permissions/:permissionId',
                method: 'DELETE',
                scopes: ['delete-permission']
            }
        },
        workers: {
            list: {
                url: '/admin/analysis-workers',
                method: 'GET',
                scopes: ['list-analysis-workers']
            },
            create: {
                url: '/admin/analysis-workers',
                method: 'PUT',
                scopes: ['create-analysis-worker']
            },
            get: {
                url: '/admin/analysis-workers/:workerId',
                method: 'GET',
                scopes: ['view-analysis-worker']
            },
            update: {
                url: '/admin/analysis-workers/:workerId',
                method: 'POST',
                scopes: ['update-analysis-worker']
            },
            delete: {
                url: '/admin/analysis-workers/:workerId',
                method: 'DELETE',
                scopes: ['delete-analysis-worker']
            }
        },
        oauth: {
            list: {
                url: '/admin/oauth/clients',
                method: 'GET',
                scopes: ['oauth-list-clients']
            },
            create: {
                url: '/admin/oauth/clients',
                method: 'PUT',
                scopes: ['oauth-create-client']
            },
            get: {
                url: '/admin/oauth/clients/:oauthId',
                method: 'GET',
                scopes: ['oauth-view-client']
            },
            delete: {
                url: '/admin/oauth/clients/:oauthId/revoke',
                method: 'DELETE',
                scopes: ['oauth-revoke-client']
            }
        },
        role: {
            list: {
                url: '/admin/roles',
                method: 'GET',
                scopes: ['list-roles']
            },
            create: {
                url: '/admin/roles',
                method: 'PUT',
                scopes: ['create-role']
            },
            get: {
                url: '/admin/roles/:roleId',
                method: 'GET',
                scopes: ['view-role']
            },
            update: {
                url: '/admin/roles/:roleId',
                method: 'POST',
                scopes: ['update-role']
            },
            delete: {
                url: '/admin/roles/:roleId',
                method: 'DELETE',
                scopes: ['delete-role']
            }
        },
        translation: {
            bulkUpdate: {
                url: '/admin/translations/bulkUpdate',
                method: 'POST'
            },
            list: {
                url: '/admin/translations',
                method: 'GET',
                scopes: ['list-translations']
            },
            create: {
                url: '/admin/translations',
                method: 'PUT',
                scopes: ['create-translation']
            },
            get: {
                url: '/admin/translations/:translationId',
                method: 'GET',
                scopes: ['view-translation']
            },
            update: {
                url: '/admin/translations/:translationId',
                method: 'POST',
                scopes: ['update-translation']
            },
            delete: {
                url: '/admin/translations/:translationId',
                method: 'DELETE',
                scopes: ['delete-translation']
            }
        },
        language: {
            list: {
                url: '/admin/languages',
                method: 'GET',
                scopes: ['list-languages']
            },
            create: {
                url: '/admin/languages',
                method: 'PUT',
                scopes: ['create-language']
            },
            get: {
                url: '/admin/languages/:languageId',
                method: 'GET',
                scopes: ['view-language']
            },
            update: {
                url: '/admin/languages/:languageId',
                method: 'POST',
                scopes: ['update-language']
            },
            delete: {
                url: '/admin/languages/:languageId',
                method: 'DELETE',
                scopes: ['delete-language']
            },
            public: {
                url: '/translate',
                method: 'GET'
            }
        },
        module: {
            list: {
                url: '/admin/modules',
                method: 'GET'
            },
            create: {
                url: '/admin/modules',
                method: 'PUT'
            },
            get: {
                url: '/admin/modules/:moduleId',
                method: 'GET'
            },
            update: {
                url: '/admin/modules/:moduleId',
                method: 'POST'
            },
            delete: {
                url: '/admin/modules/:moduleId',
                method: 'DELETE'
            }
        },
        organization: {
            list: {
                url: '/admin/organizations',
                method: 'GET',
                scopes: ['list-organizations']
            },
            create: {
                url: '/admin/organizations',
                method: 'PUT',
                scopes: ['create-organization']
            },
            get: {
                url: '/admin/organizations/:organizationId',
                method: 'GET',
                scopes: ['view-organization']
            },
            update: {
                url: '/admin/organizations/:organizationId',
                method: 'POST',
                scopes: ['update-organization']
            },
            delete: {
                url: '/admin/organizations/:organizationId',
                method: 'DELETE',
                scopes: ['delete-organization']
            },
            transferCredit: {
                url: '/admin/credits/:task/:target_type/:id',
                method: 'POST'
            },
            addModule: {
                url: '/admin/organizations/:organizationId/modules',
                method: 'PUT'
            },
            removeModule: {
                url: '/admin/organizations/:organizationId/modules/:moduleId',
                method: 'DELETE'
            }
        },
        location: {
            list: {
                url: '/admin/locations',
                method: 'GET',
                scopes: ['list-locations']
            },
            create: {
                url: '/admin/locations',
                method: 'PUT',
                scopes: ['create-location']
            },
            get: {
                url: '/admin/locations/:locationId',
                method: 'GET',
                scopes: ['view-location']
            },
            update: {
                url: '/admin/locations/:locationId',
                method: 'POST',
                scopes: ['update-location']
            },
            delete: {
                url: '/admin/locations/:locationId',
                method: 'DELETE',
                scopes: ['delete-location']
            }
        },
        hobbies: {
            list: {
                url: '/admin/hobbies',
                method: 'GET',
                scopes: ['list-hobbies']
            },
            create: {
                url: '/admin/hobbies',
                method: 'PUT',
                scopes: ['create-hobby']
            },
            get: {
                url: '/admin/hobbies/:hobbyId',
                method: 'GET',
                scopes: ['view-hobby']
            },
            update: {
                url: '/admin/hobbies/:hobbyId',
                method: 'POST',
                scopes: ['update-hobby']
            },
            delete: {
                url: '/admin/hobbies/:hobbyId',
                method: 'DELETE',
                scopes: ['delete-hobby']
            }
        },
        patient: {
            list: {
                url: '/admin/patients',
                method: 'GET',
                scopes: ['list-patients']
            },
            create: {
                url: '/admin/patients',
                method: 'PUT',
                scopes: ['create-patient']
            },
            get: {
                url: '/admin/patients/:patientId',
                method: 'GET',
                scopes: ['view-patient']
            },
            update: {
                url: '/admin/patients/:patientId',
                method: 'POST',
                scopes: ['update-patient']
            },
            delete: {
                url: '/admin/patients/:patientId',
                method: 'DELETE',
                scopes: ['delete-patient']
            },
            release: {
                url: '/admin/patients/:patientId/release-user',
                method: 'POST',
                scopes: ['isAdministrator']
            },
            generateLogin: {
                url: '/admin/patients/:patientId/generate-program-login',
                method: 'POST',
                scopes: ['create-program-id-login']
            },
            revokeLogin: {
                url: '/admin/patients/:patientId/revoke-program-login',
                method: 'POST',
                scopes: ['delete-program-id-login']
            }
        },
        hobby: {
            list: {
                url: '/admin/patients/:patientId/hobbies',
                method: 'GET',
                scopes: ['list-patient-hobbies']
            },
            create: {
                url: '/admin/patients/:patientId/hobbies',
                method: 'PUT',
                scopes: ['create-patient-hobby']
            },
            update: {
                url: '/admin/patients/:patientId/hobbies',
                method: 'POST',
                scopes: ['update-patient-hobby']
            },
            delete: {
                url: '/admin/patients/:patientId/hobbies/:hobbyId',
                method: 'DELETE',
                scopes: ['delete-patient-hobby']
            }
        },
        medication: {
            list: {
                url: '/admin/medications/:patientId',
                method: 'GET',
                scopes: ['list-patient-medications']
            },
            create: {
                url: '/admin/medications/:patientId',
                method: 'PUT',
                scopes: ['create-patient-medication']
            },
            get: {
                url: '/admin/medications/:medicationId',
                method: 'GET',
                scopes: ['view-patient-medication']
            },
            update: {
                url: '/admin/medications/:medicationId',
                method: 'POST',
                scopes: ['update-patient-medication']
            },
            delete: {
                url: '/admin/medications/:medicationId',
                method: 'DELETE',
                scopes: ['delete-patient-medication']
            }
        },
        medical_history: {
            list: {
                url: '/admin/medical-histories/:patientId',
                method: 'GET',
                scopes: ['list-patient-medical-histories']
            },
            create: {
                url: '/admin/medical-histories/:patientId',
                method: 'PUT',
                scopes: ['create-patient-medical-history']
            },
            get: {
                url: '/admin/medical-histories/:medicalHistoryId',
                method: 'GET',
                scopes: ['view-patient-medical-history']
            },
            update: {
                url: '/admin/medical-histories/:medicalHistoryId',
                method: 'POST',
                scopes: ['update-patient-medical-history']
            },
            delete: {
                url: '/admin/medical-histories/:medicalHistoryId',
                method: 'DELETE',
                scopes: ['delete-patient-medical-history']
            }
        },
        allergy: {
            list: {
                url: '/admin/allergies/:patientId',
                method: 'GET',
                scopes: ['list-patient-allergies']
            },
            create: {
                url: '/admin/allergies/:patientId',
                method: 'PUT',
                scopes: ['create-patient-allergy']
            },
            get: {
                url: '/admin/allergies/:allergyId',
                method: 'GET',
                scopes: ['view-patient-allergy']
            },
            update: {
                url: '/admin/allergies/:allergyId',
                method: 'POST',
                scopes: ['update-patient-allergy']
            },
            delete: {
                url: '/admin/allergies/:allergyId',
                method: 'DELETE',
                scopes: ['delete-patient-allergy']
            }
        },
        document: {
            list: {
                url: '/admin/documents/patients/:patientId',
                method: 'GET',
                scopes: ['list-patient-documents']
            },
            categoriesList: {
                url: '/admin/documents/categories',
                method: 'GET',
                scopes: ['list-patient-documents']
            },
            get: {
                url: '/admin/documents/patients/:patientId',
                method: 'GET',
                scopes: ['view-patient-document']
            },
            create: {
                url: '/admin/documents/patients/:patientId/categories/:categoryId/upload',
                method: 'POST',
                scopes: ['create-patient-document']
            },
            update: {
                url: '/admin/documents/patients/:patientId/categories/:categoryId/upload',
                method: 'POST',
                scopes: ['update-patient-document']
            },
            delete: {
                url: '/admin/documents/patients/:documentId',
                method: 'DELETE',
                scopes: ['delete-patient-document']
            },
            download: {
                url: '/admin/documents/patients/:patientId/download/:documentId',
                method: 'GET',
                scopes: ['view-patient-document']
            },
            createReport: {
                url: '/admin/reports/:patientId/full-report',
                method: 'POST',
                scopes: ['view-patient-document']
            },
            signReport: {
                url: '/admin/reports/:patientId/full-report/:reportId/sign',
                method: 'POST',
                scopes: ['view-patient-document']
            }
        },
        questionnaires: {
            list: {
                url: '/admin/questionnaires',
                method: 'GET',
                scopes: ['list-patient-questionnaires']
            },
            get: {
                url: '/admin/questionnaires/:questionnaireId',
                method: 'GET',
                scopes: ['view-patient-questionnaire']
            },
            create: {
                url: '/admin/patient-answers/:patientId/questionnaires/:questionnaireId',
                method: 'POST',
                scopes: ['create-patient-questionnaire']
            },
            update: {
                url: '/admin/patient-answers/:patientId/questionnaires/:questionnaireId',
                method: 'PUT',
                scopes: ['update-patient-questionnaire']
            },
            getAnswer: {
                url: '/admin/patient-answers/:patientId/questionnaires/:questionnaireId',
                method: 'GET'
            },
            getOrgAssessments: {
                url: '/admin/questionnaires/organizations/:organizationId',
                method: 'GET'
            },
            assignAssessments: {
                url: '/admin/questionnaires/assign-organization',
                method: 'POST',
                scopes: ['create-assign-assessment']
            },

            saveAnswer: {
                url: '/admin/patients/:patientId/assessments/:patientQuestionnaireId/answers',
                method: 'PUT'
            },
            updateAnswer: {
                url: '/admin/patients/:patientId/assessments/:patientQuestionnaireId/answers/:answerId',
                method: 'POST'
            },
            getPatientQuestionnaires: {
                url: '/admin/patients/:patientId/assessments',
                method: 'GET'
            },
            complete: {
                url: '/admin/patients/:patientId/assessments/:patientQuestionnaireId/complete',
                method: 'PUT'
            }

        },
        tests: {
            list: {
                url: '/admin/results/:patientId?',
                method: 'GET',
                scopes: ['list-test-results']
            },
            download: {
                url: '/admin/reports/:patientId/:resultId',
                method: 'GET'
            },
            downloadRaw: {
                url: '/admin/results/:patientId/:resultId/zip',
                method: 'GET',
                scopes: ['view-download-raw-datum']
            },
            retryIntegration: {
                url: '/admin/results/:resultId/integrate',
                method: 'POST'
            }
        },
        vitals: {
            list: {
                url: '/admin/vitals/:patientId',
                method: 'GET',
                scopes: ['list-vitals']
            },
            update: {
                url: '/admin/vitals/:patientId/:vitalId',
                method: 'POST',
                scopes: ['update-vital']
            },
            create: {
                url: '/admin/vitals/:patientId',
                method: 'PUT',
                scopes: ['create-vital']
            },
            delete: {
                url: '/admin/vitals/:patientId/:vitalId',
                method: 'DELETE',
                scopes: ['delete-vital']
            }
        },
        visits: {
            list: {
                url: '/admin/visits/:patientId/visits',
                method: 'GET',
                scopes: ['list-visits']
            },
            update: {
                url: '/admin/visits/:patientId/visits/:visitId',
                method: 'POST',
                scopes: ['update-visit']
            },
            create: {
                url: '/admin/visits/:patientId/visits',
                method: 'PUT',
                scopes: ['create-visit']
            },
            delete: {
                url: '/admin/visits/:patientId/visits/:visitId',
                method: 'DELETE',
                scopes: ['delete-visit']
            }
        },
        patient_history: {
            list: {
                url: '/admin/patients/:patientId/history',
                method: 'GET',
                scopes: ['list-patient-histories']
            }
        },
        visitActivities: {
            list: {
                url: '/admin/visits/:patientId/visits/:visitId/activities',
                method: 'GET',
                scopes: ['list-visit-activities']
            },
            update: {
                url: '/admin/visits/:patientId/visits/:visitId/activities/:activityId',
                method: 'POST',
                scopes: ['update-visit-activity']
            },
            create: {
                url: '/admin/visits/:patientId/visits/:visitId/activities',
                method: 'PUT',
                scopes: ['create-visit-activity']
            },
            delete: {
                url: '/admin/visits/:patientId/visits/:visitId/activities/:activityId',
                method: 'DELETE',
                scopes: ['delete-visit-activity']
            }
        },
        labResult: {
            list: {
                url: '/admin/patients/:patientId/lab-results',
                method: 'GET',
                scopes: ['list-lab-results']
            },
            create: {
                url: '/admin/patients/:patientId/lab-results',
                method: 'PUT',
                scopes: ['create-lab-result']
            },
            update: {
                url: '/admin/patients/:patientId/lab-results/:labResultId',
                method: 'POST',
                scopes: ['update-lab-result']
            },
            delete: {
                url: '/admin/patients/:patientId/lab-results/:labResultId',
                method: 'DELETE',
                scopes: ['delete-lab-result']
            }
        },
        imagingResult: {
            list: {
                url: '/admin/patients/:patientId/imaging-results',
                method: 'GET',
                scopes: ['list-imaging-results']
            },
            create: {
                url: '/admin/patients/:patientId/imaging-results',
                method: 'PUT',
                scopes: ['create-imaging-result']
            },
            update: {
                url: '/admin/patients/:patientId/imaging-results/:imagingResultId',
                method: 'POST',
                scopes: ['update-imaging-result']
            },
            delete: {
                url: '/admin/patients/:patientId/imaging-results/:imagingResultId',
                method: 'DELETE',
                scopes: ['delete-imaging-result']
            },
            upload: {
                url: '/admin/patients/:patientId/imaging-results/:imagingResultId/upload',
                method: 'POST',
                scopes: ['create-imaging-result']
            },
            download: {
                url: '/admin/patients/:patientId/imaging-results/:imagingResultId/download',
                method: 'GET',
                scopes: ['view-imaging-result']
            }
        },
        cognitiveStatus: {
            list: {
                url: '/admin/patients/:patientId/cognitive-statuses',
                method: 'GET',
                scopes: ['list-cognitive-statuses']
            },
            update: {
                url: '/admin/patients/:patientId/cognitive-statuses/:cognitiveStatusId',
                method: 'POST',
                scopes: ['update-cognitive-status']
            },
            create: {
                url: '/admin/patients/:patientId/cognitive-statuses',
                method: 'PUT',
                scopes: ['create-cognitive-status']
            },
            delete: {
                url: '/admin/patients/:patientId/cognitive-statuses/:cognitiveStatusId',
                method: 'DELETE',
                scopes: ['delete-cognitive-status']
            }
        },
        fitbit: {
            connect: {
                url: '/fitbit/:testSubjectId',
                method: 'GET',
                scopes: ['isPatient']
            },
            disconnect: {
                url: '/services/fitbit/disconnect',
                method: 'POST',
                scopes: ['isPatient']
            }
        }
    }
};
