/**
 * oauth Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';

import {
    CLEAR_OAUTHS,
    GET_OAUTHS_REQUEST,
    GET_OAUTHS_ERROR,
    GET_OAUTHS_SUCCESS,
    CLEAR_OAUTH,
    GET_OAUTH_REQUEST,
    GET_OAUTH_ERROR,
    GET_OAUTH_SUCCESS,
    UPDATE_OAUTH_REQUEST,
    UPDATE_OAUTH_ERROR,
    UPDATE_OAUTH_SUCCESS,
    CREATE_OAUTH_REQUEST,
    CREATE_OAUTH_ERROR,
    CREATE_OAUTH_SUCCESS,
    DELETE_OAUTH_REQUEST,
    DELETE_OAUTH_ERROR,
    DELETE_OAUTH_SUCCESS
} from 'store/oauth/OAuthActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function oauths(state = initialState, action) {

    let newState = {};

    switch (action.type) {
        case CLEAR_OAUTHS: {
            newState = {
                ...initialState
            };
            break;
        }
        case GET_OAUTHS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_OAUTHS_SUCCESS: {
            newState = {
                ...initialState,
                status: action.status,
                data: {
                    ...keyBy(action.data, 'client_id')
                },
                error: null
            };
            break;
        }
        case GET_OAUTHS_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_OAUTH: {
            const {[action.client_id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_OAUTH_REQUEST:
        case UPDATE_OAUTH_REQUEST:
        case CREATE_OAUTH_REQUEST:
        case DELETE_OAUTH_REQUEST: {
            const newData = {...state.data};
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_OAUTH_SUCCESS:
        case UPDATE_OAUTH_SUCCESS:
        case CREATE_OAUTH_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.client_id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_OAUTH_ERROR:
        case UPDATE_OAUTH_ERROR:
        case CREATE_OAUTH_ERROR:
        case DELETE_OAUTH_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_OAUTH_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
