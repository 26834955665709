import React from 'react';
import PropTypes from 'prop-types';
import {Snackbar} from '@material-ui/core';
import {connect} from 'react-redux';
import {closeNotification} from 'store/ui/UIActions';
import Notification from './Notification';
import UISelector from 'store/ui/UISelectors';
import {Alert} from '@material-ui/lab';

NotificationContainer.propTypes = {
    timeout: PropTypes.number,
    position: PropTypes.shape({vertical: PropTypes.string, horizontal: PropTypes.string}),
    closeNotification: PropTypes.func,
    notifications: PropTypes.object
};

function NotificationContainer(props) {
    const {
        notifications,
        timeout,
        position,
        closeNotification
    } = props;
    return (
        <React.Fragment>
            {
                notifications && (Object.keys(notifications)).map((index) => (
                    [
                        <Snackbar
                            open={true}
                            anchorOrigin={{
                                horizontal: 'center',
                                vertical: 'bottom'
                            }}
                            autoHideDuration={notifications[index].timeout || timeout}
                            key={'notification_' + index}
                            onClose={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }

                                closeNotification(index);
                            }}
                        >
                            <Alert elevation={6} variant={'filled'} {...notifications[index]}
                                onClose={() => closeNotification(index)}
                            >
                                {notifications[index].text}
                            </Alert>
                        </Snackbar>
                    ]
                ))
            }
        </React.Fragment>
    );
}

NotificationContainer.defaultProps = {
    position: {
        vertical: 'bottom', horizontal: 'left'
    }
};

const mapStateToProps = (state) => {
    return {
        notifications: UISelector.getNotifications(state)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        closeNotification: (index) => dispatch(closeNotification(index))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
