import {Button, Grid, Typography, withStyles} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Alert} from '@material-ui/lab';
import classNames from 'classnames';

import RouteUtil from 'common/RouteUtil';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouteLink} from 'react-router-dom';
import {Field} from 'redux-form';
import LoadingButton from 'views/components/LoadingButton';
import Logo from 'views/components/Logo';

const CommonFormBuilder = (props) => {
    const {
        invalid,
        handleSubmit,
        theme,
        onSubmit,
        pristine,
        submitting,
        result,
        error,
        classes,
        translationBase,
        hideLogo,
        codeType,
        title,
        subTitle,
        errorTitle,
        errorDescription,
        successTitle,
        successDescription,
        formFields,
        formLinks,
        cancelButton,
        submitButton,
        formName,
        passwordFormat,
        formCaption,
        ...rest
    } = props;
    const {t, ready} = useTranslation(translationBase);

    return (
        <div className={classNames(classes.root, {'post-login': hideLogo})}>
            {!hideLogo && (
                <div className={classes.logo}>
                    <Logo type={'dark'}/>
                </div>
            )}
            <div className={classes.titleBlock}>
                <Typography className={classes.title} variant="h5" gutterBottom>
                    {result
                        ? t(successTitle, {codeType})
                        : t(title, {codeType})}
                </Typography>
                <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    gutterBottom
                >
                    {result
                        ? t(successDescription, {codeType})
                        : t(subTitle, {codeType})}
                </Typography>
            </div>
            <>
                {Boolean(error) && (
                    <div>
                        <Alert
                            className={classes.errorMessage}
                            icon={false}
                            severity="error"
                        >
                            {errorTitle ? (
                                <p>{t(errorTitle)}</p>
                            ) : (
                                <p>{error}</p>
                            )}
                            {errorTitle && (
                                <small>{error || t(errorDescription)}</small>
                            )}
                        </Alert>
                    </div>
                )}
                {result ? (
                    formLinks && (formLinks.successLinkId || formLinks.successLinkAction) && (
                        <Button
                            component={formLinks.successLinkAction ? Button : RouteLink}
                            color="primary"
                            variant="contained"
                            className={classes.loginButton}
                            onClick={e => typeof formLinks.successLinkAction === 'function' ? formLinks.successLinkAction(e) : null}
                            to={RouteUtil.getRoutePath(formLinks.successLinkId)}
                        >
                            {t(formLinks.successMainLabel)}
                        </Button>
                    )
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {formFields.map((formField) => {
                                if (formField.hidden) {
                                    return null;
                                }

                                return (
                                    <Grid
                                        item xs={12}
                                        className={classes.inputFields}
                                        key={formField.name}
                                    >
                                        <Field
                                            className={classes.input}
                                            variant={'outlined'}
                                            {...formField}
                                            fullWidth={formField.fullWidth || true}
                                            label={t(formField.label)}
                                        />
                                    </Grid>
                                );
                            })}
                            {passwordFormat && (
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.passwordformat}
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        {t('password-format')}
                                        <br/>
                                        {/(?=^.{10,}$)/.test(get(props, 'entry', '')) && (
                                            <CheckCircleIcon
                                                className={classes.checkMark}
                                            ></CheckCircleIcon>
                                        )} {t('password-format-character')}
                                        <br/>
                                        {/(?=.*[a-z])(?=.*[A-Z])/.test(get(props, 'entry', '')) && (
                                            <CheckCircleIcon
                                                className={classes.checkMark}
                                            ></CheckCircleIcon>
                                        )} {t('password-format-case')}
                                        <br/>
                                        {/(?=.*\d)/.test(get(props, 'entry', '')) && (
                                            <CheckCircleIcon
                                                className={classes.checkMark}
                                            ></CheckCircleIcon>
                                        )} {t('password-format-number')}
                                        <br/>
                                        {/(?=.*\W)/.test(get(props, 'entry', '')) && (
                                            <CheckCircleIcon
                                                className={classes.checkMark}
                                            ></CheckCircleIcon>
                                        )} {t('password-format-special-character')}
                                    </Typography>
                                </Grid>
                            )}
                            {(formLinks?.linkId || formLinks?.linkAction) && (
                                <Grid item
                                    component={formLinks.linkComponent || RouteLink}
                                    xs={12}
                                    align={'center'}
                                    className={classes.forgotPassword}
                                    onClick={e => {
                                        formLinks.linkAction ? formLinks.linkAction(e) : null;
                                    }}
                                    to={RouteUtil.getRoutePath(formLinks.linkId)}
                                >
                                    <Typography
                                        className={classes.forgotPasswordText}
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        {t(formLinks.mainLabel)}
                                    </Typography>
                                </Grid>
                            )}
                            {formCaption && (
                                <Grid item>{formCaption}</Grid>
                            )}
                            {cancelButton?.mainLabel && (
                                <Grid item xs={submitButton.mainLabel ? 6 : 12}>
                                    <Button
                                        variant={'outlined'}
                                        size={'large'}
                                        fullWidth
                                        onClick={cancelButton.clickAction}
                                        className={classNames(classes.cancelBtn)}
                                    >
                                        {t(cancelButton.mainLabel)}
                                    </Button>
                                </Grid>
                            )}
                            {submitButton?.mainLabel && (
                                <Grid item xs={cancelButton.mainLabel ? 6 : 12}>
                                    <LoadingButton
                                        type={'submit'}
                                        disabled={submitting || pristine || invalid}
                                        color={'primary'}
                                        loading={submitting}
                                        variant={'contained'}
                                        size={'large'}
                                        fullWidth
                                        className={classNames(classes.loginButton)}
                                        loadingText={t(submitButton.loadingText)}
                                    >
                                        {t(submitButton.mainLabel)}
                                    </LoadingButton>
                                </Grid>
                            )}
                        </Grid>
                    </form>
                )}
            </>
        </div>
    );
};

CommonFormBuilder.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    result: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    error: PropTypes.string,
    translationBase: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hideLogo: PropTypes.bool,
    codeType: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    errorTitle: PropTypes.string,
    errorDescription: PropTypes.string,
    successTitle: PropTypes.string,
    successDescription: PropTypes.string,
    formFields: PropTypes.array,
    formLinks: PropTypes.object,
    submitButton: PropTypes.object,
    cancelButton: PropTypes.object,
    formName: PropTypes.string,
    passwordFormat: PropTypes.bool,
    formCaption: PropTypes.any
};

CommonFormBuilder.defaultProps = {
    hideLogo: false,
    cancelButton: {
        clickAction: () => {
        },
        mainLabel: false
    }
};

const styles = (theme) => {
    return {
        root: {
            width: `calc(100% - ${theme.spacing(2)}px)`,
            maxWidth: '375px',
            textAlign: 'center',
            color: theme.palette.primary[theme.palette.type],
            justifyContent: 'center',
            padding: theme.spacing('auto', 2),
            margin: 'auto',
            '&.post-login': {
                textAlign: 'left'
            }
        },
        logo: {
            textAlign: 'center',
            margin: theme.spacing(2, 'auto'),
            width: '180px',
            height: '60px'
        },
        titleBlock: {
            margin: theme.spacing(2, 0)
        },
        title: {
            fontWeight: '500',
            fontFamily: theme.variables.fontSecondary
        },
        subTitle: {
            fontWeight: '500',
            fontSize: '1rem'
        },
        errorMessage: {
            color: theme.palette.error[theme.palette.type],
            textAlign: 'center',
            fontSize: '1rem',
            margin: theme.spacing(2, 'auto'),
            '& p': {
                margin: theme.spacing(0.5, 'auto')
            },
            '& small': {
                fontSize: '.8rem',
                color: theme.palette.lightError[theme.palette.type],
                margin: theme.spacing(0.5, 'auto')
            },
            '& .MuiAlert-message': {
                margin: 'auto'
            },
            '&.MuiAlert-standardError': {
                backgroundColor:
                    theme.palette.backgroundError[theme.palette.type]
            }
        },
        forgotPassword: {
            margin: theme.spacing(0),
            color: theme.palette.primary.main
        },
        forgotPasswordText: {
            textDecoration: 'underline',
            fontSize: '1rem',
            cursor: 'pointer'
        },
        loginButton: {
            width: '100%',
            textTransform: 'none',
            height: '60px',
            marginTop: theme.spacing(4),
            borderRadius: theme.shape.borderRadius * 2.5
        },
        cancelBtn: {
            color: theme.palette.primary.main,
            height: '60px',
            marginTop: theme.spacing(4),
            marginLeft: theme.spacing(0),
            textTransform: 'none',
            borderRadius: theme.shape.borderRadius * 2.5
        },
        checkMark: {
            color: 'green',
            width: '21px',
            height: '26px',
            paddingTop: theme.spacing(1.3),
            verticalAlign: 'sub'
        },
        passwordformat: {
            color: theme.palette.gray.contrastText,
            fontSize: '0.875rem'
        }
    };
};

export default withStyles(styles, {withTheme: true})(CommonFormBuilder);
