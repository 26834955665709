import React from 'react';
import Logo from 'media/logo-notext.svg';
import {Backdrop, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import LogoSpinner from 'views/components/LogoSpinner';

const Preloader = ({classes}) => {
    return (
        <div className={classes.root}>
            <div className={classes.preloader}>
                <Backdrop open={true}/>
                <div className={classes.loader}>
                    <LogoSpinner/>
                </div>
            </div>
        </div>
    );
};

Preloader.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};
const styles = theme => ({
    root: {},
    preloader: {
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'table',
        height: '100%',
        width: '100%',
        zIndex: 99999
    },
    loader: {
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center'
    }

});

export default withStyles(styles, {withTheme: true})(Preloader);
