/**
 * Questionnaires Reducer definitions
 */

export const CLEAR_QUESTIONNAIRES = 'questionnaires/clear';
export const GET_QUESTIONNAIRES_REQUEST = 'questionnaires/request';
export const GET_QUESTIONNAIRES_ERROR = 'questionnaires/error';
export const GET_QUESTIONNAIRES_SUCCESS = 'questionnaires/success';
export const CLEAR_QUESTIONNAIRE = 'questionnaire/clear';
export const GET_QUESTIONNAIRE_REQUEST = 'questionnaire/get/request';
export const GET_QUESTIONNAIRE_ERROR = 'questionnaire/get/error';
export const GET_QUESTIONNAIRE_SUCCESS = 'questionnaire/get/success';
export const UPDATE_QUESTIONNAIRE_REQUEST = 'questionnaire/update/request';
export const UPDATE_QUESTIONNAIRE_ERROR = 'questionnaire/update/error';
export const UPDATE_QUESTIONNAIRE_SUCCESS = 'questionnaire/update/success';
export const CREATE_QUESTIONNAIRE_REQUEST = 'questionnaire/create/request';
export const CREATE_QUESTIONNAIRE_ERROR = 'questionnaire/create/error';
export const CREATE_QUESTIONNAIRE_SUCCESS = 'questionnaire/create/success';
export const DELETE_QUESTIONNAIRE_REQUEST = 'questionnaire/delete/request';
export const DELETE_QUESTIONNAIRE_ERROR = 'questionnaire/delete/error';
export const DELETE_QUESTIONNAIRE_SUCCESS = 'questionnaire/delete/success';
export const UPDATE_QUESTIONNAIRE_ANSWER_REQUEST = 'questionnaire/answer/update/request';
export const UPDATE_QUESTIONNAIRE_ANSWER_ERROR = 'questionnaire/answer/update/error';
export const UPDATE_QUESTIONNAIRE_ANSWER_SUCCESS = 'questionnaire/answer/update/success';
export const GET_ANSWER_REQUEST = 'questionnaire/answer/get/request';
export const GET_ANSWER_ERROR = 'questionnaire/answer/get/error';
export const GET_ANSWER_SUCCESS = 'questionnaire/answer/get/success';
export const CREATE_QUESTIONNAIRE_ANSWER_REQUEST = 'questionnaire/answer/create/request';
export const CREATE_QUESTIONNAIRE_ANSWER_ERROR = 'questionnaire/answer/create/error';
export const CREATE_QUESTIONNAIRE_ANSWER_SUCCESS = 'questionnaire/answer/create/success';
export const GET_PATIENT_QUESTIONNAIRES_REQUEST = 'patientquestionnaires/get/request';
export const GET_PATIENT_QUESTIONNAIRES_ERROR = 'patientquestionnaires/get/error';
export const GET_PATIENT_QUESTIONNAIRES_SUCCESS = 'patientquestionnaires/get/success';
export const GET_ORG_QUESTIONNAIRES_REQUEST = 'orgQuestionnaires/get/request';
export const GET_ORG_QUESTIONNAIRES_SUCCESS = 'orgQuestionnaires/get/success';
export const GET_ORG_QUESTIONNAIRES_ERROR = 'orgQuestionnaires/get/error';
export const UPDATE_ORG_QUESTIONNAIRES_REQUEST = 'orgQuestionnaires/update/request';
export const UPDATE_ORG_QUESTIONNAIRES_ERROR = 'orgQuestionnaires/update/error';
export const UPDATE_ORG_QUESTIONNAIRES_SUCCESS = 'orgQuestionnaires/update/success';
