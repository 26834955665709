/**
 * User Reducer definitions
 */

export const CLEAR_USERS = 'users/clear';
export const GET_USERS_REQUEST = 'users/request';
export const GET_USERS_ERROR = 'users/error';
export const GET_USERS_SUCCESS = 'users/success';

export const CLEAR_USER = 'user/clear';
export const GET_USER_REQUEST = 'user/get/request';
export const GET_USER_ERROR = 'user/get/error';
export const GET_USER_SUCCESS = 'user/get/success';

export const UPDATE_USER_REQUEST = 'user/update/request';
export const UPDATE_USER_ERROR = 'user/update/error';
export const UPDATE_USER_SUCCESS = 'user/update/success';

export const CREATE_USER_REQUEST = 'user/create/request';
export const CREATE_USER_ERROR = 'user/create/error';
export const CREATE_USER_SUCCESS = 'user/create/success';

export const DELETE_USER_REQUEST = 'user/delete/request';
export const DELETE_USER_ERROR = 'user/delete/error';
export const DELETE_USER_SUCCESS = 'user/delete/success';

export const ADD_USER_NOTIFICATION = 'user/notifications/add';
export const DISMISS_USER_NOTIFICATION = 'user/notifications/dismiss';

export const SET_USER_RECERT_REQUEST = 'user/recertification/request';
export const SET_USER_RECERT_ERROR = 'user/recertification/error';
export const SET_USER_RECERT_SUCCESS = 'user/recertification/success';

export const SET_USER_ORGANIZATION = 'user/set/organization';
