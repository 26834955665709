import {parse} from 'path-to-regexp';

const idRegex = /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/g;
/**
 * Parses params from location
 * @param {string} pathname
 * @param {string} linkPattern
 * @returns {Object}
 */
export const parseParamsFromLocation = (pathname, linkPattern) => {
    const parsedLink = parse(linkPattern);
    const [, ...splitPathname] = pathname.split('/');

    return splitPathname.reduce((acc, item, index) => {
        if (typeof parsedLink[index] === 'string') {
            return acc;
        }

        if (parsedLink[index] && item.match(idRegex)) {
            acc[parsedLink[index].name] = item;
        }

        return acc;
    }, {});
};
