/* eslint-disable */
import React from 'react';
import ErrorBoundary from 'react-error-boundaries';
import ErrorLogger from 'views/components/ErrorHandler/ErrorLogger';
import ErrorMessage from 'views/components/ErrorHandler/ErrorMessage';
import JiraIssueCollector from 'views/components/ErrorHandler/JiraIssueCollector';

const ErrorBoundaryHandler = (props) => {
    // console.log(process.env.REACT_APP_ENV);
    return (
        <ErrorBoundary
            onError={ErrorLogger}
            FallbackComponent={ErrorMessage}
        >
            {props.children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryHandler;
