import {Box, Popover, TextField, Tooltip, withStyles} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Check, Close, CommentAlertOutline} from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {change, registerField, unregisterField} from 'redux-form';

const FieldChangeReason = (props) => {

    const {theme, classes, meta, input, injectField, removeField, updateFormData, autoShowTooltip, onOpen} = props;
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(false);
    const [value, setValue] = useState();
    const fieldName = useMemo(() => input ? `${input.name}_reason` : 'reason', []);
    const [showTooltip, setShowTooltip] = useState(autoShowTooltip);

    useEffect(() => {
        injectField(meta?.form, fieldName);
        updateFormData(meta?.form, fieldName, null);
        const t = setTimeout(() => {
            setShowTooltip(false);
        }, 3000);
        return function () {
            clearTimeout(t);
            updateFormData(meta?.form, fieldName, undefined);
            removeField(meta?.form, fieldName);
        };
    }, [setAnchorEl]);


    const handleClose = (e, reason) => {
        if (reason !== 'backdropClick') {
            onOpen(false);
            setAnchorEl(null);
        }
    };

    const handleClickClose = e => {
        e.stopPropagation();
        handleClose(e, 'discardChanged');
        updateFormData(meta?.form, fieldName, null);
        setValue(undefined);
    };

    const handleOpen = e => {
        e.stopPropagation();
        setShowTooltip(false);
        onOpen(true);
        setAnchorEl(e.target);
    };

    const handleChange = e => {
        e.stopPropagation();
        setValue(e.target.value);
    };

    const handleSubmit = e => {
        e.stopPropagation();
        updateFormData(meta?.form, fieldName, value);
        handleClose(e, 'submitChange');
    };

    const handleFocus = e => {
        e.stopPropagation();
    };
    return (
        <>
            <Tooltip
                arrow
                title={t(`patients:${value ? 'change-reason-edit-tooltip' : 'change-need-icon-tooltip'}`)}
                open={showTooltip}
                classes={{
                    popper: classes.popper,
                    tooltip: !value && classes.tooltip,
                    arrow: !value && classes.tooltip_arrow
                }}
            >
                <IconButton
                    onClick={handleOpen}
                    onMouseEnter={() => {
                        setShowTooltip(true);
                    }}
                    onMouseLeave={() => {
                        setShowTooltip(false);
                    }}
                    size={'small'}
                >
                    <CommentAlertOutline
                        style={{
                            color: !value ? theme.palette.danger.main : theme.palette.grey[300]
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
                PaperProps={{
                    style: {padding: theme.spacing(2), overflow: 'hidden'}
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between'
                    }}
                >
                    <TextField
                        size={'small'}
                        variant={'outlined'}
                        label={t('patients:change-reason-label')}
                        multiline={true}
                        name={fieldName}
                        value={value}
                        maxRows={5}
                        onChange={handleChange}
                        onClick={handleFocus}
                    />
                    <IconButton size={'small'} onClick={handleSubmit}><Check/></IconButton>
                    <IconButton size={'small'} onClick={handleClickClose}><Close/></IconButton>
                </Box>
            </Popover>
        </>
    );
};

FieldChangeReason.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    meta: PropTypes.object,
    input: PropTypes.object,
    injectField: PropTypes.func,
    removeField: PropTypes.func,
    updateFormData: PropTypes.func,
    markInvalid: PropTypes.func,
    onOpen: PropTypes.func,
    needs_reason: PropTypes.bool,
    formErrors: PropTypes.any,
    formValues: PropTypes.any,
    autoShowTooltip: PropTypes.bool
};

FieldChangeReason.defaultProps = {
    autoShowTooltip: true,
    onOpen: () => {}
};
const styles = theme => ({
    root: {},
    tooltip_arrow: {
        color: theme.palette.danger.main
    },
    tooltip: {
        background: theme.palette.danger.main,
        color: theme.palette.common.white
    },
    popper: {
        zIndex: theme.zIndex.modal + 1
    },
    tooltip_arrow_clean: {
        color: theme.palette.common.white
    },
    tooltip_clean: {
        color: theme.palette.text.primary,
        background: theme.palette.common.white
    }
});

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => ({
    injectField: (form, name) => dispatch(registerField(form, name, 'Field')),
    removeField: (form, name) => dispatch(unregisterField(form, name, true)),
    updateFormData: (form, field, value) => dispatch(change(form, field, value, false, false))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(FieldChangeReason));
