import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Close} from 'mdi-material-ui';
import {Icon, IconButton, SnackbarContent, Typography, withStyles} from '@material-ui/core';

const variantIcon = {
    success: 'check_circle',
    warning: 'warning',
    error: 'error',
    info: 'info'
};

class Notification extends Component {
    constructor(props) {
        super(props);
        this.closeTimer = null;
    }

    componentDidMount() {
        const _self = this;
        this.closeTimer = setTimeout(() => {
            _self.props.onClose();
        }, 5000);
    }

    componentWillUnmount() {
        clearTimeout(this.closeTimer);
    }

    render() {

        const {className, text, onClose, variant, classes, theme, header, hasIcon, ...other} = this.props;
        const icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={classNames(classes[variant], className, 'text')}
                aria-describedby="client-snackbar"
                message={
                    <div id="client-snackbar" className={classes.message}>
                        {hasIcon && (
                            <Icon className={classNames(classes.icon, classes.iconVariant)}>{icon}</Icon>
                        )}
                        <div className={classes.messageContent}>
                            {header && (
                                <Typography style={{fontWeight: 'bold'}}>{header}</Typography>
                            )}
                            <span>{text}</span>
                        </div>
                    </div>
                }
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                        <Close/>
                    </IconButton>
                ]}
                {...other}
            />
        );
    }
}

Notification.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    theme: PropTypes.object,
    text: PropTypes.string,
    onClose: PropTypes.func,
    hasIcon: PropTypes.bool,
    header: PropTypes.string,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'default']).isRequired
};


const styles = theme => ({
    success: {
        backgroundColor: theme.palette.states.success.dark
    },
    error: {
        backgroundColor: theme.palette.states.error.dark
    },
    info: {
        backgroundColor: theme.palette.states.info.dark
    },
    warning: {
        backgroundColor: theme.palette.states.info.dark
    },
    icon: {
        fontSize: 32
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    messageContent: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    }
});

export default withStyles(styles, {withTheme: true})(Notification);
