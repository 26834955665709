import React, {forwardRef} from 'react';
import * as PropTypes from 'prop-types';
import {Scrollbars} from 'react-custom-scrollbars-patched';
import {withStyles} from '@material-ui/core';
import {AutoSizer} from 'react-virtualized';

const Scroller = forwardRef((props, ref) => {
    const {tag: tagName, children, classes, disableWidth, AutoSizerProps, ...rest} = props;

    return (
        <AutoSizer nonce={process.env.NONCE} disableWidth={disableWidth} className={classes.root} style={{
            height: 250
        }} {...AutoSizerProps}>
            {({width, height}) => {
                return (
                    <Scrollbars
                        ref={ref}
                        tagName={tagName}
                        {...rest}
                        style={{width, height}}
                    >
                        {children}
                    </Scrollbars>
                );
            }}
        </AutoSizer>
    );
});
Scroller.displayName = 'Scroller';

Scroller.propTypes = {
    tag: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.object
    ]),
    disableWidth: PropTypes.bool,
    classes: PropTypes.object,
    autoHide: PropTypes.bool,
    alwaysRenderScrollbar: PropTypes.bool,
    AutoSizerProps: PropTypes.any
};

Scroller.defaultProps = {
    tag: 'div',
    autoHide: true,
    disableWidth: true,
    alwaysRenderScrollbar: true
};

const styles = {
    root: {}
};

export default withStyles(styles)(Scroller);
