import {alpha, Box, ClickAwayListener, Fab, IconButton, Popper, Tooltip, withStyles, Zoom} from '@material-ui/core';
import {Translate, Tune} from '@material-ui/icons';
import classNames from 'classnames';
import SessionStorage from 'common/SessionStorage';
import i18next from 'core/i18next.js';
import * as PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import LangSelectors from 'store/lang/LangSelectors';

const PreferencesMenu = ({theme, classes, onClickToggleTheme, languages, ...props}) => {
    const [anchorEl, setAnchorEl] = useState();
    const [anchorLangEl, setLangAnchorEl] = useState();
    const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);

    const {t} = useTranslation('components');
    const handleChangeLanguage = (e) => {
        const ds = e.currentTarget.dataset;
        const lang = ds['lang'];
        SessionStorage.set('i18nextLng', lang);
        i18next.changeLanguage(lang).then(() => {
            setSelectedLanguage(lang);
            handleClose();
        });
    };

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
        setLangAnchorEl(null);
    };

    const handleClose = () => {
        if (anchorEl) {
            setAnchorEl(null);
            setLangAnchorEl(null);
        }
    };

    const handleLangOpen = (e) => setLangAnchorEl(e.currentTarget);
    const handleLangClose = () => setLangAnchorEl(null);

    return (
        <div className={classes.root}>
            <ClickAwayListener onClickAway={handleClose}>
                <Box style={{position: 'relative'}}>
                    <Fab color={'primary'}
                        size={'small'}
                        onMouseEnter={handleOpen}
                    >
                        <Tune fontSize={'small'} style={{color: theme.palette.common.white}}/>
                    </Fab>
                    <Popper
                        placement={'top'}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        transition
                        style={{
                            zIndex: 999999
                        }}
                    >
                        {({TransitionProps}) => (
                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                zIndex: 999999,
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Zoom direction={'up'} {...TransitionProps} timeout={350}>
                                    <Box style={{position: 'relative'}}>
                                        <Fab
                                            title={t('change-language')}
                                            size={'small'}
                                            style={{
                                                zIndex: 999999, marginBottom: theme.spacing(2)
                                            }}
                                            onClick={handleLangOpen}
                                        >
                                            <Translate fontSize={'small'}/>
                                        </Fab>
                                        <Popper
                                            placement={'left'}
                                            open={Boolean(anchorLangEl)}
                                            anchorEl={anchorLangEl}
                                            transition
                                            style={{
                                                zIndex: 999999
                                            }}
                                        >
                                            {({TransitionProps}) => (
                                                <Box style={{
                                                    position: 'relative',
                                                    marginTop: theme.spacing(.5),
                                                    marginRight: theme.spacing()
                                                }}>
                                                    {languages && languages.length > 0 && languages.map(language => (
                                                        <Zoom {...TransitionProps} timeout={350} key={language.shortname}>
                                                            <Tooltip title={language.name}>
                                                                <IconButton
                                                                    data-lang={language.shortname.toLowerCase()}
                                                                    onClick={handleChangeLanguage}
                                                                    className={classNames({
                                                                        [classes.flagContainer]: true,
                                                                        [classes.selectedLang]: selectedLanguage === language.shortname.toLowerCase()
                                                                    }, 'fflag ff-xl ff-round')}
                                                                >
                                                                    <div
                                                                        className={classNames(
                                                                            'fflag ff-xl ff-round',
                                                                            {
                                                                                [`fflag-${language?.shortname.toUpperCase()}`]: true,
                                                                                [classes.flag]: true
                                                                            }
                                                                        )}>&nbsp;</div>
                                                                    <div className={'overlay'}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Zoom>
                                                    ))}
                                                </Box>
                                            )}
                                        </Popper>
                                    </Box>
                                </Zoom>
                            </Box>
                        )}
                    </Popper>
                </Box>
            </ClickAwayListener>
        </div>
    );
};

PreferencesMenu.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    onClickToggleTheme: PropTypes.func,
    languages: PropTypes.array
};
const styles = theme => ({
    root: {
        position: 'absolute',
        bottom: theme.spacing(0),
        right: '1%',
        zIndex: 999999,
        padding: theme.spacing()
    },
    flagContainer: {
        zIndex: 999999,
        backgroundImage: 'none !important',
        marginRight: theme.spacing(0.5),
        padding: 0,
        border: `${theme.spacing(.5)}px solid ${theme.palette.common.white}`,
        boxShadow: `0px 1px 2px 1px ${alpha(theme.palette.gray.main, .3)}`,
        backgroundColor: `${theme.palette.common.white} !important`,
        '&  .fflag': {
            opacity: .5,
            '&:hover': {
                opacity: 1
            }
        }
    },
    flag: {},
    selectedLang: {
        backgroundColor: theme.palette.secondary.main,
        '& .fflag': {
            opacity: 1
        }
    }
});
const mapStateToProps = (state, ownProps) => ({
    languages: LangSelectors.getLangs(state)
});
export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(PreferencesMenu));
