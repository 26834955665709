import keyBy from 'lodash/keyBy';

import {LOGOUT} from 'store/auth/AuthActionTypes';
import {
    ACTIVITIES_LIST_SUCCESS,
    ACTIVITY_LOADING,
    ACTIVITY_ERROR,
    ACTIVITY_SUCCESS,
    ACTIVITY_DELETE_SUCCESS
} from 'store/visit/ActivitiesActionTypes';

const emptyObject = {};
const initialState = {
    data: emptyObject,
    status: 'idle',
    error: null
};

export default function activities(state = initialState, action) {
    switch (action.type) {
        case ACTIVITIES_LIST_SUCCESS: {
            return {
                status: action.status,
                data: {
                    ...state.data,
                    [action.id]: keyBy(action.data, 'id')
                },
                error: null
            };
        }
        case ACTIVITY_LOADING: {
            return {
                ...state,
                status: 'loading',
                error: null
            };
        }
        case ACTIVITY_SUCCESS: {
            return {
                ...state,
                status: 'idle',
                data: {
                    ...state.data,
                    [action.visitId]: {
                        ...state.data[action.visitId],
                        [action.id]: action.data
                    }
                },
                error: null
            };
        }
        case ACTIVITY_DELETE_SUCCESS: {
            const {[action.visitId]: {[action.id]: removed, ...restData}} = state.data;
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.visitId]: restData
                },
                status: 'idle',
                error: null
            };
        }
        case ACTIVITY_ERROR: {
            return {
                ...state,
                status: 'error',
                error: action.error
            };
        }

        case LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

