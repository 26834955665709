import get from 'lodash/get';

export const objArray2NameValue = data => {
    return Object.entries(data).map(([v, n]) => ({name: n, value: v}));
};

export const array2NameValue = data => {    
    return data?.map((value) => ({name: value, value}));
};

export const normalize = (data, valueProp = 'value', nameProp) => {
    return data.reduce((acc, option) => {
        acc[get(option, valueProp)] = get(option, nameProp) || option;
        return acc;
    }, {});
};
