/**
 * permission Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';

import {
    CLEAR_PERMISSIONS,
    GET_PERMISSIONS_REQUEST,
    GET_PERMISSIONS_ERROR,
    GET_PERMISSIONS_SUCCESS,
    CLEAR_PERMISSION,
    GET_PERMISSION_REQUEST,
    GET_PERMISSION_ERROR,
    GET_PERMISSION_SUCCESS,
    UPDATE_PERMISSION_REQUEST,
    UPDATE_PERMISSION_ERROR,
    UPDATE_PERMISSION_SUCCESS,
    CREATE_PERMISSION_REQUEST,
    CREATE_PERMISSION_ERROR,
    CREATE_PERMISSION_SUCCESS,
    DELETE_PERMISSION_REQUEST,
    DELETE_PERMISSION_ERROR,
    DELETE_PERMISSION_SUCCESS
} from 'store/permission/PermissionActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function permissions(state = initialState, action) {

    let newState = {};

    switch (action.type) {
        case CLEAR_PERMISSIONS: {
            newState = {
                ...initialState
            };
            break;
        }
        case GET_PERMISSIONS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_PERMISSIONS_SUCCESS: {
            newState = {
                ...initialState,
                status: action.status,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_PERMISSIONS_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_PERMISSION: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_PERMISSION_REQUEST:
        case UPDATE_PERMISSION_REQUEST:
        case CREATE_PERMISSION_REQUEST:
        case DELETE_PERMISSION_REQUEST: {
            const newData = {...state.data};
            //Object.assign(newData, {[action.id]: {status: action.status}});
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_PERMISSION_SUCCESS:
        case UPDATE_PERMISSION_SUCCESS:
        case CREATE_PERMISSION_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_PERMISSION_ERROR:
        case UPDATE_PERMISSION_ERROR:
        case CREATE_PERMISSION_ERROR:
        case DELETE_PERMISSION_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_PERMISSION_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
