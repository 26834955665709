/**
 * Allergy Reducer definitions
 */

export const CLEAR_ALLERGIES = 'allergies/clear';
export const GET_ALLERGIES_REQUEST = 'allergies/request';
export const GET_ALLERGIES_ERROR = 'allergies/error';
export const GET_ALLERGIES_SUCCESS = 'allergies/success';
export const CLEAR_ALLERGY = 'allergy/clear';
export const GET_ALLERGY_REQUEST = 'allergy/get/request';
export const GET_ALLERGY_ERROR = 'allergy/get/error';
export const GET_ALLERGY_SUCCESS = 'allergy/get/success';
export const UPDATE_ALLERGY_REQUEST = 'allergy/update/request';
export const UPDATE_ALLERGY_ERROR = 'allergy/update/error';
export const UPDATE_ALLERGY_SUCCESS = 'allergy/update/success';
export const CREATE_ALLERGY_REQUEST = 'allergy/create/request';
export const CREATE_ALLERGY_ERROR = 'allergy/create/error';
export const CREATE_ALLERGY_SUCCESS = 'allergy/create/success';
export const DELETE_ALLERGY_REQUEST = 'allergy/delete/request';
export const DELETE_ALLERGY_ERROR = 'allergy/delete/error';
export const DELETE_ALLERGY_SUCCESS = 'allergy/delete/success';

