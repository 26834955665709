import i18next from 'core/i18next';
import moment from 'moment';
import {DATE_FORMAT} from 'common/constants/common';

/**
 * Checks if a date is later than today + addOffset - subtractOffset
 * @param addOffset {{days: number, months: number, years: number}}
 * @param subtractOffset {{days: number, months: number, years: number}}
 * @returns {function(string|moment): undefined}
 */
export const dateLater = (addOffset = {}, subtractOffset = {}) => (date) => {
    if (date instanceof moment) {
        let offsetDate = Object.entries(addOffset).reduce((acc, [type, quantity]) => {
            return acc.add(type, quantity);
        }, moment());
        offsetDate = Object.entries(subtractOffset).reduce((acc, [type, quantity]) => {
            return acc.subtract(type, quantity);
        }, offsetDate);
        if (date.isBefore(offsetDate)) {
            return i18next.t('errors:date-later', {date: offsetDate.format(DATE_FORMAT)});
        }
    }
    return undefined;
};
