import _ from 'lodash';
import {createSelector} from 'reselect';

const emptyArray = [];
const emptyObject = {};

const getLangState = state => _.get(state, 'langs', emptyObject);

const getId = (state, id) => id || null;

export const getRootStateLang = createSelector(
    getLangState,
    lang => lang || null
);

const LangSelectorFactory = getState => ({
    getState,
    getLangs: createSelector(
        [getState],
        (data, config) => _.chain(data).get('data', emptyObject).values().value()
    ),
    getNames: createSelector(
        [getState],
        (data) => _.chain(data).get('data', emptyObject).values().map(lng => lng.shortname).value()
    ),
    getLang: createSelector(
        [getState, getId],
        (lang, id, config) => _.chain(lang).get(`data.${id}`).value()
    ),
    getStatus: createSelector(
        getState,
        state => _.chain(state).get('status', '').value()
    ),
    getLoading: (state) => _.chain(state).get('status', '').value() === 'loading',
    getError: createSelector(
        getState,
        state => _.chain(state).get('error', null).value()
    )
});

export default LangSelectorFactory(getRootStateLang);

