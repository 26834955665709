import {Link, Box, Button, Dialog, DialogContent, DialogTitle, Typography, useTheme} from '@material-ui/core';
import {ErrorOutline} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import InputField from 'views/components/fields/InputField';


export const MFAField = (props) => {
    const {methods, handleSendMFACode, ...rest} = props;
    const {t} = useTranslation(['common', 'mfa']);
    const onClickSelectMethod = async (method) => {
        if (typeof handleSendMFACode === 'function') {
            return handleSendMFACode(method);
        }
        return Promise.resolve();
    };
    return (
        <>
            <InputField label={t('mfa:code-from-authenticator')} type={'text'} {...rest}/>
            {methods && <MFAMethodSelector
                onClickMethod={onClickSelectMethod}
                methods={methods}
            />}
        </>
    );
};

MFAField.propTypes = {
    methods: PropTypes.array.isRequired,
    handleSendMFACode: PropTypes.func.isRequired
};

MFAField.defaultProps = {
    methods: ['authenticator', 'sms', 'email']
};


const MFAMethodSelector = (props) => {
    const {onClickMethod, methods} = props;

    const theme = useTheme();
    const {t} = useTranslation(['common', 'mfa']);
    const [showMethodModal, setShowModal] = useState(true);
    const [method, setMethod] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        setShowModal(true);
    }, []);

    useEffect(() => {
        if (!showMethodModal) {
            setError(null);
            setMethod(null);
        }
    }, [showMethodModal]);

    useEffect(() => {
        if (method) {
            onClickMethod(method)
                .then(result => {
                    setShowModal(false);
                })
                .catch(e => {
                    setError(e.error);
                })
                .finally(() => {
                    setMethod(null);
                });
        }
    }, [method]);
    return (
        <>
            <Dialog open={showMethodModal} maxWidth={'sm'} fullWidth>
                <DialogTitle>{t('mfa:change-method-title')}</DialogTitle>
                <DialogContent>
                    <Typography align={'center'} variant={'body2'}>{t('mfa:select-mfa-method')}.</Typography>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        {methods?.map((mtd, index) => (
                            <div
                                key={`mfa-method-${index}`}
                                style={{
                                    flex: 1,
                                    padding: theme.spacing(5, 0, 5, 0),
                                    paddingLeft: index === 0 ? 0 : 2,
                                    paddingRight: index === methods.length - 1 ? 0 : 2
                                }}
                            >
                                <Button
                                    style={{minHeight: theme.spacing(8)}}
                                    variant={'contained'}
                                    color={'primary'}
                                    fullWidth
                                    size={'large'}
                                    onClick={e => setMethod(mtd)}
                                >{t(`mfa:${mtd}`)}</Button>
                            </div>
                        ))}
                    </div>
                    {error && (
                        <Alert icon={false} variant={'standard'} color={'warning'}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>
                                <ErrorOutline color={'error'} style={{
                                    color: theme.palette.danger.main,
                                    marginRight: theme.spacing(2)
                                }}/>
                                <div>{t(`mfa:${error}`)}</div>
                            </Box>
                        </Alert>
                    )}
                </DialogContent>
            </Dialog>
            <div
                style={{textAlign: 'center', marginTop: theme.spacing(2)}}
            >
                <Link
                    component={'a'}
                    href={'#'}
                    color={'primary'}
                    onClick={e => setShowModal(true)}
                >{t('mfa:change-method-link')}</Link>
            </div>
        </>
    );
};

MFAMethodSelector.propTypes = {
    onClickMethod: PropTypes.func.isRequired,
    methods: PropTypes.array.isRequired
};
