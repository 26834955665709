import RouteUtil from 'common/RouteUtil';
import PropTypes from 'prop-types';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Redirect, Route} from 'react-router-dom';
import AuthSelectors from 'store/auth/AuthSelectors';
import UserSelectors from 'store/user/UserSelectors';
import NotAuthorized from 'views/modules/Error/NotAuthorized';

class PrivateRoute extends Component {

    static propTypes = {
        accessToken: PropTypes.string,
        component: PropTypes.any,
        isTokenExpired: PropTypes.bool,
        status: PropTypes.string,
        user: PropTypes.object,
        render: PropTypes.func,
        challenge: PropTypes.string,
        redirect: PropTypes.string,
        forbiddenScope: PropTypes.string,
        scope: PropTypes.string,
        scopes: PropTypes.array,
        isTokenRefreshing: PropTypes.bool
    };

    render() {
        const {
            component: Component,
            accessToken,
            isTokenExpired,
            status,
            user,
            render,
            redirect,
            challenge,
            isTokenRefreshing,
            forbiddenScope,
            scope,
            scopes,
            ...rest
        } = this.props;

        return (
            <Route
                {...rest}
                render={props => {
                    if (accessToken && accessToken !== '' && (!isTokenExpired || isTokenRefreshing) && status && status === 'authenticated') {
                        if (user || challenge) {
                            if (user && ((typeof scope !== 'undefined' && !scopes.includes(scope)) || scopes.includes(forbiddenScope))) {
                                return (<NotAuthorized/>);
                            }
                            if (redirect) {
                                return (<Redirect
                                    to={redirect}
                                />);
                            }
                            if (Component) {
                                return (<Component {...rest} user={user} {...props} />);
                            }
                            else if (render) {
                                return render({...rest, ...props});
                            }
                        }
                        return null;
                    }
                    else {
                        return (
                            <Redirect
                                to={{
                                    pathname: RouteUtil.getRoutePath('base.login')
                                }}
                            />);
                    }
                }}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accessToken: AuthSelectors.getAccessToken(state),
        challenge: AuthSelectors.getChallenge(state),
        isTokenExpired: AuthSelectors.getIsTokenExpired(state),
        isTokenRefreshing: AuthSelectors.getIsTokenRefreshing(state),
        status: AuthSelectors.getAuthStatus(state),
        user: UserSelectors.getUser(state),
        scopes: AuthSelectors.getScopes(state)
    };
};

export default connect(mapStateToProps)(PrivateRoute);
