import {getNormalizeState, updateNormalizeState} from 'common/helpers/State';

import {LOGOUT} from 'store/auth/AuthActionTypes';
import {
    LAB_RESULTS_LIST_SUCCESS,
    LAB_RESULT_LOADING,
    LAB_RESULT_ERROR,
    LAB_RESULT_SUCCESS,
    LAB_RESULT_DELETE_SUCCESS
} from 'store/lab/LabActionTypes';

const emptyObject = {};
const initialState = {
    data: emptyObject,
    status: 'idle',
    error: null
};

export default function labs(state = initialState, action) {
    switch (action.type) {
        case LAB_RESULTS_LIST_SUCCESS: {
            return {
                ...initialState,
                status: action.status,
                data: {
                    ...state.data,
                    ...action.data.length ? {
                        [action.patientId]: {
                            ...state.data[action.patientId],
                            [action.labType]: getNormalizeState(action.data, 'id')
                        }
                    } : {}
                },
                error: null
            };
        }
        case LAB_RESULT_LOADING: {
            return {
                ...state,
                status: 'loading',
                error: null
            };
        }
        case LAB_RESULT_DELETE_SUCCESS:
        case LAB_RESULT_SUCCESS: {
            const labType = state.data[action.patientId]?.[action.labType];
            return {
                ...state,
                status: 'idle',
                data: {
                    ...state.data,
                    [action.patientId]: {
                        ...state.data[action.patientId],
                        [action.labType]: updateNormalizeState(labType || {}, action.id, action.data)
                    }
                },
                error: null
            };
        }
        case LAB_RESULT_ERROR: {
            return {
                ...state,
                status: 'error',
                error: action.error
            };
        }

        case LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
 
