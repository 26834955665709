import store from 'store';

import {showAlert} from 'store/ui/UIActions';

class UserError {
    constructor(props) {
        const {
            error,
            message: error_message,
            ...rest
        } = props;

        store.dispatch(showAlert({
            type: 'error',
            title: 'common:error',
            text: typeof error_message === 'object' && error_message.length >= 0 ? error_message.join('<br/>') : error_message,
            confirmButtonText: 'common:continue',
            confirmButtonVariant: 'contained',
            onConfirm: () => {

            }
        }));
    }

}

export default UserError;
