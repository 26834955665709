/**
 * lang action definitions
 */
import {
    CLEAR_LANGS,
    GET_LANGS_REQUEST,
    GET_LANGS_ERROR,
    GET_LANGS_SUCCESS,
    CLEAR_LANG,
    GET_LANG_REQUEST,
    GET_LANG_ERROR,
    GET_LANG_SUCCESS,
    UPDATE_LANG_REQUEST,
    UPDATE_LANG_ERROR,
    UPDATE_LANG_SUCCESS,
    CREATE_LANG_REQUEST,
    CREATE_LANG_ERROR,
    CREATE_LANG_SUCCESS,
    DELETE_LANG_REQUEST,
    DELETE_LANG_ERROR,
    DELETE_LANG_SUCCESS
} from 'store/lang/LangActionTypes';

import {errorNotification} from 'store/ui/UIActions';

const clearLangsAction = () => ({type: CLEAR_LANGS});
const clearLangAction = (id) => ({type: CLEAR_LANG, id});

const getLangsRequestAction = () => ({type: GET_LANGS_REQUEST, status: 'loading'});
const getLangsErrorAction = e => ({type: GET_LANGS_ERROR, error: e, status: 'error'});
const getLangsSuccessAction = data => ({type: GET_LANGS_SUCCESS, data, status: 'idle'});

const getLangRequestAction = (id) => ({type: GET_LANG_REQUEST, id, status: 'loading'});
const getLangErrorAction = (e, id) => ({type: GET_LANG_ERROR, error: e, status: 'error', id});
const getLangSuccessAction = (data, id) => ({type: GET_LANG_SUCCESS, data, id, status: 'idle'});

const updateLangRequestAction = id => ({type: UPDATE_LANG_REQUEST, id, status: 'loading'});
const updateLangErrorAction = (e, id) => ({type: UPDATE_LANG_ERROR, error: e, status: 'error', id});
const updateLangSuccessAction = (data, id) => ({type: UPDATE_LANG_SUCCESS, data, id, status: 'idle'});

const createLangRequestAction = () => ({type: CREATE_LANG_REQUEST, status: 'loading'});
const createLangErrorAction = e => ({type: CREATE_LANG_ERROR, error: e, status: 'error'});
const createLangSuccessAction = (data, id) => ({type: CREATE_LANG_SUCCESS, data, id, status: 'idle'});

const deleteLangRequestAction = id => ({type: DELETE_LANG_REQUEST, id, status: 'loading'});
const deleteLangErrorAction = (e, id) => ({type: DELETE_LANG_ERROR, error: e, status: 'error', id});
const deleteLangSuccessAction = id => ({type: DELETE_LANG_SUCCESS, id, status: 'idle'});

export function getLangsRequest() {
    return dispatch => {
        dispatch(getLangsRequestAction());
    };
}

export function getLangsSuccess(data) {
    return dispatch => {
        dispatch(getLangsSuccessAction(data));
    };
}

export function getLangsError(e) {
    return dispatch => {
        dispatch(clearLangsAction());
        dispatch(getLangsErrorAction(e));
        dispatch(errorNotification(e));
    };
}

export function getLangRequest(id) {
    return dispatch => {
        dispatch(clearLangAction(id));
        dispatch(getLangRequestAction(id));
    };
}

export function getLangSuccess(data, id) {
    return dispatch => {
        dispatch(getLangSuccessAction(data, id));
    };
}

export function getLangError(e, id) {
    return dispatch => {
        dispatch(clearLangAction(id));
        dispatch(getLangErrorAction(e, id));
        dispatch(errorNotification(e));
    };
}

export function saveLangRequest(id) {
    return dispatch => {
        if (id) {
            dispatch(updateLangRequestAction(id));
        }
        else {
            dispatch(createLangRequestAction());
        }
    };
}

export function saveLangError(e, id) {
    return dispatch => {
        if (id) {
            dispatch(updateLangErrorAction(e, id));
        }
        else {
            dispatch(createLangErrorAction(e));
        }
        dispatch(errorNotification(e));
    };
}

export function saveLangSuccess(data, id) {
    return dispatch => {
        if (data) {
            if (id) {
                dispatch(updateLangSuccessAction(data, id));
            }
            else {
                dispatch(createLangSuccessAction(data, id));
            }
        }
    };
}


export function deleteLangRequest(id) {
    return dispatch => {
        dispatch(deleteLangRequestAction(id));
    };
}

export function deleteLangSuccess(id) {
    return dispatch => {
        dispatch(deleteLangSuccessAction(id));
    };
}

export function deleteLangError(e, id) {
    return dispatch => {
        dispatch(deleteLangErrorAction(e, id));
        dispatch(errorNotification(e));
    };
}


export default ({
    getLangsRequest,
    getLangsSuccess,
    getLangsError,
    getLangRequest,
    getLangSuccess,
    getLangError,
    saveLangRequest,
    saveLangError,
    saveLangSuccess,
    deleteLangRequest,
    deleteLangSuccess,
    deleteLangError
});
