import React from 'react';
import {matchPath} from 'react-router-dom';
import {compile, parse} from 'path-to-regexp';

const idRegex = /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/g;

/**
 * Parses params from location
 * @param {string} pathname
 * @param {string} linkPattern
 * @returns {Object}
 */
export const parseParamsFromLocation = (pathname, linkPattern) => {
    const parsedLink = parse(linkPattern);
    const [, ...splitPathname] = pathname.split('/');

    const {params} = splitPathname.reduce((acc, item, index) => {
        if (!acc.match) {
            return acc;
        }

        if (typeof parsedLink[index] === 'string') {
            acc.match = parsedLink[index].replace('/', '') === item;
            return acc;
        }

        if (parsedLink[index] && item.match(idRegex)) {
            acc.params[parsedLink[index].name] = item;
        }

        return acc;
    }, {params: {}, match: true});

    return params;
};

/**
 * Replaces parameters in url
 * @param {string} pathname
 * @param {string} linkPattern
 * @returns {string}
 */
const compileLink = (pathname, linkPattern) => {
    const params = parseParamsFromLocation(pathname, linkPattern);

    return compile(linkPattern)(params);
};

/**
 * Checks location pathname to match routes
 * @param routes
 * @param pathname
 * @returns {boolean}
 */
const checkRoutes = (routes, {pathname}) =>
    routes.some((route) => matchPath(pathname, route), []);

const getMenu = (menuConfig, location) => {
    return menuConfig.reduce((acc, {routes, props}) => {
        if (checkRoutes(routes, location)){
            props.link = compileLink(location.pathname, props.link);

            acc.push(props);
        }
        
        return acc;
    }, []);
};


export default getMenu;
