import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    Typography,
    withStyles
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import UISelector from 'store/ui/UISelectors';
import {closeAlert} from 'store/ui/UIActions';
import {
    Alert as AlertTriangle,
    AlertOctagon,
    CheckCircle,
    ExclamationThick,
    Lightbulb
} from 'mdi-material-ui';
import memoizeOne from 'memoize-one';

const Alert = (props) => {
    const {
        classes,
        theme,
        open,
        onOpen,
        onConfirm,
        onCancel,
        onClose,
        html,
        showCancelButton,
        showConfirmButton,
        confirmButtonText,
        confirmButtonColor,
        cancelButtonText,
        buttonVariant,
        cancelButtonVariant,
        confirmButtonVariant,
        title,
        text,
        type,
        size,
        closeAlert,
        actions
    } = props;

    const {t} = useTranslation();

    const getIcon = memoizeOne(
        (type) => {
            switch (type) {
                case 'success':
                    return CheckCircle;
                case 'error':
                    return AlertOctagon;
                case 'info':
                    return Lightbulb;
                case 'warning':
                    return AlertTriangle;
                default:
                    return null;
            }
        },
        [type]
    );
    const AlertIcon = getIcon(type);
    return (
        <Dialog
            classes={{
                root: classes.root,
                paper: classes.paper
            }}
            open={open}
            hideBackdrop={false}
            disableEscapeKeyDown
            // disableBackdropClick
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeAlert();
                    onClose && onClose();
                }
            }}
            keepMounted={false}
            onEntered={onOpen}
            scroll={'paper'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={size}
        >
            {title && (!type || type === 'default') && (
                <DialogTitle className={classes.title} id="alert-dialog-title">
                    {t(title)}
                </DialogTitle>
            )}
            <DialogContent
                id="alert-dialog-description"
                className={classes.messageContainer}
            >
                {type && type !== 'default' && (
                    <div>
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#291987'
                            }}
                        >
                            {title && type !== 'default' && (
                                <Typography
                                    className={classes.title}
                                    id="alert-dialog-title"
                                    variant={'h5'}
                                >
                                    {t(title)}
                                </Typography>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 0
                            }}
                        >
                            <AlertIcon className={classes.messageIcon}/>
                            <Typography variant={'body1'} className={classes.messageDiv}>
                                {html ? (
                                    <div
                                        className={classes.message}
                                        dangerouslySetInnerHTML={{__html: `${t(text)}`}}
                                    />
                                ) : (
                                    <span className={classes.message}>{t(text)}</span>
                                )}
                            </Typography>
                        </div>
                    </div>
                )}
            </DialogContent>

            <DialogActions className={classes.actions}>
                {showCancelButton || showConfirmButton || onCancel || onConfirm ? (
                    <React.Fragment>
                        {(showCancelButton || onCancel) && (
                            <Button
                                onClick={() => {
                                    closeAlert();
                                    onCancel && onCancel();
                                }}
                                color={'primary'}
                                className={classes.cancelButton}
                                variant={cancelButtonVariant || buttonVariant}
                            >
                                {t(cancelButtonText)}
                            </Button>
                        )}
                        {(showConfirmButton || onConfirm) && (
                            <Button
                                onClick={() => {
                                    closeAlert();
                                    onConfirm && onConfirm();
                                }}
                                color={confirmButtonColor || 'primary'}
                                variant={confirmButtonVariant || buttonVariant}
                                className={classes.confirmButton}
                            >
                                {t(confirmButtonText)}
                            </Button>
                        )}
                    </React.Fragment>
                ) : (
                    <>
                        <div>&nbsp;</div>
                        <Button
                            onClick={closeAlert}
                            color={confirmButtonColor || 'primary'}
                            variant={confirmButtonVariant || buttonVariant}
                        >
                            {t('common:ok')}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};
Alert.propTypes = {
    ...Dialog.propTypes,
    theme: PropTypes.object,
    classes: PropTypes.object,
    /** Event handler for confirm button click */
    onConfirm: PropTypes.func,
    /** Event handler for cancel button click */
    onCancel: PropTypes.func,
    /** Event handler when alert message close */
    onClose: PropTypes.func,
    /** Enable html content in message body. */
    html: PropTypes.bool,
    /** Display cancel button */
    showCancelButton: PropTypes.bool,
    /** Display Confirmation button */
    showConfirmButton: PropTypes.bool,
    /** Text for confirmation button */
    confirmButtonText: PropTypes.string,
    confirmButtonVariant: PropTypes.oneOf(['contained', 'outlined', 'text']),
    /** Color of confirmation button */
    confirmButtonColor: PropTypes.string,
    /** Text for cancel button */
    cancelButtonText: PropTypes.string,
    buttonVariant: PropTypes.oneOf(['contained', 'outlined', 'text']),
    /** Title of alert message */
    title: PropTypes.string,
    /** Text content */
    text: PropTypes.string,
    /** Type of alert message */
    type: PropTypes.oneOf(['warning', 'error', 'success', 'info', 'default'])
};

Alert.defaultProps = {
    type: 'default',
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel',
    buttonVariant: 'text',
    size: 'sm'
};

const styles = (theme) => ({
    root: {},
    paper: {padding: theme.spacing(2)},
    messageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing(3, 3, 3, 2)} !important`
    },
    title: {
        padding: theme.spacing(),
        fontSize: '1.2em'
    },
    message: {
        padding: theme.spacing()
    },
    messageDiv: {
        color: theme.palette.primary[theme.palette.type],
        paddingLeft: theme.spacing(3)
    },
    messageIcon: {
        fontSize: theme.spacing(6),
        color: '#FFA87C'
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    cancelButton: {
        padding: theme.spacing(1, 3)
    },
    confirmButton: {
        padding: theme.spacing(1, 3),
        color: '#fff',
        backgroundColor: theme.palette.primary[theme.palette.type]
    }
});

const mapStateToProps = (state, ownProps) => {
    const alertData = UISelector.getAlert(state);
    return {
        ...alertData,
        open: Boolean(alertData && alertData.text)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeAlert: () => dispatch(closeAlert())
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, {withTheme: true})(Alert));
