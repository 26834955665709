/**
 * medication Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';

import {
    CLEAR_MEDICATIONS,
    GET_MEDICATIONS_REQUEST,
    GET_MEDICATIONS_ERROR,
    GET_MEDICATIONS_SUCCESS,
    CLEAR_MEDICATION,
    GET_MEDICATION_REQUEST,
    GET_MEDICATION_ERROR,
    GET_MEDICATION_SUCCESS,
    UPDATE_MEDICATION_REQUEST,
    UPDATE_MEDICATION_ERROR,
    UPDATE_MEDICATION_SUCCESS,
    CREATE_MEDICATION_REQUEST,
    CREATE_MEDICATION_ERROR,
    CREATE_MEDICATION_SUCCESS,
    DELETE_MEDICATION_REQUEST,
    DELETE_MEDICATION_ERROR,
    DELETE_MEDICATION_SUCCESS
} from 'store/medication/MedicationActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function medications(state = initialState, action) {

    let newState = {};

    switch (action.type) {
        case CLEAR_MEDICATIONS: {
            newState = {
                ...initialState
            };
            break;
        }
        case GET_MEDICATIONS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_MEDICATIONS_SUCCESS: {
            newState = {
                ...initialState,
                patientId: action.patientId,
                status: action.status,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_MEDICATIONS_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_MEDICATION: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_MEDICATION_REQUEST:
        case UPDATE_MEDICATION_REQUEST:
        case CREATE_MEDICATION_REQUEST:
        case DELETE_MEDICATION_REQUEST: {
            const newData = {...state.data};
            //Object.assign(newData, {[action.id]: {status: action.status}});
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_MEDICATION_SUCCESS:
        case UPDATE_MEDICATION_SUCCESS:
        case CREATE_MEDICATION_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_MEDICATION_ERROR:
        case UPDATE_MEDICATION_ERROR:
        case CREATE_MEDICATION_ERROR:
        case DELETE_MEDICATION_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_MEDICATION_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
