import {createBrowserHistory, createMemoryHistory} from 'history';
import thunk from 'redux-thunk';
import {routerMiddleware} from 'connected-react-router';

import configureStore from 'store/root/reducerManager';

export const history = process.env.REACT_APP_ENV === 'test' ? createMemoryHistory() : createBrowserHistory();

const initialState = {};
const enhancers = [];

const middlewares = [
    routerMiddleware(history),
    thunk
];

const store = configureStore(history, enhancers, middlewares, initialState);

export default store;
