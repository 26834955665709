import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import AuthSelectors from 'store/auth/AuthSelectors';
import {loading} from 'store/ui/UIActions';
import UserSelectors from 'store/user/UserSelectors';
import PrivateRoute from 'core/PrivateRoute';
import RouteUtil from 'common/RouteUtil';
import ScreenLock from 'views/components/ScreenLock/ScreenLockContainer';
import MFAFormContainer from 'views/pages/MFAForm/MFAFormContainer';

/**
 * PreLogin Pages
 */
const Tos = React.lazy(() => import('views/pages/Tos'));
const PrivacyPolicy = React.lazy(() => import('views/pages/PrivacyPolicy'));

const Login = React.lazy(() => import('views/pages/Login/LoginContainer'));
const MainOrg = React.lazy(() => import('views/pages/MainOrg/MainOrgContainer'));
const ForgotPassword = React.lazy(() =>
    import('views/pages/ForgotPassword/ForgotPasswordContainer')
);
const ResetPassword = React.lazy(() =>
    import('views/pages/ResetPassword/ResetPasswordContainer')
);

/**
 * ToDo
 * Prelogin Pages Redesign Pending
 */

const ActivateAccount = React.lazy(() =>
    import('views/pages/ActivateAccount/ActivateAccountContainer')
);
const Recertification = React.lazy(() =>
    import('views/pages/Recertification/RecertificationContainer')
);
const ReactivateAccount = React.lazy(() =>
    import('views/pages/ReactivateAccount/ReactivateAccountContainer')
);
const ConfirmEmail = React.lazy(() =>
    import('views/pages/ConfirmEmail/ConfirmEmailContainer')
);
const RequestNewCode = React.lazy(() =>
    import('views/pages/RequestNewCode/RequestNewCodeContainer')
);

/**
 * Private Routes
 */
const Home = React.lazy(() => import('views/pages/Home/HomeContainer'));
const ChangePassword = React.lazy(() =>
    import('views/modules/ChangePassword/ChangePasswordContainer')
);

// Organizations
const Organizations = React.lazy(() =>
    import('views/modules/Organization/OrganizationListContainer')
);
const ViewOrganization = React.lazy(() =>
    import('views/modules/Organization/OrganizationViewContainer')
);
const ViewLocation = React.lazy(() =>
    import('views/modules/Location/LocationViewContainer')
);

// Users
const Users = React.lazy(() => import('views/modules/User/UserListContainer'));

// Patients
const Patients = React.lazy(() =>
    import('views/modules/Patient/PatientListContainer')
);
const ViewPatient = React.lazy(() =>
    import('views/modules/Patient/PatientViewContainer')
);
const ViewPatientHealthProfile = React.lazy(() =>
    import('views/modules/Patient/HealthProfile/PatientHealthProfileViewContainer')
);
const PatientDocuments = React.lazy(() => {
    import('views/modules/Patient/Document/PatientDocumentCategoriesListContainer');
});
const ViewPatientDocuments = React.lazy(() => {
    import('views/modules/Patient/Document/PatientDocumentListContainer');
});
const PatientTestResults = React.lazy(() =>
    import('views/modules/Patient/TestResult/PatientTestResultListContainer')
);
const PatientAppointments = React.lazy(() => {
    import('views/modules/Patient/TestResult/PatientTestResultListContainer');
});


const ViewPatientVitals = React.lazy(() => {
    import('views/modules/Patient/Vitals/PatientVitalsListContainer');
});

const AssignAssessments = React.lazy(() => {
    import('views/modules/Assessments/AssignAssessmentsContainer');
});

function Base(props) {
    useEffect(() => {
        if (props.isAuthenticated) {
            document.body.classList.remove('canScroll');
        }
    }, [props.isAuthenticated]);
    return (
        <>
            <Switch key={props.routeKey}>
                {/*  Prelogin Pages  */}
                <Route
                    path={RouteUtil.getRoutePath('base.tos')}
                    component={Tos}
                />
                {/* <Route
                exact
                path={RouteUtil.getRoutePath('base.test')}
                component={test}
            /> */}
                {/* <Route
                // exact
                path={RouteUtil.getRoutePath(
                    'base.modules.viewPatientHealthProfile'
                )}
                component={ViewPatientHealthProfile}
            /> */}
                <Route
                    path={RouteUtil.getRoutePath('base.privacy')}
                    component={PrivacyPolicy}
                />
                {/*<Route*/}
                {/*    exact*/}
                {/*    path={RouteUtil.getRoutePath('base.privacy')}*/}
                {/*    component={Privacy}*/}
                {/*/>*/}
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.login')}
                    component={Login}
                />
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.forgotPassword')}
                    component={ForgotPassword}
                />
                <Route
                    strict
                    exact
                    path={RouteUtil.getRoutePath('base.resetPassword')}
                    component={ResetPassword}
                />
                {/*  Prelogin Pages Redesign Pending  */}
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.confirmAccount')}
                    component={ActivateAccount}
                />
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.reactivateAccount')}
                    component={ReactivateAccount}
                />
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.confirmEmail')}
                    component={ConfirmEmail}
                />
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.recertification')}
                    render={(routeProps) => <Recertification {...routeProps} type={'recertification'}/>}
                />
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.recertificationReactivate')}
                    render={(routeProps) => <Recertification {...routeProps} type={'recertificationReactivate'}/>}
                />
                {/*<Route*/}
                {/*    exact*/}
                {/*    path={RouteUtil.getRoutePath('base.unlockAccount')}*/}
                {/*    component={ActivateAccount}*/}
                {/*/>*/}
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.requestNewCode')}
                    render={() => (
                        <RequestNewCode
                            serviceType={'auth'}
                            codeType={'activation'}
                        />
                    )}
                />
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.requestNewCodeRecertification')}
                    render={() => (
                        <RequestNewCode
                            serviceType={'auth'}
                            codeType={'recertification'}
                        />
                    )}
                />
                <Route
                    exact
                    path={RouteUtil.getRoutePath('base.changePassword')}
                    component={ChangePassword}
                />

                {/*  Private Routes  */}
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.validateMFA')}
                    component={MFAFormContainer}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.mainOrg')}
                    component={MainOrg}
                    forbiddenScope='isPatient'
                />
                <PrivateRoute
                    strict
                    path={RouteUtil.getRoutePath('base.modules.dashboard')}
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.private.changePassword')}
                    component={ChangePassword}
                />
                {/*  Organizations  */}
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.organizations')}
                    component={Organizations}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.viewOrganization')}
                    component={ViewOrganization}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.viewLocation')}
                    component={ViewLocation}
                />
                {/*  Users  */}
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.users')}
                    component={Users}
                />
                {/*  Patients  */}
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.patients')}
                    component={Patients}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.viewPatient')}
                    component={ViewPatient}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath(
                        'base.modules.viewPatientHealthProfile'
                    )}
                    component={ViewPatientHealthProfile}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.patientDocuments')}
                    component={PatientDocuments}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath(
                        'base.modules.viewPatientDocument'
                    )}
                    component={ViewPatientDocuments}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.patientTestResults')}
                    component={PatientTestResults}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath(
                        'base.modules.patientAppointments'
                    )}
                    component={PatientAppointments}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.assignAssessments')}
                    component={AssignAssessments}
                />
                <PrivateRoute
                    exact
                    path={RouteUtil.getRoutePath('base.modules.viewPatientVitals')}
                    component={ViewPatientVitals}
                />
            </Switch>
            {props.isAuthenticated && props.user && <ScreenLock/>}
        </>
    );
}

Base.propTypes = {
    hideLoading: PropTypes.func,
    routeKey: PropTypes.string,
    user: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
    isAuthenticated: AuthSelectors.getIsAuthenticated(state),
    isTokenExpired: AuthSelectors.getIsTokenExpired(state),
    user: UserSelectors.getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    showLoading: (type, message) => dispatch(loading({type, message})),
    hideLoading: (type) => dispatch(loading(type))
});

export default connect(mapStateToProps, mapDispatchToProps)(Base);
