import React from 'react';
import * as PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import LogoDark from 'media/logo_dark.png';
import LogoLight from 'media/logo_light.png';

const logos = {
    dark: LogoDark,
    light: LogoLight
};
const Logo = ({theme, classes, type, ...props}) => {
    const Logo = logos[type || theme.palette.type];
    const {t} = useTranslation();
    return (
        <div className={classes.root} {...props}>
            <img src={Logo} alt={t('title')} style={{width: '100%', maxWidth: '100%'}}/>
        </div>
    );
};

Logo.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    type: PropTypes.string
};
const styles = theme => ({
    root: {}
});
export default withStyles(styles, {withTheme: true})(Logo);
