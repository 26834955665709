import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from 'views/App';
import 'styles/global.css';
import 'styles/flags.css';
import 'intl-pluralrules';

ReactDOM.render(<App/>, document.getElementById('root'));

if (process.env.NODE_ENV !== 'production') {
    reportWebVitals(console.log);
}
