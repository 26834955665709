import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Button, CircularProgress, Typography, withStyles} from '@material-ui/core';

class LoadingButton extends Component {
    state = {
        isLoading: null
    };

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading !== this.props.loading) {
            this.setState({isLoading: this.props.loading});
        }
    }

    render() {

        const {
            loading,
            loadingText,
            children,
            theme,
            disabled,
            className,
            classes,
            onClick,
            LoadingTextProps,
            ProgressProps,
            ...props
        } = this.props;


        return (
            <Button
                className={classNames(className, classes.root)}
                disabled={disabled || this.state.isLoading}
                {...props}
                onClick={(e) => {
                    if (typeof onClick === 'function') {
                        onClick(e);
                    }
                    if (this.props.type && this.props.type !== 'submit') {
                        this.setState({isLoading: this.props.loading});
                    }
                }}
            >
                {!this.state.isLoading ? children : (
                    <React.Fragment>
                        <CircularProgress {...{
                            size: props.size === 'small' ? 18 : 24,
                            color: 'primary',
                            thickness: 3, ...ProgressProps
                        }} className={classes.progress}/>
                        <Typography {...{variant: 'caption', ...LoadingTextProps}} style={{textTransform: 'initial'}}>
                            {loadingText}...
                        </Typography>
                    </React.Fragment>
                )}
            </Button>
        );
    }
}

LoadingButton.propTypes = {
    ...Button.propTypes,
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    children: PropTypes.node,
    theme: PropTypes.object,
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    size: PropTypes.oneOf(['small', 'large'])
};

const style = theme => ({
    root: {},
    progress: {
        marginRight: theme.spacing()
    }
});

export default withStyles(style, {withTheme: true})(LoadingButton);
