import {createSelector} from 'reselect';
import _ from 'lodash';
import get from 'lodash/get';

import enums from 'common/constants/enums';

import {getInitials} from 'common/helpers/Str';

const emptyArray = [];
const emptyObject = {};

const getUserState = (state) => get(state, 'user', null);
const getAuthState = state => get(state, 'auth', emptyObject);
const getUserId = (state, userId) => userId || null;
const getUserOrganization = state => get(state, 'user.organization', null);

export const getRootStateUser = createSelector(
    [getUserState, getUserId, getAuthState],
    (usr, userId, auth) =>
        get(usr, `data[${userId || get(auth, 'userId', null)}]`, null)
);


const getRootState = createSelector(getUserState, (state) => get(state, 'data', emptyObject));

const getId = (state, id) => id || null;

const getOrgId = (state, orgId) => orgId || null;

const getParamObj = (state, params) => params || null;

class UserSelectorFactory {
    getState = getRootStateUser
    getUsers = createSelector(getRootState, (users) => {
        return _.values(users) || emptyArray;
    })
    getUsersByOrg = createSelector(getRootState, getOrgId, (users, orgId) => {
        return (
            _.values(users).filter((user) => user.organizations.some(({id}) => id === orgId)) ||
            emptyArray
        );
    })
    getUsersByOrgLocation = createSelector(
        getRootState,
        getParamObj,
        (users, params) => {
            return (
                _.values(users).filter(
                    (user) => user.organizations.some(organization =>
                        organization['user-organization'].location_id === params.locationId
                    )
                ) || emptyArray
            );
        }
    )
    getUser = createSelector(
        [getRootStateUser, getUserOrganization, getAuthState],
        (user, userOrganization, auth) => {
            if (!user || !user.id) {
                return null;
            }
            const loggedUserId = get(auth, 'userId', null);
            const firstName = get(user, 'firstName', '');
            const lastName = get(user, 'lastName', '');
            let fullname = `${firstName} ${lastName || ''}`.trim();
            let organization = get(user, 'organization', emptyObject);
            const patient = get(user, 'patient');

            if (!user.organization) {
                organization = get(user, 'organizations[0]', emptyObject);

                if (loggedUserId === user.id && userOrganization) {
                    organization = userOrganization || get(user, 'organizations[0]', emptyObject);

                }
            }

            if (patient) {
                if (organization.pseudonymize) {
                    if (organization.preferences?.[enums.ORGANIZATION_PREFERENCES.HIDE_PATIENT_FIRST_NAME]) {
                        fullname = patient.participantId;
                    }
                    else {
                        fullname = firstName;
                    }
                }
                else if (organization.preferences?.[enums.ORGANIZATION_PREFERENCES.HIDE_PATIENT_FIRST_NAME]) {
                    fullname = lastName;
                }
            }

            return {
                ...user,
                organization,
                fullname,
                initials: getInitials(fullname),
                photo: get(user, 'photo', '')
            };
        }
    )
    getPatient = createSelector(
        [getRootStateUser],
        (user) => {
            if (!user || !user.id) {
                return null;
            }
            return user.patient;
        }
    )
    getId = createSelector([getRootStateUser], (user) => get(user, 'id', null))
    getFullname = createSelector([getRootStateUser], (user) =>
        get(user, 'fullname', '')
    )
    getInitials = createSelector([getRootStateUser], (user) =>
        get(user, 'initials', '??')
    )
    getMyOrganization = createSelector(
        [this.getUser],
        (user) => (
            get(user, 'organization')
        )
    )
    getMyOrganizationId = createSelector(
        [this.getMyOrganization],
        (organization) => get(organization, 'id')
    )
    getStatus = createSelector(getUserState, (state) =>
        _.chain(state).get('status', '').value()
    )
    getLoading = (state) => this.getStatus(state) === 'loading'
    getError = createSelector(getUserState, (state) =>
        _.chain(state).get('error', null).value()
    )
}

export default new UserSelectorFactory();
