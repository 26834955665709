import API from 'common/API';
import Messages from 'common/constants/Messages';
import {getTranslation} from 'common/helpers/i18n';
import RouteUtil from 'common/RouteUtil';
import UserError from 'core/Error/UserError';
import titleCase from 'lodash/startCase';
import {SubmissionError} from 'redux-form';
import store from 'store';
import {clearToken, loginError, logout, requestLogin} from 'store/auth/AuthActions';
import rootService from 'store/root/rootService';
import UserService from 'store/user/UserService';

class AuthService {

    async login(props) {
        const {username, password} = props;
        store.dispatch(requestLogin());
        let _self = this;
        try {
            store.dispatch(clearToken());
            const params = {
                username: username,
                password: password,
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET
            };

            const endpoint = API.resolveRoute('auth', 'login');
            const response = await API.request(endpoint.url, params, endpoint.method, true);

            if (response.error) {
                if (response.error === 'validation-error') {
                    throw new SubmissionError({
                        _error: response.message,
                        ...response.fields,
                        statusCode: response.status
                    });
                }
                throw response;
            }

            return await this.loginSuccessAction(response);
        }
        catch (e) {
            store.dispatch(clearToken());
            store.dispatch(loginError(e));
            throw e;
        }
    }

    async loginSuccessAction(response) {

        const {userId, access_token, expires, refresh_token, roles, scopes, token_type, challenge} = response;

        await this.saveToken({
            access_token,
            refresh_token,
            roles,
            scopes,
            token_type,
            challenge,
            userId,
            expires
        });

        return response;
    }

    async saveToken(token) {
        await API.saveToken(token);
        if (token.challenge) {
            if (token.challenge === 'change-password') {
                if (window.location.pathname !== RouteUtil.getRoutePath('base.changePassword')) {
                    window.location.href = RouteUtil.getRoutePath('base.changePassword');
                }
                return;
            }
            else if (token.challenge === 'validate-mfa') {
                if (window.location.pathname !== RouteUtil.getRoutePath('base.validateMFA')) {
                    window.location.href = RouteUtil.getRoutePath('base.validateMFA');
                }
                return;
            }
            else if (token.challenge === 'select_organization') {
                await rootService.getConfiguration();
                if (window.location.pathname !== RouteUtil.getRoutePath('base.mainOrg')) {
                    window.location.href = RouteUtil.getRoutePath('base.mainOrg');
                }
                return;
            }
        }
        const tokenHeaders = !token?.refresh_token ? {'x-user-id': token.userId} : undefined;
        await rootService.getConfiguration(null, tokenHeaders);
        await UserService.getMe(null, tokenHeaders);
    }

    async forgotPassword(props) {
        const {username} = props;
        let _self = this;
        const params = {
            username
        };
        const endpoint = API.resolveRoute('auth', 'forgotPassword');
        const response = await API.request(endpoint.url, params, endpoint.method, true);

        if (response.error && response.error === 'validation-error') {
            throw new SubmissionError({
                _error: response.message,
                ...response.fields,
                statusCode: response.status
            });
        }

        return response;
    }

    async resetPassword({resetToken, email, password, password_confirmation}) {
        if (!resetToken) {
            const {t} = getTranslation(['messages']);

            throw new UserError({
                error: Messages.ERROR,
                message: t('no-reset-password-code')
            });
        }

        const params = {
            password: password,
            password_confirmation: password_confirmation
        };

        const endpoint = API.resolveRoute('auth', 'resetPassword', {resetToken});
        const response = await API.request(endpoint.url, params, endpoint.method, true);

        if (response.error && response.error === 'validation-error') {
            throw new SubmissionError({
                _error: response.message,
                ...response.fields,
                statusCode: response.status
            });
        }
        return response;
    }

    async activateAccount(activateToken, needsPasswordChange) {
        const endpoint = API.resolveRoute('auth', 'activate', {activateToken});
        const response = await API.request(endpoint.url, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            needsPasswordChange
        }, endpoint.method, true);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async reactivateAccount(activateToken) {
        const endpoint = API.resolveRoute('auth', 'reactivate', {activateToken});
        const response = await API.request(endpoint.url, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
        }, endpoint.method, true);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async confirmEmail(confirmToken) {
        const endpoint = API.resolveRoute('auth', 'confirmEmail', {confirmToken});
        const response = await API.request(endpoint.url, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
        }, endpoint.method, true);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async recertification(token, type) {
        const endpoint = API.resolveRoute('auth', type, {token});
        const response = await API.request(endpoint.url, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
        }, endpoint.method, true);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async requestNewCode(values, service, codeType, isPublic = true) {
        const endpoint = API.resolveRoute(service, `requestNewCode${titleCase(codeType)}`);
        const response = await API.request(endpoint.url, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            ...values
        }, endpoint.method, isPublic);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async changePassword(values) {
        const endpoint = API.resolveRoute('auth', 'changePassword');
        const response = await API.request(endpoint.url, values, endpoint.method, false);
        if (response.error) {
            throw response;
        }
        return response;
    }

    async enableMFA(values) {
        const endpoint = API.resolveRoute('auth', 'enableMFA');
        const response = await API.request(endpoint.url, values, endpoint.method, false);

        if (response.error) {
            throw response;
        }
        return response;
    }

    async validateMFA(values) {

        const endpoint = API.resolveRoute('auth', 'validateMFA');
        const response = await API.request(endpoint.url, values, endpoint.method, false);

        if (response.error) {
            throw response;
        }

        return await this.loginSuccessAction(response);
    }

    async unlockMFA(values) {
        const endpoint = API.resolveRoute('auth', 'unlock');
        const response = await API.request(endpoint.url, values, endpoint.method, false);

        if (response.error) {
            throw response;
        }

        return API.saveToken(response);
    }

    async sendMFACode(params) {
        const endpoint = API.resolveRoute('auth', 'sendMFACode');
        const result = await API.request(endpoint.url, params, endpoint.method, false);
        if (!result || result.error) {
            throw result;
        }
        return result;
    }

    async unlockScreen(values) {

        const endpoint = API.resolveRoute('auth', 'unlock');
        const response = await API.request(endpoint.url, values, endpoint.method, false);

        if (response.error) {
            throw response;
        }
        return response;
    }

    async lockScreen(values) {

        const endpoint = API.resolveRoute('auth', 'lock');
        const response = await API.request(endpoint.url, values, endpoint.method, false);

        if (response.error) {
            throw response;
        }
        await API.saveToken(response);
        return response;
    }

    async logout(redirect) {
        let headers = null;
        if (API.isSpecialToken()) {
            const token = API.getToken();
            headers = {'x-user-id': token.userId};
        }
        const endpoint = API.resolveRoute('auth', 'logout');
        const response = await API.request(endpoint.url, null, endpoint.method, false, headers);
        if (response.error) {
            throw response;
        }
        if (redirect) {
            store.dispatch(logout(redirect));
        }
    }
}

export default new AuthService;
