import Config from 'common/Config';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import titleCase from 'lodash/startCase';
import moment from 'moment';
import 'moment/min/locales';
import {initReactI18next} from 'react-i18next';

const apiPath = `${Config.get('locale.url', '/').replace(/\/$/, '')}/translate/{{lng}}/{{ns}}`;
const backupPath = `${Config.get('locale.backupUrl', '/').replace(/\/$/, '')}/locales/{{lng}}/{{ns}}${Config.get('locale.extension', '.json')}`;

const defaultBackendConfig = {
    allowMultiLoading: true,
    crossDomain: true,
    requestOptions: {
        // cache: 'default'
    }
};

i18n
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        initImmediate: false,
        backend: {
            backends: [
                HttpBackend,
                HttpBackend
            ],
            backendOptions: [{
                ...defaultBackendConfig,
                loadPath: process.env.TRANSLATION === 'local' ? backupPath : apiPath
            }, {
                ...defaultBackendConfig,
                loadPath: backupPath
            }]
        },
        detection: {
            order: ['sessionStorage', 'navigator'],
            caches: ['sessionStorage'],
            excludeCacheFor: ['cimode']
        },
        // nonExplicitSupportedLngs: true,
        ns: ['common', 'messages', 'errors', 'login', 'patients'],
        defaultNS: 'common',
        fallbackNS: 'common',
        lang: 'en',
        lowerCaseLng: true,
        cleanCode: true,
        fallbackLng: 'en',
        load: 'all',
        debug: process.env.STAGE === 'local',
        interpolation: {
            escapeValue: false, // react already safes from xss
            format: function (value, format, lng) {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }
                if (format === 'titlecase') {
                    return titleCase(value);
                }
                if (value instanceof Date) {
                    return moment(value).format(format);
                }
                return value;
            }
        }
    });
i18n.on('initialized', function (options) {
    // moment.locale(i18n.languages[0]);
});

i18n.on('languageChanged', function (lng) {
    moment.locale(lng);
});


export default i18n;
