/**
 * Documents ActionTypes definitions
 */

export const CLEAR_CATEGORIES = 'categories/clear';
export const GET_CATEGORIES_REQUEST = 'categories/request';
export const GET_CATEGORIES_ERROR = 'categories/error';
export const GET_CATEGORIES_SUCCESS = 'categories/success';

export const CLEAR_DOCUMENTS = 'documents/clear';
export const GET_DOCUMENTS_REQUEST = 'documents/request';
export const GET_DOCUMENTS_ERROR = 'documents/error';
export const GET_DOCUMENTS_SUCCESS = 'documents/success';

export const CLEAR_DOCUMENT = 'document/clear';
export const GET_DOCUMENT_REQUEST = 'document/get/request';
export const GET_DOCUMENT_ERROR = 'document/get/error';
export const GET_DOCUMENT_SUCCESS = 'document/get/success';

export const UPDATE_DOCUMENT_REQUEST = 'document/update/request';
export const UPDATE_DOCUMENT_ERROR = 'document/update/error';
export const UPDATE_DOCUMENT_SUCCESS = 'document/update/success';

export const CREATE_DOCUMENT_REQUEST = 'document/create/request';
export const CREATE_DOCUMENT_ERROR = 'document/create/error';
export const CREATE_DOCUMENT_SUCCESS = 'document/create/success';

export const DELETE_DOCUMENT_REQUEST = 'document/delete/request';
export const DELETE_DOCUMENT_ERROR = 'document/delete/error';
export const DELETE_DOCUMENT_SUCCESS = 'document/delete/success';

export const DOWNLOAD_DOCUMENT_REQUEST = 'document/download/request';
export const DOWNLOAD_DOCUMENT_ERROR = 'document/download/error';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'document/download/success';
