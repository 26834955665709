import {createSelector} from 'reselect';
import get from 'lodash/get';
import intersection from 'lodash/intersection';

import moment from 'moment';

import UserSelectors from 'store/user/UserSelectors';

const emptyArray = [];
const emptyObject = {};

const getCanState = (state, scopes) => scopes || [];
const getAuthState = state => get(state, 'auth', emptyObject);

export const can = createSelector(
    [
        getAuthState,
        getCanState
    ], (auth, scopes) => intersection(typeof scopes === 'string' ? [scopes] : scopes, get(auth, 'scopes', emptyArray)).length > 0
);

export const getRootStateAuth = createSelector(
    [getAuthState],
    authState => authState || emptyObject
);

class AuthSelectorFactory {
    getAuth = getRootStateAuth;
    getCurrentUser = createSelector(
        this.getAuth,
        auth => get(auth, 'userId')
    );
    getAccessToken = createSelector(
        this.getAuth,
        auth => get(auth, 'access_token', null)
    );
    getChallenge = createSelector(
        this.getAuth,
        auth => get(auth, 'challenge', null)
    );
    getRefreshToken = createSelector(
        this.getAuth,
        auth => get(auth, 'refresh_token', null)
    );

    getExpiresAt = createSelector(
        this.getAuth,
        auth => moment.unix(get(auth, 'expires', moment().subtract(1, 'hour').utc().unix())).utc()
    );

    getIsTokenExpired = createSelector(
        this.getAuth,
        auth => {
            const expiration = get(auth, 'expires', moment().subtract(1, 'hour'));
            return expiration && moment.utc().isAfter(moment.unix(expiration).utc());
        }
    );
    getAuthStatus = createSelector(
        this.getAuth,
        auth => get(auth, 'status', 'unauthenticated')
    );

    getIsTokenRefreshing = createSelector(
        this.getAuth,
        auth => get(auth, 'isRefreshing', false)
    );
    getRoles = createSelector(
        this.getAuth,
        auth => get(auth, 'roles', emptyArray)
    );
    getIsAuthenticated = createSelector(
        this.getAuth,
        auth => Boolean(get(auth, 'status', 'unauthenticated') === 'authenticated' && get(auth, 'access_token', null) !== null)
    );
    getIsInternal = createSelector(
        this.getAuth,
        auth => {
            const scopes = get(auth, 'scopes', emptyArray) || emptyArray;
            return scopes.includes('isInternal');
        }
    );
    getIsPatient = createSelector(
        this.getAuth,
        auth => {
            const scopes = get(auth, 'scopes', emptyArray) || emptyArray;
            return scopes.includes('isPatient');
        }
    );
    getIsMultiLoginUser = createSelector(
        [this.getIsPatient, UserSelectors.getUser],
        (isPatient, user) => {
            return !isPatient && user?.organizations?.length > 1;
        }
    )
    getScopes = createSelector(
        [this.getAuth, UserSelectors.getUser, this.getIsInternal],
        (auth, user, isInternal) => {
            const scopes = get(auth, 'scopes') || emptyArray;

            if (get(user, 'organization.preferences.demo_organization', false) && !isInternal) {
                return scopes?.filter(scope => !scope.includes('test-result'));
            }

            if (get(user, 'organization.preferences.hide_assessment_results', false) && !isInternal) {
                return scopes?.filter(scope => !scope.includes('test-result'));
            }

            if (!get(user, 'organization.preferences.display_patient_results', true) && scopes.includes('isPatient')) {
                return scopes?.filter(scope => !scope.includes('test-result'));
            }

            return scopes;
        }
    );
}

export default new AuthSelectorFactory();
