import {
    FilledInput,
    FormControl,
    FormHelperText,
    Icon,
    Input,
    InputLabel,
    OutlinedInput,
    Typography, withStyles
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import snakeCase from 'lodash/snakeCase';
import {HelpCircle} from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import FieldChangeReason from 'views/components/FieldChangeReason';
import FieldHistoryComponent from 'views/components/fields/FieldHistoryComponent';

const InputField = ({
    theme,
    classes,
    label,
    fullWidth,
    shrink,
    required,
    disabled,
    variant,
    singleRow,
    help,
    input,
    meta,
    className = {},
    defaultValue,
    type,
    MenuProps,
    disablePortal,
    multiline,
    disableOutline,
    inputProps = {},
    needs_reason = false,
    history,
    ...props
}) => {
    const formControlProps = {
        disabled,
        error: Boolean(meta?.touched && meta?.error),
        fullWidth,
        required,
        variant
    };
    const fieldRef = useRef(null);
    let InputComponent;
    if (variant === 'filled') {
        InputComponent = FilledInput;
    }
    else if (variant === 'outlined') {
        InputComponent = OutlinedInput;
    }
    else {
        InputComponent = Input;
    }
    const {t} = useTranslation();
    const [inputValue, setInputValue] = useState(input.value || undefined);

    useEffect(val => {
        setInputValue(input.value);
    }, [input.value]);
    const setValue = debounce((e, value) => {
        input.onChange(value);
        input.onBlur(value);
    }, 600);

    const handleChange = e => {
        const value = e.target.value;
        setInputValue(value);
        if (!needs_reason) {
            input.onChange(value);
        }
    };

    const handleBlur = e => {
        if (!needs_reason) {
            return input.onBlur(e);
        }
        setValue(e, inputValue);
    };

    const renderHelp = () => {
        if (meta?.touched && meta?.invalid) {
            return <Typography variant={'caption'} style={{color: theme.palette.danger.main}}>{meta.error}</Typography>;
        }
        if (help) {
            return (
                <div
                    style={{
                        marginTop: theme.spacing(),
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}
                >
                    <HelpCircle
                        fontSize={'small'}
                        color={'primary'}
                        style={{marginRight: theme.spacing()}}
                    />{' '}
                    {help.indexOf(':') > -1 ? t(help) : help}
                </div>
            );
        }
    };

    Object.assign(props, {
        endAdornment: [
            meta?.dirty && needs_reason && (
                <FieldChangeReason
                    key={`${input.name}-change-reason`}
                    input={input}
                    meta={meta}
                />
            ),
            <FieldHistoryComponent history={history} key={`${input.name}-field-history`}/>,
            <React.Fragment key={`${input.name}-default-adornment`}>{props?.endAdornment}</React.Fragment>
        ]
    });

    return (
        <FormControl
            {...formControlProps}
            error={Boolean(meta.touched && meta.error)}
            className={classNames(classes.formControl, {
                [classes.singleRow]: singleRow
            })}
        >
            {label && type !== 'hidden' && (
                <InputLabel
                    shrink={
                        Boolean(props.startAdornment && props.endAdornment) ||
                        type && type === 'date' ||
                        Boolean(input.value) ||
                        shrink
                    }
                    error={Boolean(meta.touched && meta.error)}
                    required={props.required}
                    className={classes.inputLabel}
                >
                    {label}
                </InputLabel>
            )}
            <InputComponent
                className={classNames(classes.inputComponent, className)}
                {...input}
                {...props}
                inputProps={{
                    tabIndex: props.tabIndex,
                    ref: fieldRef,
                    'data-lpignore': 'true',
                    id: input.id || snakeCase(input.name),
                    className: classes.input,
                    ...inputProps
                }}
                value={inputValue || undefined}
                onChange={handleChange}
                onBlur={handleBlur}
                multiline={multiline}
                type={type}
            />
            {((meta && meta.touched && meta.invalid) || help) && (
                <FormHelperText
                    component={'div'}
                    color={meta?.touched && meta?.error ? 'error' : 'default'}
                    variant={'standard'}
                >
                    {(help || props.validate || meta?.error) && renderHelp()}&nbsp;
                </FormHelperText>
            )}
        </FormControl>
    );
};

InputField.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    halfWidth: PropTypes.bool,
    mediumWidth: PropTypes.bool,
    grid: PropTypes.any,
    singleRow: PropTypes.bool,
    input: PropTypes.object,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    margin: PropTypes.string,
    meta: PropTypes.object,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    startAdornment: PropTypes.any,
    endAdornment: PropTypes.any,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    shrink: PropTypes.bool,
    validate: PropTypes.any,
    help: PropTypes.string,
    type: PropTypes.string,
    tabIndex: PropTypes.number,
    defaultValue: PropTypes.string,
    MenuProps: PropTypes.any,
    disablePortal: PropTypes.any,
    hasSearch: PropTypes.bool,
    multiline: PropTypes.bool,
    needs_reason: PropTypes.bool,
    history: PropTypes.array,
    disableOutline: PropTypes.bool
};
const styles = (theme) => ({
    root: {},
    input: {}
});

export default withStyles(styles, {withTheme: true})(InputField);
