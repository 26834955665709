/**
 * Questionnaires Reducer definitions
 */

import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import {LOGOUT} from 'store/auth/AuthActionTypes';

import {
    CLEAR_QUESTIONNAIRE,
    CLEAR_QUESTIONNAIRES,
    CREATE_QUESTIONNAIRE_ANSWER_ERROR,
    CREATE_QUESTIONNAIRE_ANSWER_SUCCESS,
    CREATE_QUESTIONNAIRE_ERROR,
    CREATE_QUESTIONNAIRE_REQUEST,
    DELETE_QUESTIONNAIRE_ERROR,
    DELETE_QUESTIONNAIRE_REQUEST,
    DELETE_QUESTIONNAIRE_SUCCESS,
    GET_ANSWER_SUCCESS,
    GET_ORG_QUESTIONNAIRES_ERROR,
    GET_ORG_QUESTIONNAIRES_REQUEST,
    GET_ORG_QUESTIONNAIRES_SUCCESS,
    GET_PATIENT_QUESTIONNAIRES_ERROR,
    GET_QUESTIONNAIRE_ERROR,
    GET_QUESTIONNAIRE_REQUEST,
    GET_QUESTIONNAIRE_SUCCESS,
    GET_QUESTIONNAIRES_ERROR,
    GET_QUESTIONNAIRES_REQUEST,
    GET_QUESTIONNAIRES_SUCCESS,
    UPDATE_ORG_QUESTIONNAIRES_ERROR,
    UPDATE_ORG_QUESTIONNAIRES_REQUEST,
    UPDATE_ORG_QUESTIONNAIRES_SUCCESS,
    UPDATE_QUESTIONNAIRE_ANSWER_ERROR,
    UPDATE_QUESTIONNAIRE_ANSWER_SUCCESS,
    UPDATE_QUESTIONNAIRE_ERROR,
    UPDATE_QUESTIONNAIRE_REQUEST,
    GET_PATIENT_QUESTIONNAIRES_SUCCESS
} from 'store/questionnaires/QuestionnaireActionTypes';

const initialState = {
    data: {},
    orgQuestionnaires: {},
    status: 'idle',
    error: null
};

export default function questionnaires(state = initialState, action) {
    let newState = {};

    switch (action.type) {
        case CLEAR_QUESTIONNAIRES: {
            newState = {
                ...state,
                ...initialState
            };
            break;
        }
        case GET_QUESTIONNAIRES_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_QUESTIONNAIRES_SUCCESS: {
            newState = {
                ...state,
                status: action.status,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_QUESTIONNAIRES_ERROR: {
            newState = {
                ...state,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_QUESTIONNAIRE: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_QUESTIONNAIRE_REQUEST:
        case UPDATE_QUESTIONNAIRE_REQUEST:
        case CREATE_QUESTIONNAIRE_REQUEST:
        case GET_ORG_QUESTIONNAIRES_REQUEST:
        case UPDATE_ORG_QUESTIONNAIRES_REQUEST:
        case DELETE_QUESTIONNAIRE_REQUEST: {
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                status: action.status,
                error: null
            };
            break;
        }
        case GET_QUESTIONNAIRE_SUCCESS: {
            newState = {
                ...state,
                status: action.status,
                questionnaireDetails: action.data,
                error: null
            };
            break;
        }
        case UPDATE_QUESTIONNAIRE_ANSWER_ERROR:
        case CREATE_QUESTIONNAIRE_ANSWER_ERROR:
        case GET_QUESTIONNAIRE_ERROR:
        case UPDATE_QUESTIONNAIRE_ERROR:
        case CREATE_QUESTIONNAIRE_ERROR:
        case GET_ORG_QUESTIONNAIRES_ERROR:
        case UPDATE_ORG_QUESTIONNAIRES_ERROR:
        case DELETE_QUESTIONNAIRE_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_QUESTIONNAIRE_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_ANSWER_SUCCESS: {
            const totalScore = state.totalScore
                ? state.totalScore
                : Object.assign({});
            Object.assign(totalScore, {
                [action.id.questionnaire_id]: action.id.total_score
            });
            newState = {
                ...state,
                answers: {...action.id.answers},
                status: action.status,
                totalScore: totalScore,
                error: null
            };
            break;
        }
        case GET_PATIENT_QUESTIONNAIRES_SUCCESS: {
            newState = {
                ...state,
                patientQuestionnaires: [...action.data.result],
                patientId: action.data.patientId,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_PATIENT_QUESTIONNAIRES_ERROR: {
            if (action.error.error === 'NOT_FOUND') {
                newState = {
                    ...initialState,
                    status: action.status,
                    patientQuestionnaires: [],
                    patientId: null,
                    error: action.error
                };
            }
            else {
                newState = {
                    ...initialState,
                    status: action.status,
                    patientQuestionnaires: [],
                    patientId: null,
                    error: null
                };
            }
            break;
        }
        case UPDATE_ORG_QUESTIONNAIRES_SUCCESS: {
            if (state.orgQuestionnaires[action.id]) {
                newState = {
                    ...state,
                    status: action.status,
                    orgQuestionnaires: {
                        ...state.orgQuestionnaires,
                        [action.id]: [...state.orgQuestionnaires[action.id], action.data[action.data.length - 1]]
                    },
                    error: null
                };
            }
            else {
                newState = state;
            }
            break;
        }
        case GET_ORG_QUESTIONNAIRES_SUCCESS: {
            newState = {
                ...state,
                status: action.status,
                orgQuestionnaires: {
                    ...state.orgQuestionnaires,
                    [action.id]: action.data
                },
                error: null
            };
            break;
        }
        case CREATE_QUESTIONNAIRE_ANSWER_SUCCESS:
        case UPDATE_QUESTIONNAIRE_ANSWER_SUCCESS: {
            const {data, id} = action;
            const newPatientQuestionnaires = [...state.patientQuestionnaires.filter(q => q.id !== data.id), data];
            newState = {
                ...state,
                status: action.status,
                patientQuestionnaires: newPatientQuestionnaires,
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
