import {withStyles} from '@material-ui/core';
import {oneTimePassCode, required} from 'common/validate';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {reduxForm} from 'redux-form';
import {MFAField} from 'views/components/fields/MFAField';
import CommonFormBuilder from 'views/components/CommonFormBuilder';

const MFAForm = ({theme, classes, handleSendMFACode, user, ...props}) => {
    const formFields = useMemo(() => [
        {
            component: MFAField,
            label: 'code-from-authenticator',
            name: 'code',
            validate: [required, oneTimePassCode],
            required: true,
            type: 'text',
            methods: ['authenticator', user?.phone && 'sms', user?.email && 'email'].filter(Boolean),
            handleSendMFACode: handleSendMFACode
        }
    ], [user, handleSendMFACode]);

    const submitButton = {
        loadingText: 'processing',
        mainLabel: 'mfa:validate'
    };

    const cancelButton = {
        clickAction: props.handleCancel,
        mainLabel: 'common:cancel'
    };

    return (
        <div className={classes.root}>
            <CommonFormBuilder
                classes={{errorMessage: classes.errorMessage}}
                translationBase={'mfa'}
                title={'validate-mfa-title'}
                subTitle={'validate-mfa-details'}
                formFields={formFields}
                submitButton={submitButton}
                cancelButton={cancelButton}
                {...props}
            />
        </div>
    );
};

MFAForm.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    user: PropTypes.object,
    handleCancel: PropTypes.func,
    handleSendMFACode: PropTypes.func
};

const styles = theme => ({
    root: {
        paddingBottom: theme.spacing(4)
    },
    errorMessage: {
        color: theme.palette.lightError.main
    }
});

export const MFA_FORM_NAME = 'mfa-validation';
export default reduxForm({
    form: MFA_FORM_NAME
})(withStyles(styles, {withTheme: true})(MFAForm));
