import _ from 'lodash';
import {createSelector} from 'reselect';
import {getRootConfigState} from 'store/root/rootSelectors';

const emptyArray = [];
const emptyObject = {};

const getModuleState = state => _.get(state, 'modules', emptyObject);

const getId = (state, id) => id || null;

export const getRootStateModule = createSelector(
    getModuleState,
    module => module || null
);

const ModuleSelectorFactory = getState => ({
    getState,
    getModules: createSelector(
        [getState, getRootConfigState],
        (data, config) => _.chain(data).get('data', emptyObject).values().value()
    ),
    getModule: createSelector(
        [getState, getId, getRootConfigState],
        (module, id, config) => _.chain(module).get(`data.${id}`).value()
    ),
    getStatus: createSelector(
        getState,
        state => _.chain(state).get('status', '').value()
    ),
    getLoading: (state) => _.chain(state).get('status', '').value() === 'loading',
    getError: createSelector(
        getState,
        state => _.chain(state).get('error', null).value()
    )
});

export default ModuleSelectorFactory(getRootStateModule);

