import _ from 'lodash';
import pluralize from 'pluralize';
import {createSelector} from 'reselect';
import get from 'lodash/get';

import {array2NameValue, objArray2NameValue} from 'common/helpers/Arr';

const emptyObject = {};
const emptyArray = [];

const getId = (state, ids) => ids || null;
const getRootConfig = (state) => _.get(state, 'config', emptyObject);

export const getRootConfigState = createSelector(
    getRootConfig,
    (config) => config || null
);

class RootSelectorFactory {
    getConfig = getRootConfigState;
    getPermissions = (state) => _.get(state, 'config.permissions', emptyObject);
    getPermissionsObjWithGroup = createSelector(
        [this.getPermissions],
        config => _
            .chain(config)
            .entries()
            .map(o => {
                const group = pluralize(
                    _
                        .chain(o)
                        .last()
                        .replace(/(List|View|Delete|Update|Create)/, '')
                        .trim()
                        .startCase()
                        .value()
                );

                return _.zipObject(['value', 'name', 'group'], [...o, group]);
            })
            .sortBy(['group', 'name'])
            .map(item => ({...item, name: _.startCase(_.get(item, 'name'))}))
            .value()
    );
    getRoles = (state) => _.get(state, 'config.roles', emptyObject);
    getLocations = (state) => _.get(state, 'config.locations', emptyObject);
    getSortedLocationList = createSelector(
        [this.getLocations],
        (locations) => _.chain(objArray2NameValue(locations))
            .sortBy(o => o.name.toLowerCase())
            .value()
    );
    getUsers = (state) => _.get(state, 'config.users', emptyObject);
    getOrganizations = (state) => _.get(state, 'config.organizations', emptyObject);
    getSortedOrganizationsList = createSelector(
        [this.getOrganizations],
        (organizations) => _.chain(objArray2NameValue(organizations))
            .sortBy(o => o.name.toLowerCase())
            .value()
    );
    getBiologicalSex = (state) => _.get(state, 'config.biological_sex_id_slug', emptyObject);
    getBpArm = (state) => _.get(state, 'config.bp_arm', emptyObject);
    getBpPosition = (state) => _.get(state, 'config.bp_position', emptyObject);
    getMaritalStatuses = (state) => _.get(state, 'config.marital_status_id_slug', emptyObject);
    getLivingArrangements = (state) => _.get(state, 'config.living_arrangement_id_slug', emptyObject);
    getEducations = (state) => _.get(state, 'config.education_id_slug', emptyObject);
    getDominantHand = (state) => _.get(state, 'config.dominant_hand', emptyObject);
    getDocumentUploadCategories = (state) => _.get(state, 'config.document_categories_upload_allowed', emptyObject);
    getMedicationFrequency = (state) => _.get(state, 'config.medication_frequency', emptyObject);
    getMedicationRoute = (state) => _.get(state, 'config.medication_route', emptyObject);
    getMedicalHistory = (state) => _.get(state, 'config.medical_history', emptyObject);
    getDrugAllergy = (state) => _.get(state, 'config.allergen_drug', emptyObject);
    getFoodAllergy = (state) => _.get(state, 'config.allergen_food', emptyObject);
    getEnvironmentalAllergy = (state) => _.get(state, 'config.allergen_environment', emptyObject);
    getAllergyReaction = (state) => _.get(state, 'config.allergy_reaction', emptyObject);
    getPatientTypes = (state) => _.get(state, 'config.patient_type', emptyObject);
    getPatientTypeSlugs = (state) => _.get(state, 'config.patient_type_slug', emptyObject);
    getHeartRateRhythm = (state) => _.get(state, 'config.heart_rate_rhythm', emptyObject);
    getVitalUnitHeight = (state) => _.get(state, 'config.vital_unit_height', emptyObject);
    getVitalUnitWeight = (state) => _.get(state, 'config.vital_unit_weight', emptyObject);
    getVisitType = (state) => _.get(state, 'config.visit_type', emptyObject);
    getVisitStatus = (state) => _.get(state, 'config.visit_status', emptyObject);
    getVisitTransportation = (state) => _.get(state, 'config.visit_transportation', emptyObject);
    getVisitActivitiesTypes = (state) => _.get(state, 'config.visit_type_activity_types', emptyObject);
    getVisitActivitiesStatuses = (state) => _.get(state, 'config.visit_type_new_visit_statuses', emptyObject);
    getVisitActivitiesReasons = (state) => _.get(state, 'config.visit_status_reasons', emptyObject);
    getLabResultSchema = (state) => _.get(state, 'config.lab_result_schema', emptyObject);
    getLabResultsCsfSites = (state) => _.get(state, 'config.lab_results_csf_sites', emptyArray);
    getLabResultStatuses = (state) => _.get(state, 'config.lab_result_statuses', emptyArray);
    getImagingResultSchema = (state) => get(state, 'config.imaging_result_schema', emptyObject);
    getImagingResultProcedures = createSelector(
        [this.getImagingResultSchema, getId],
        (schema, category) => array2NameValue(Object.keys(schema[category]))
    );
    getImagingResultStatuses = (state) => get(state, 'config.imaging_result_statuses', emptyArray);
    getImagingResultTypes = (state) => get(state, 'config.imaging_result_types', emptyArray);
    getHobbies = (state) => _.get(state, 'config.hobby_id_slug', emptyObject);
    getEthnicity = (state) => _.get(state, 'config.ethnicity_id_slug', emptyObject);
    getDnsSources = (state) => _.get(state, 'config.dns_sources', emptyArray);
    getSubstudyTypes = (state) => _.get(state, 'config.substudy_participant_types', emptyArray);
    getOrganizationTypes = (state) => _.get(state, 'config.organization_types', emptyArray).map(ot => ({[ot]: ot})).reduce((a, b) => ({...a, ...b}));
    getOrgPreferences = (state) => _.get(state, 'config.organization_preferences_schema', emptyObject);
    getOrgPreferencesOptions = createSelector(
        [getRootConfig],
        (config) => {
            const prefs = _.chain(config).get('organization_preferences_schema', emptyObject).value();
            return _.chain(prefs).keys().map(k => ({name: k, ...prefs[k]})).sortBy('type').value();
        });
    getOrgPreferencesSchema = createSelector(
        [this.getOrgPreferencesOptions],
        (prefs) => {
            return _.chain(prefs)
                .reduce((acc, preference) => {
                    if (preference.group) {
                        if (!acc.grouped[preference.group]) {
                            if (preference.subgroup) {
                                acc.grouped[preference.group] = {
                                    subgroupList: [preference],
                                    noSubgroupList: []
                                };
                            } else {
                                acc.grouped[preference.group] = {
                                    subgroupList: [],
                                    noSubgroupList: [preference]
                                };
                            }
                        }
                        // add preferences have not been added to a subgroup or do not have a subgroup
                        else if (preference.subgroup === null || !acc.subgroup[preference.subgroup]) {
                            if (preference.subgroup === null) {
                                acc.grouped[preference.group].noSubgroupList.push(preference);
                            } else {
                                acc.grouped[preference.group].subgroupList.push(preference);
                            }
                        }
                        if (!acc.subgroup[preference.subgroup]) {
                            acc.subgroup[preference.subgroup] = [preference];
                        } else {
                            acc.subgroup[preference.subgroup].push(preference);
                        }
                    }
                    else {
                        acc.ungrouped.push(preference);
                    }

                    return acc;
                }, {grouped: {}, subgroup: {}, ungrouped: []})
                .value();
        });
    getCognitiveStatusDiagnosis = (state) => _.get(state, 'config.cognitive_status_diagnosis', emptyObject);
}

export default new RootSelectorFactory();
