import {go} from 'connected-react-router';
import RouteUtil from 'common/RouteUtil';
import SessionStorage from 'common/SessionStorage';
import {
    CLEAR_TOKEN,
    CLOSE_WELCOME,
    LOGIN_ERROR,
    LOGOUT,
    REFRESH_TOKEN,
    REFRESHING_TOKEN,
    REQUEST_LOGIN,
    SET_CURRENT_USER,
    SET_USER_SCOPES,
    SET_TOKEN
} from 'store/auth/AuthActionTypes';
import {CLEAR_USER} from 'store/user/UserActionTypes';

export function requestLogin() {
    return {
        type: REQUEST_LOGIN
    };
}

export function closeWelcomeMessage() {
    return {
        type: CLOSE_WELCOME
    };
}

export function setCurrentUser(userId) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_USER,
            userId
        });
    };
}

export function setUserScopes(scopes) {
    return (dispatch) => {
        dispatch({
            type: SET_USER_SCOPES,
            scopes
        });
    };
}

export function setToken(token) {
    if (!token) {
        throw new TypeError('No Token provided');
    }
    SessionStorage.set('token', JSON.stringify(token));

    return (dispatch) => {
        dispatch({
            type: SET_TOKEN,
            token
        });
    };
}

export function clearToken() {
    SessionStorage.remove('token');
    return {
        type: CLEAR_TOKEN
    };
}

export function refreshToken() {
    return {
        type: REFRESH_TOKEN
    };
}

export function refreshingToken(isRefreshing) {
    return {
        type: REFRESHING_TOKEN,
        isRefreshing: isRefreshing
    };
}

export function logout() {
    SessionStorage.clear();

    return (dispatch) => {
        dispatch({
            type: LOGOUT
        });

        window.location.href = RouteUtil.getRoutePath('base.login');
    };
}

export function loginError(e) {
    return (dispatch) => {
        dispatch({
            type: LOGIN_ERROR
        });
        // dispatch(showNotification({
        //     header: 'Error',
        //     variant: 'error',
        //     position: {vertical: 'bottom', horizontal: 'center'},
        //     text: typeof e.message === 'object' && e.message.length >= 0 ? e.message.join('<br/>') : e.message
        // }));
        dispatch({
            type: CLEAR_USER
        });
    };
}

export default null;
