import {diff} from 'deep-object-diff';
import React, {Component, Suspense} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {closeLazyDialog} from 'store/ui/UIActions';
import UISelectors from 'store/ui/UISelectors';


class LazyDialog extends React.PureComponent {

    constructor(props) {
        super(props);
        this.handleOnclose = this.handleOnclose.bind(this);
    }


    handleOnclose() {
        const {component: Component, onClose, onCloseAction, ...rest} = this.props;
        onCloseAction();
        onClose && onClose();
    }

    render() {
        if (!this.props.component) {
            return null;
        }

        const {component: Component, onClose, onCloseAction, ...rest} = this.props;

        return (
            <Suspense fallback={''}>
                <Component
                    {...rest}
                    open={true}
                    onClose={this.handleOnclose}
                />
            </Suspense>
        );
    }

}

LazyDialog.propTypes = {
    component: PropTypes.any,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onCloseAction: PropTypes.func,
    onOpen: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    ...UISelectors.getLazyDialog(state)
});
const mapDispatchToProps = (dispatch) => ({
    onCloseAction: () => dispatch(closeLazyDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(LazyDialog);
