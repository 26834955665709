
export const CLEAR_TESTRESULTS = 'testResults/clear';
export const GET_TESTRESULTS_REQUEST = 'testResults/request';
export const GET_TESTRESULTS_ERROR = 'testResults/error';
export const GET_TESTRESULTS_SUCCESS = 'testResults/success';
export const CLEAR_TESTRESULT = 'testResult/clear';
export const GET_TESTRESULT_REQUEST = 'testResult/get/request';
export const GET_TESTRESULT_ERROR = 'testResult/get/error';
export const GET_TESTRESULT_SUCCESS = 'testResults/get/success';
export const DOWNLOAD_TESTRESULT_REQUEST = 'testResults/download/request';
export const DOWNLOAD_TESTRESULT_ERROR = 'testResults/download/error';
export const DOWNLOAD_TESTRESULT_SUCCESS = 'testResults/download/success';
export const UPDATE_TESTRESULT_REQUEST = 'testResults/update/request';
export const UPDATE_TESTRESULT_ERROR = 'testResults/update/error';
export const UPDATE_TESTRESULT_SUCCESS = 'testResults/update/success';

