import _ from 'lodash';
import moment from 'moment';
import {
    DATE_FORMAT,
    TIME_FORMAT
} from 'common/constants/common';
import {createSelector} from 'reselect';
import {getRootConfigState} from 'store/root/rootSelectors';

const emptyArray = [];
const emptyObject = {};

const getPatientHistoryState = state => _.get(state, 'patient_history', emptyObject);

const getId = (state, id) => id || null;
const getFilter = (state, filter) => filter || null;


export const getRootStatePatientHistory = createSelector(
    getPatientHistoryState,
    patient_history => patient_history || null
);

class PatientHistorySelectorFactory {

    getState = getRootStatePatientHistory;

    getPatientHistory = createSelector(
        [this.getState, getRootConfigState],
        (state, config) => _.chain(state).get('data', emptyObject).values().map(ps => ({
            ...ps,
            user: _.get(config, `users[${ps.user_id}]`, 'N/A'),
            createdAt: moment(ps.createdAt).format(`${DATE_FORMAT} ${TIME_FORMAT}`)
        })).value()
    );

    getPatientFormHistory = createSelector(
        [this.getPatientHistory, getFilter],
        (state, filter) => {
            return _.chain(state)
                .filter(filter)
                .filter(e => e.current_value !== e.previous_value)
                .sortBy(['createdAt'])
                .groupBy('field_name')
                .value();
        }
    );

    getStatus = createSelector(
        this.getState,
        state => _.chain(state).get('status', '').value()
    );

    getError = createSelector(
        this.getState,
        state => _.chain(state).get('error', null).value()
    );

}

export default new PatientHistorySelectorFactory();

