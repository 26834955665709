/**
 * User action definitions
 */
import {
    CLEAR_USERS,
    GET_USERS_REQUEST,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    CLEAR_USER,
    GET_USER_REQUEST,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_ERROR,
    UPDATE_USER_SUCCESS,
    CREATE_USER_REQUEST,
    CREATE_USER_ERROR,
    CREATE_USER_SUCCESS,
    DELETE_USER_REQUEST,
    DELETE_USER_ERROR,
    DELETE_USER_SUCCESS,
    ADD_USER_NOTIFICATION,
    DISMISS_USER_NOTIFICATION,
    SET_USER_RECERT_ERROR,
    SET_USER_RECERT_REQUEST,
    SET_USER_RECERT_SUCCESS,
    SET_USER_ORGANIZATION
} from 'store/user/UserActionTypes';

import {errorNotification} from 'store/ui/UIActions';

const clearUsersAction = () => ({type: CLEAR_USERS});
const clearUserAction = (id) => ({type: CLEAR_USER, id});

const getUsersRequestAction = () => ({type: GET_USERS_REQUEST, status: 'loading'});
const getUsersErrorAction = e => ({type: GET_USERS_ERROR, error: e, status: 'error'});
const getUsersSuccessAction = data => ({type: GET_USERS_SUCCESS, data, status: 'idle'});

const getUserRequestAction = (id) => ({type: GET_USER_REQUEST, id, status: 'loading'});
const getUserErrorAction = (e, id) => ({type: GET_USER_ERROR, error: e, status: 'error', id});
const getUserSuccessAction = (data, id) => ({type: GET_USER_SUCCESS, data, id, status: 'idle'});

const updateUserRequestAction = id => ({type: UPDATE_USER_REQUEST, id, status: 'loading'});
const updateUserErrorAction = (e, id) => ({type: UPDATE_USER_ERROR, error: e, status: 'error', id});
const updateUserSuccessAction = (data, id) => ({type: UPDATE_USER_SUCCESS, data, id, status: 'idle'});

const createUserRequestAction = () => ({type: CREATE_USER_REQUEST, status: 'loading'});
const createUserErrorAction = e => ({type: CREATE_USER_ERROR, error: e, status: 'error'});
const createUserSuccessAction = (data, id) => ({type: CREATE_USER_SUCCESS, data, id, status: 'idle'});

const deleteUserRequestAction = id => ({type: DELETE_USER_REQUEST, id, status: 'loading'});
const deleteUserErrorAction = (e, id) => ({type: DELETE_USER_ERROR, error: e, status: 'error', id});
const deleteUserSuccessAction = id => ({type: DELETE_USER_SUCCESS, id, status: 'idle'});


const setUserRecertifyRequestAction = () => ({type: SET_USER_RECERT_REQUEST, status: 'loading'});
const setUserRecertifyErrorAction = e => ({type: SET_USER_RECERT_ERROR, error: e, status: 'error'});
const setUserRecertifySuccessAction = (data) => ({type: SET_USER_RECERT_SUCCESS, data, status: 'idle'});

const setUserOrganizationAction = (organization, userId) => ({type: SET_USER_ORGANIZATION, organization, userId});

export function addUserNotification(userId, notification) {
    return ({
        type: ADD_USER_NOTIFICATION,
        userId,
        notification
    });
}

export function dismissUserNotification(userId, id) {
    return ({
        type: DISMISS_USER_NOTIFICATION,
        userId,
        id
    });
}


export function getUsersRequest() {
    return dispatch => {
        dispatch(getUsersRequestAction());
    };
}

export function getUsersSuccess(data) {
    return dispatch => {
        dispatch(getUsersSuccessAction(data));
    };
}

export function getUsersError(e) {
    return dispatch => {
        dispatch(clearUsersAction());
        dispatch(getUsersErrorAction(e));
        dispatch(errorNotification(e));
    };
}

export function getUserRequest(id) {
    return dispatch => {
        dispatch(clearUserAction(id));
        dispatch(getUserRequestAction(id));
    };
}

export function getUserSuccess(data, id) {
    return dispatch => {
        dispatch(getUserSuccessAction(data, id));
    };
}

export function getUserError(e, id) {
    return dispatch => {
        dispatch(clearUserAction(id));
        dispatch(getUserErrorAction(e, id));
        dispatch(errorNotification(e));
    };
}

export function saveUserRequest(id) {
    return dispatch => {
        if (id) {
            dispatch(updateUserRequestAction(id));
        }
        else {
            dispatch(createUserRequestAction());
        }
    };
}

export function saveUserError(e, id) {
    return dispatch => {
        if (id) {
            dispatch(updateUserErrorAction(e, id));
        }
        else {
            dispatch(createUserErrorAction(e));
        }
        dispatch(errorNotification(e));
    };
}

export function saveUserSuccess(data, id) {
    return dispatch => {
        if (data) {
            if (id) {
                dispatch(updateUserSuccessAction(data, id));
            }
            else {
                dispatch(createUserSuccessAction(data, id));
            }
        }
    };
}

export function setUserRecertifyRequest() {
    return dispatch => {
        dispatch(setUserRecertifyRequestAction());
    };
}

export function setUserRecertifyError(e) {
    return dispatch => {
        dispatch(setUserRecertifyErrorAction(e));
        dispatch(errorNotification(e));
    };
}

export function setUserRecertifySuccess(data) {
    return dispatch => {
        if (data) {
            dispatch(setUserRecertifySuccessAction(data));
        }
    };
}

export function deleteUserRequest(id) {
    return dispatch => {
        dispatch(deleteUserRequestAction(id));
    };
}

export function deleteUserSuccess(id) {
    return dispatch => {
        dispatch(deleteUserSuccessAction(id));
    };
}

export function deleteUserError(e, id) {
    return dispatch => {
        dispatch(deleteUserErrorAction(e, id));
        dispatch(errorNotification(e));
    };
}

export function setUserOrganization(organization, userId) {
    return dispatch => {
        dispatch(setUserOrganizationAction(organization, userId));
    };
}


export default ({
    getUsersRequest,
    getUsersSuccess,
    getUsersError,
    getUserRequest,
    getUserSuccess,
    getUserError,
    saveUserRequest,
    saveUserError,
    saveUserSuccess,
    deleteUserRequest,
    deleteUserSuccess,
    deleteUserError,
    addUserNotification,
    dismissUserNotification,
    setUserRecertifyRequest,
    setUserRecertifySuccess,
    setUserRecertifyError,
    setUserOrganization
});
