/* eslint no-console: ["error", { allow: ["warn"] }] */
import dot from 'dot-object';
import Config from 'common/Config';
import pathToRegexp from 'path-to-regexp';

export const getRowPath = (route, fullPath) => {
    if (typeof fullPath === 'undefined' || fullPath === null) {
        fullPath = true;
    }
    let result = '';
    if (fullPath) {
        let parts = route.split('.');
        let pathBuilder = [];
        parts.forEach((part) => {
            if (part !== 'path') {
                pathBuilder.push(part);
                let dotPath = pathBuilder.join('.') + '.path';
                if (
                    typeof dot.pick(dotPath, Config.get('routes')) !==
                        'undefined' &&
                    dot.pick(dotPath, Config.get('routes')) !== null
                ) {
                    result += dot.pick(dotPath, Config.get('routes'));
                }
            }
        });
    } else {
        result = dot.pick(
            route.endsWith('path') ? route : route + '.path',
            Config.get('routes')
        );
    }

    return result.replace(/\/\//g, '/');
};

export const getRoutePath = (route, data, fullPath) => {
    const result = getRowPath(route, fullPath);

    return resolveRoute(result, data);
};

export const resolveRoute = (route, data) => {
    if (data) {
        if (typeof data !== 'object') {
            console.warn(
                'Path call to ' + route + ' parameters should be json object'
            );
            return null;
        }

        const resolver = pathToRegexp.compile(route);
        route = resolver(data);
    }

    return route;
};

const RouteUtil = {
    resolveRoute: resolveRoute,
    getRowPath: getRowPath,
    getRoutePath: getRoutePath
};

export default RouteUtil;
