/**
 * lang Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';

import {
    CLEAR_LANGS,
    GET_LANGS_REQUEST,
    GET_LANGS_ERROR,
    GET_LANGS_SUCCESS,
    CLEAR_LANG,
    GET_LANG_REQUEST,
    GET_LANG_ERROR,
    GET_LANG_SUCCESS,
    UPDATE_LANG_REQUEST,
    UPDATE_LANG_ERROR,
    UPDATE_LANG_SUCCESS,
    CREATE_LANG_REQUEST,
    CREATE_LANG_ERROR,
    CREATE_LANG_SUCCESS,
    DELETE_LANG_REQUEST,
    DELETE_LANG_ERROR,
    DELETE_LANG_SUCCESS
} from 'store/lang/LangActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function langs(state = initialState, action) {

    let newState = {};

    switch (action.type) {
        case CLEAR_LANGS: {
            newState = {
                ...initialState
            };
            break;
        }
        case GET_LANGS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_LANGS_SUCCESS: {
            newState = {
                ...initialState,
                status: action.status,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_LANGS_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_LANG: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_LANG_REQUEST:
        case UPDATE_LANG_REQUEST:
        case CREATE_LANG_REQUEST:
        case DELETE_LANG_REQUEST: {
            const newData = {...state.data};
            //Object.assign(newData, {[action.id]: {status: action.status}});
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_LANG_SUCCESS:
        case UPDATE_LANG_SUCCESS:
        case CREATE_LANG_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_LANG_ERROR:
        case UPDATE_LANG_ERROR:
        case CREATE_LANG_ERROR:
        case DELETE_LANG_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_LANG_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
