/**
 * patient Reducer definitions
 */

import {combineReducers} from 'redux';
import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';
import SessionStorage from 'common/SessionStorage';

import {
    SET_PATIENT_FILTERS,
    CLEAR_PATIENTS,
    CLEAR_PATIENTS_PAGES,
    GET_PATIENTS_REQUEST,
    GET_PATIENTS_ERROR,
    GET_PATIENTS_SUCCESS,
    CLEAR_PATIENT,
    GET_PATIENT_REQUEST,
    GET_PATIENT_ERROR,
    GET_PATIENT_SUCCESS,
    UPDATE_PATIENT_REQUEST,
    UPDATE_PATIENT_ERROR,
    UPDATE_PATIENT_SUCCESS,
    CREATE_PATIENT_REQUEST,
    CREATE_PATIENT_ERROR,
    CREATE_PATIENT_SUCCESS,
    DELETE_PATIENT_REQUEST,
    DELETE_PATIENT_ERROR,
    DELETE_PATIENT_SUCCESS,
    PARTIAL_UPDATE_PATIENT_SUCCESS,
    PARTIAL_UPDATE_PATIENT_ERROR
} from 'store/patient/PatientActionTypes';

const defaultPatientFilters = {
    keyword: '',
    filters: {
        patientTypes: [],
        organizations: [],
        locations: []
    }
};
const storedFilters = SessionStorage.get('patientFilter');
const initialState = {
    patientFilters: storedFilters ? JSON.parse(storedFilters) : defaultPatientFilters,
    patients: {
        data: {},
        pagination: {
            pages: {}
        },
        status: 'idle',
        error: null,
        loaded: false
    }
};

function patientFilters(state = initialState.patientFilters, action) {
    switch (action.type) {
        case SET_PATIENT_FILTERS: {
            const newState = {
                ...state,
                ...action.payload,
                filters: {
                    ...state.filters,
                    ...action.payload.filters
                }
            };
            SessionStorage.set('patientFilter', JSON.stringify(newState));
            return newState;
        }
        default: {
            return state;
        }
    }
}

function patients(state = initialState.patients, action) {
    let newState = {};

    switch (action.type) {
        case CLEAR_PATIENTS: {
            newState = {
                ...initialState.patients
            };
            break;
        }
        case CLEAR_PATIENTS_PAGES: {
            newState = {
                ...state,
                pagination: {
                    ...initialState.patients.pagination
                }
            };
            break;
        }
        case GET_PATIENTS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null,
                loaded: false
            };
            break;
        }
        case GET_PATIENTS_SUCCESS: {
            let data;
            let pagination;
            if (action.params.page) {
                data = {
                    ...state.data,
                    ...keyBy(action.data.results, 'id')
                };
                pagination = {
                    ...action.data.pagination,
                    pages: {
                        ...state.pagination.pages,
                        [action.params.page]: action.data.results
                    }
                };
            }
            else {
                pagination = state.pagination;
                data = keyBy(action.data, 'id');
            }
            newState = {
                status: action.status,
                pagination,
                data,
                loaded: true,
                error: null
            };
            break;
        }
        case GET_PATIENTS_ERROR: {
            newState = {
                ...initialState.patients,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_PATIENT: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_PATIENT_REQUEST:
        case UPDATE_PATIENT_REQUEST:
        case CREATE_PATIENT_REQUEST:
        case DELETE_PATIENT_REQUEST: {
            const newData = {...state.data};
            //Object.assign(newData, {[action.id]: {status: action.status}});
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_PATIENT_SUCCESS:
        case UPDATE_PATIENT_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        // Partially update patient data without removing all properties
        case PARTIAL_UPDATE_PATIENT_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: {...newData[action.id], ...action.data}});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_PATIENT_ERROR:
        case UPDATE_PATIENT_ERROR:
        case PARTIAL_UPDATE_PATIENT_ERROR:
        case CREATE_PATIENT_ERROR:
        case DELETE_PATIENT_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_PATIENT_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                pagination: {
                    count: state.pagination.count - 1
                },
                status: action.status,
                error: null
            };
            break;
        }
        case CREATE_PATIENT_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.data.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                pagination: {
                    count: state.pagination.count + 1
                },
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState.patients
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}

export default combineReducers({
    patientFilters,
    patients
});
