import FormHelpers from 'common/helpers/Form';
import RouteUtil from 'common/RouteUtil';
import {push} from 'connected-react-router';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {isDirty, isValid, SubmissionError} from 'redux-form';
import {logout} from 'store/auth/AuthActions';
import AuthSelectors from 'store/auth/AuthSelectors';

import AuthService from 'store/auth/AuthService';
import UserService from 'store/user/UserService';

import {closeAlert, showAlert} from 'store/ui/UIActions';

import MFAForm, {MFA_FORM_NAME} from 'views/pages/MFAForm/MFAForm';

class MFAFormContainer extends PureComponent {
    method

    componentDidMount() {
        if (!this.props.user) {
            UserService.getMe(null, {'x-user-id': this.props.userId});
        }
    }

    handleCancel = async () => {
        this.props.logout();
    };

    handleSendMFACode = async method => {
        this.method = method;
        if (method !== 'authenticator') {
            return await AuthService.sendMFACode({method, userId: this.props.userId});
        }
        return Promise.resolve();
    };

    handleSubmit = async values => {
        const {isFormValid, isFormDirty, scopes, user, goto} = this.props;

        if (!isFormDirty || !isFormValid) {
            return;
        }
        const cleanValues = FormHelpers.clearValues(values);
        try {
            await AuthService.validateMFA({...cleanValues, validationType: this.method});
            const user = await UserService.getMe();

            let redirectUrl = RouteUtil.getRoutePath('base');

            // if (!scopes.includes('isPatient') && user.organizations.length > 1) {
            //     redirectUrl = RouteUtil.getRoutePath('base.mainOrg');
            // }

            goto(redirectUrl);
        }
        catch (e) {
            throw new SubmissionError({
                _error: e.errorDescription || e.message
            });
        }
    };

    render() {
        return (
            <MFAForm
                {...this.props}
                onSubmit={this.handleSubmit}
                handleCancel={this.handleCancel}
                handleSendMFACode={this.handleSendMFACode}
            />
        );
    }
}

MFAFormContainer.propTypes = {
    isFormValid: PropTypes.bool,
    isFormDirty: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.object,
    showAlert: PropTypes.func,
    closeAlert: PropTypes.func,
    user: PropTypes.object,
    userId: PropTypes.string,
    scopes: PropTypes.array,
    t: PropTypes.func,
    goto: PropTypes.func,
    logout: PropTypes.func
};

MFAFormContainer.defaultProps = {};

const mapStateToProps = (state, ownProps) => ({
    userId: AuthSelectors.getCurrentUser(state),
    scopes: AuthSelectors.getScopes(state),
    isFormValid: isValid(MFA_FORM_NAME)(state),
    isFormDirty: isDirty(MFA_FORM_NAME)(state),
    initialValues: {
        userId: AuthSelectors.getCurrentUser(state)
    }
});

const mapDispatchToProps = (dispatch) => ({
    showAlert: (data) => dispatch(showAlert(data)),
    closeAlert: (data) => dispatch(closeAlert(data)),
    logout: () => dispatch(logout()),
    goto: (path) => dispatch(push(path))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MFAFormContainer);
