/**
 * organization Reducer definitions
 */

export const CLEAR_ORGANIZATIONS = 'organizations/clear';
export const GET_ORGANIZATIONS_REQUEST = 'organizations/request';
export const GET_ORGANIZATIONS_ERROR = 'organizations/error';
export const GET_ORGANIZATIONS_SUCCESS = 'organizations/success';
export const CLEAR_ORGANIZATION = 'organization/clear';
export const GET_ORGANIZATION_REQUEST = 'organization/get/request';
export const GET_ORGANIZATION_ERROR = 'organization/get/error';
export const GET_ORGANIZATION_SUCCESS = 'organization/get/success';
export const UPDATE_ORGANIZATION_REQUEST = 'organization/update/request';
export const UPDATE_ORGANIZATION_ERROR = 'organization/update/error';
export const UPDATE_ORGANIZATION_SUCCESS = 'organization/update/success';
export const CREATE_ORGANIZATION_REQUEST = 'organization/create/request';
export const CREATE_ORGANIZATION_ERROR = 'organization/create/error';
export const CREATE_ORGANIZATION_SUCCESS = 'organization/create/success';
export const DELETE_ORGANIZATION_REQUEST = 'organization/delete/request';
export const DELETE_ORGANIZATION_ERROR = 'organization/delete/error';
export const DELETE_ORGANIZATION_SUCCESS = 'organization/delete/success';

export const GET_ORG_QUESTIONNAIRES_REQUEST = 'orgQuestionnaires/get/request';
export const GET_ORG_QUESTIONNAIRES_SUCCESS = 'orgQuestionnaires/get/success';
export const GET_ORG_QUESTIONNAIRES_ERROR = 'orgQuestionnaires/get/error';

export const DELETE_ORGANIZATION_MODULE_ERROR = 'organization/module/delete/error';
export const ADD_ORGANIZATION_MODULE_ERROR = 'organization/module/add/error';
