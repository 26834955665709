import keyBy from 'lodash/keyBy';

import {getNormalizeState, updateNormalizeState} from 'common/helpers/State';

import {LOGOUT} from 'store/auth/AuthActionTypes';
import {
    CLEAR_COGNITIVE_STATUSES_LIST,
    COGNITIVE_STATUSES_LIST_SUCCESS,
    COGNITIVE_STATUS_LOADING,
    COGNITIVE_STATUS_ERROR,
    COGNITIVE_STATUS_SUCCESS,
    COGNITIVE_STATUS_DELETE_SUCCESS
} from 'store/cognitiveStatus/CognitiveStatusActionTypes';

const initialState = {
    data: {
        byId: {},
        list: []
    },
    status: 'idle',
    error: null
};

export default function cognitiveStatus(state = initialState, action) {
    switch (action.type) {
        case CLEAR_COGNITIVE_STATUSES_LIST: {
            return initialState;
        }
        case COGNITIVE_STATUSES_LIST_SUCCESS: {
            return {
                ...initialState,
                status: action.status,
                patientId: action.patientId,
                data: getNormalizeState(action.data, 'id'),
                error: null
            };
        }
        case COGNITIVE_STATUS_LOADING: {
            return {
                ...state,
                status: 'loading',
                error: null
            };
        }
        case COGNITIVE_STATUS_SUCCESS:
        case COGNITIVE_STATUS_DELETE_SUCCESS: {
            return {
                ...state,
                status: 'idle',
                data: updateNormalizeState(state.data, action.id, action.data),
                error: null
            };
        }
        case COGNITIVE_STATUS_ERROR: {
            return {
                ...state,
                status: 'error',
                error: action.error
            };
        }

        case LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
 
