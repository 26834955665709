import {createSelector} from 'reselect';
import _ from 'lodash';
import get from 'lodash/get';
import last from 'lodash/last';

import RouterSelectors from 'store/root/routerSelectors';

import getMenu from 'common/menuUtils';

const emptyArray = [];
const emptyObject = {};

const getUIState = (state) => get(state, 'ui', null);
const getUIMenuState = (state) => get(state, 'ui.menu', []);
const getUISubMenuState = (state) => get(state, 'ui.submenu', []);

const getRootStateUI = createSelector(getUIState, (ui) => ui || emptyObject);

let prevMenu; let prevLocation;
const UISelectorFactory = (getUI) => ({
    getUI,
    getTheme: createSelector(getUI, (ui) => get(ui, 'theme', 'light')),
    getUIMenu: createSelector(
        [getUIMenuState, RouterSelectors.getLocation],
        (menu, location) => getMenu(menu, location)),
    getUISubMenu: createSelector(
        [getUISubMenuState, RouterSelectors.getLocation],
        (menu, location) => getMenu(menu, location)),
    getHasNotification: createSelector(getUI, (ui) =>
        Boolean(Object.keys(get(ui, 'notifications', emptyObject)).length)
    ),
    getNotifications: createSelector(getUI, (ui) =>
        get(ui, 'notifications', emptyObject)
    ),
    getHeaderHeight: createSelector(getUI, (ui) => get(ui, 'sticky.headerHeight', emptyObject)),
    getTotalHeight: createSelector(getUI, (ui) => 
        _.chain(ui)
            .get('sticky', emptyObject)
            .values()
            .reduce((acc, item) => acc + item, 0)
            .value()
    ),
    getWizard: createSelector(getUI, (ui) => get(ui, 'wizard', emptyObject)),
    getWizardStep: createSelector(getUI, (ui) =>
        get(ui, 'wizard.currentStep', 0)
    ),
    getWizardData: createSelector(getUI, (ui) => get(ui, 'wizard.data', 0)),
    getHasAlert: createSelector(getUI, (ui) =>
        Boolean(get(ui, 'alert', false))
    ),
    getAlert: createSelector(getUI, (ui) => get(ui, 'alert', null)),
    getLazyDialog: createSelector(getUI, (ui) => get(ui, 'dialog', null)),
    getLoading: createSelector(getUI, (ui) =>
        get(last(get(ui, 'loading', emptyArray)) || emptyArray, 'message', null)
    )
});

export default UISelectorFactory(getRootStateUI);
