import dot from 'dot-object';
import deepmerge from 'deepmerge';

const getEnv = () => process.env.REACT_APP_ENV;
let envConfig = [];

if (process.env.REACT_APP_ENV !== 'production') {
    envConfig = importAll(require.context(`../config/${process.env.REACT_APP_ENV}/`, false, /\.(js|json)$/), false);
}
const config = importAll(require.context('../config/', false, /\.(js|json)$/), true);

function importAll(r, merge) {
    let files = [];
    r.keys().map((item, index) => {
        const filename = item.replace('./', '');
        const name = filename.split('.').shift();
        files[name] = r(item);
        if (typeof files[name].default !== 'undefined') {
            files[name] = files[name].default; // es6 fix
        }
        if (getEnv() !== 'production' && merge) {
            if (typeof envConfig[name] !== 'undefined') {
                files[name] = deepmerge(files[name], envConfig[name]);
            }
        }
    });
    return files;
}

export default ({
    get: (param, defaultValue) => {
        const value = dot.pick(param, config);
        if (typeof value === 'undefined' || value === null) {
            return defaultValue;
        }
        return value;
    },
    has: (param) => {
        const value = dot.pick(param, config);
        return !(typeof value === 'undefined' || value === null);
    }
});
