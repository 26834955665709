import Fade from '@material-ui/core/Fade';
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import HistoryPreloading from 'media/history-preloading.gif';
import {connect} from 'react-redux';
import AuthSelectors from 'store/auth/AuthSelectors';

const HistoryIndicator = (props) => {
    const {theme, classes, status, isPatient} = props;
    if (isPatient) {
        return null;
    }
    return (
        <Fade in={status === 'loading'}>
            <div className={classes.root}>
                <div style={{width: theme.spacing(4)}}>
                    <img src={HistoryPreloading} style={{maxWidth: '100%'}}/>
                </div>
            </div>
        </Fade>
    );
};

HistoryIndicator.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    status: PropTypes.string,
    isPatient: PropTypes.bool
};
const styles = theme => ({
    root: {
        position: 'absolute',
        right: theme.spacing(7.5),
        bottom: theme.spacing(),
        zIndex: theme.zIndex.tooltip + 1
    }
});

export default connect(state => ({
    isPatient: AuthSelectors.getIsPatient(state)
}))(withStyles(styles, {withTheme: true})(HistoryIndicator));
