/**
 * permission Reducer definitions
 */

export const CLEAR_PERMISSIONS = 'permissions/clear';
export const GET_PERMISSIONS_REQUEST = 'permissions/request';
export const GET_PERMISSIONS_ERROR = 'permissions/error';
export const GET_PERMISSIONS_SUCCESS = 'permissions/success';
export const CLEAR_PERMISSION = 'permission/clear';
export const GET_PERMISSION_REQUEST = 'permission/get/request';
export const GET_PERMISSION_ERROR = 'permission/get/error';
export const GET_PERMISSION_SUCCESS = 'permission/get/success';
export const UPDATE_PERMISSION_REQUEST = 'permission/update/request';
export const UPDATE_PERMISSION_ERROR = 'permission/update/error';
export const UPDATE_PERMISSION_SUCCESS = 'permission/update/success';
export const CREATE_PERMISSION_REQUEST = 'permission/create/request';
export const CREATE_PERMISSION_ERROR = 'permission/create/error';
export const CREATE_PERMISSION_SUCCESS = 'permission/create/success';
export const DELETE_PERMISSION_REQUEST = 'permission/delete/request';
export const DELETE_PERMISSION_ERROR = 'permission/delete/error';
export const DELETE_PERMISSION_SUCCESS = 'permission/delete/success';

