/**
 * test results Reducer definitions
 */

import keyBy from 'lodash/keyBy';
import {LOGOUT} from 'store/auth/AuthActionTypes';

import {
    CLEAR_TESTRESULT,
    CLEAR_TESTRESULTS,
    DOWNLOAD_TESTRESULT_ERROR,
    DOWNLOAD_TESTRESULT_REQUEST,
    DOWNLOAD_TESTRESULT_SUCCESS,
    GET_TESTRESULT_ERROR,
    GET_TESTRESULT_REQUEST,
    GET_TESTRESULT_SUCCESS,
    GET_TESTRESULTS_ERROR,
    GET_TESTRESULTS_REQUEST,
    GET_TESTRESULTS_SUCCESS,
    UPDATE_TESTRESULT_ERROR,
    UPDATE_TESTRESULT_REQUEST,
    UPDATE_TESTRESULT_SUCCESS
} from 'store/testResults/TestResultActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function testResults(state = initialState, action) {
    let newState = {};

    switch (action.type) {
        case CLEAR_TESTRESULTS: {
            newState = {
                ...initialState
            };
            break;
        }

        case GET_TESTRESULTS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_TESTRESULTS_SUCCESS: {
            newState = {
                ...initialState,
                status: action.status,
                patientId: action.patientId,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_TESTRESULTS_ERROR : {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_TESTRESULT: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }

        case GET_TESTRESULT_REQUEST:
        case UPDATE_TESTRESULT_REQUEST:
        case DOWNLOAD_TESTRESULT_REQUEST: {
            const newData = {...state.data};
            //Object.assign(newData, {[action.id]: {status: action.status}});
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case UPDATE_TESTRESULT_SUCCESS:
        case GET_TESTRESULT_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_TESTRESULT_ERROR:
        case UPDATE_TESTRESULT_ERROR:
        case DOWNLOAD_TESTRESULT_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DOWNLOAD_TESTRESULT_SUCCESS: {
            const newData = {};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                downloadDoc: {...newData},
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
