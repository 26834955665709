/**
 * role Reducer definitions
 */

export const CLEAR_ROLES = 'roles/clear';
export const GET_ROLES_REQUEST = 'roles/request';
export const GET_ROLES_ERROR = 'roles/error';
export const GET_ROLES_SUCCESS = 'roles/success';
export const CLEAR_ROLE = 'role/clear';
export const GET_ROLE_REQUEST = 'role/get/request';
export const GET_ROLE_ERROR = 'role/get/error';
export const GET_ROLE_SUCCESS = 'role/get/success';
export const UPDATE_ROLE_REQUEST = 'role/update/request';
export const UPDATE_ROLE_ERROR = 'role/update/error';
export const UPDATE_ROLE_SUCCESS = 'role/update/success';
export const CREATE_ROLE_REQUEST = 'role/create/request';
export const CREATE_ROLE_ERROR = 'role/create/error';
export const CREATE_ROLE_SUCCESS = 'role/create/success';
export const DELETE_ROLE_REQUEST = 'role/delete/request';
export const DELETE_ROLE_ERROR = 'role/delete/error';
export const DELETE_ROLE_SUCCESS = 'role/delete/success';

