import {CssBaseline, MuiThemeProvider} from '@material-ui/core';
import API from 'common/API';
import Config from 'common/Config';
import themes from 'common/themes';
import {ConnectedRouter} from 'connected-react-router';
import i18next from 'core/i18next';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect, Provider} from 'react-redux';
import ErrorBoundary from 'views/components/ErrorHandler/ErrorBoundary';
import store, {history} from 'store';
import {getLangsSuccess} from 'store/lang/LangActions';
import UISelectors from 'store/ui/UISelectors';
import Preloader from 'views/components/Preloader';
import Main from 'views/pages/Main';

const loadLanguages = async () => {
    let languages;
    try {
        const endpoint = API.resolveRoute('language', 'public');
        languages = await API.httprequest(endpoint.url, {}, endpoint.method, true);
    }
    catch (e) {
        process.env.STAGE !== 'production' && console.log(e);
        languages = [{
            'name': 'English',
            'slug': 'english',
            'original': 'English',
            'shortname': 'en',
            'country': 'us',
            'language': 'en',
            'locale': 'en-us'
        }];
    }
    finally {
        store.dispatch(getLangsSuccess(languages.map(lng => ({...lng, id: lng.slug}))));
    }
    return languages;
};

function App(props) {
    return (
        <Provider store={store}>
            <ErrorBoundary>
                <ConnectedRouter history={history}>
                    <ConnectedSubmodules/>
                </ConnectedRouter>
            </ErrorBoundary>
        </Provider>
    );
}

const SubModules = (props) => {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        loadLanguages().then(langs => {
            if (!find(langs, {shortName: i18next.languages[0]})) {
                i18next.changeLanguage(i18next.options.fallbackLng[0]).catch(e => {
                });
            }
        }).catch(e => {
            process.env.STAGE !== 'production' && console.log(e);
            throw e;
        });
        setReady(true);
    }, []);

    document.title = Config.get('app.title');
    document.body.classList.remove('theme-light');
    document.body.classList.remove('theme-dark');
    document.body.classList.add(`theme-${props.theme}`);
    document.body.classList.add('canScroll');
    return ready && (
        <MuiThemeProvider theme={themes(props.theme)}>
            <CssBaseline/>
            <ErrorBoundary>
                <React.Suspense fallback={<Preloader/>}>
                    <Main {...props}/>
                </React.Suspense>
            </ErrorBoundary>
        </MuiThemeProvider>
    );
};

SubModules.propTypes = {
    theme: PropTypes.string
};

const ConnectedSubmodules = connect((state) => ({
    theme: UISelectors.getTheme(state)
}))(SubModules);

export default App;
