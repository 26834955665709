/**
 * Translation Reducer definitions
 */

export const CLEAR_TRANSLATIONS = 'translations/clear';
export const GET_TRANSLATIONS_REQUEST = 'translations/request';
export const GET_TRANSLATIONS_ERROR = 'translations/error';
export const GET_TRANSLATIONS_SUCCESS = 'translations/success';
export const CLEAR_TRANSLATION = 'translation/clear';
export const GET_TRANSLATION_REQUEST = 'translation/get/request';
export const GET_TRANSLATION_ERROR = 'translation/get/error';
export const GET_TRANSLATION_SUCCESS = 'translation/get/success';
export const UPDATE_TRANSLATION_REQUEST = 'translation/update/request';
export const UPDATE_TRANSLATION_ERROR = 'translation/update/error';
export const UPDATE_TRANSLATION_SUCCESS = 'translation/update/success';
export const CREATE_TRANSLATION_REQUEST = 'translation/create/request';
export const CREATE_TRANSLATION_ERROR = 'translation/create/error';
export const CREATE_TRANSLATION_SUCCESS = 'translation/create/success';
export const DELETE_TRANSLATION_REQUEST = 'translation/delete/request';
export const DELETE_TRANSLATION_ERROR = 'translation/delete/error';
export const DELETE_TRANSLATION_SUCCESS = 'translation/delete/success';

