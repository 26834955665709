import padStart from 'lodash/padStart';
import moment from 'moment';
import {
    DATE_FORMAT,
    TIME_FORMAT
} from 'common/constants/common';


export const getDate = (UTCDate) => UTCDate ? moment(UTCDate).format(DATE_FORMAT) : '-';
export const getTime = (UTCDate) => UTCDate ? moment(UTCDate).format(TIME_FORMAT) : '-';


export const convertDate = (dateFormat) => {
    const pad = (s) => {
        return (s < 10) ? '0' + s : s;
    };
    let d = new Date(dateFormat);
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');
};

export const timeLeft = (seconds) => {
    const timeLeft = {
        hr: Math.floor(seconds / 3600),
        sec: (seconds % 60)
    };
    Object.assign(timeLeft, {min: Math.floor((seconds / 60) - (timeLeft.hr * 60))});
    return [
        timeLeft.hr && timeLeft.hr > 0 && padStart(timeLeft.hr, 2, '0'),
        timeLeft.min && padStart(timeLeft.min, 2, '0'),
        padStart(timeLeft.sec, 2, '0')
    ].filter(Boolean).join(':');
};
