/**
 * role Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';

import {
    CLEAR_PATIENT_HISTORY,
    GET_PATIENT_HISTORY_REQUEST,
    GET_PATIENT_HISTORY_ERROR,
    GET_PATIENT_HISTORY_SUCCESS
} from 'store/patient_history/PatientHistoryActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function patient_history(state = initialState, action) {

    let newState = {};

    switch (action.type) {
        case CLEAR_PATIENT_HISTORY: {
            newState = {
                ...initialState
            };
            break;
        }
        case GET_PATIENT_HISTORY_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_PATIENT_HISTORY_SUCCESS: {
            newState = {
                ...initialState,
                status: action.status,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_PATIENT_HISTORY_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
