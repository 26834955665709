/**
 * location Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';

import {
    CLEAR_LOCATIONS,
    GET_LOCATIONS_REQUEST,
    GET_LOCATIONS_ERROR,
    GET_LOCATIONS_SUCCESS,
    CLEAR_LOCATION,
    GET_LOCATION_REQUEST,
    GET_LOCATION_ERROR,
    GET_LOCATION_SUCCESS,
    UPDATE_LOCATION_REQUEST,
    UPDATE_LOCATION_ERROR,
    UPDATE_LOCATION_SUCCESS,
    CREATE_LOCATION_REQUEST,
    CREATE_LOCATION_ERROR,
    CREATE_LOCATION_SUCCESS,
    DELETE_LOCATION_REQUEST,
    DELETE_LOCATION_ERROR,
    DELETE_LOCATION_SUCCESS
} from 'store/location/LocationActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function locations(state = initialState, action) {
    let newState = {};

    switch (action.type) {
        case CLEAR_LOCATIONS: {
            newState = {
                ...initialState
            };
            break;
        }
        case GET_LOCATIONS_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_LOCATIONS_SUCCESS: {
            newState = {
                ...initialState,
                status: action.status,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_LOCATIONS_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_LOCATION: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_LOCATION_REQUEST:
        case UPDATE_LOCATION_REQUEST:
        case CREATE_LOCATION_REQUEST:
        case DELETE_LOCATION_REQUEST: {
            const newData = {...state.data};
            //Object.assign(newData, {[action.id]: {status: action.status}});
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_LOCATION_SUCCESS:
        case UPDATE_LOCATION_SUCCESS:
        case CREATE_LOCATION_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_LOCATION_ERROR:
        case UPDATE_LOCATION_ERROR:
        case CREATE_LOCATION_ERROR:
        case DELETE_LOCATION_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_LOCATION_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
