/**
 * module Reducer definitions
 */

import {LOGOUT} from 'store/auth/AuthActionTypes';
import keyBy from 'lodash/keyBy';

import {
    CLEAR_MODULES,
    GET_MODULES_REQUEST,
    GET_MODULES_ERROR,
    GET_MODULES_SUCCESS,
    CLEAR_MODULE,
    GET_MODULE_REQUEST,
    GET_MODULE_ERROR,
    GET_MODULE_SUCCESS,
    UPDATE_MODULE_REQUEST,
    UPDATE_MODULE_ERROR,
    UPDATE_MODULE_SUCCESS,
    CREATE_MODULE_REQUEST,
    CREATE_MODULE_ERROR,
    CREATE_MODULE_SUCCESS,
    DELETE_MODULE_REQUEST,
    DELETE_MODULE_ERROR,
    DELETE_MODULE_SUCCESS
} from 'store/module/ModuleActionTypes';

const initialState = {
    data: {},
    status: 'idle',
    error: null
};

export default function modules(state = initialState, action) {

    let newState = {};

    switch (action.type) {
        case CLEAR_MODULES: {
            newState = {
                ...initialState
            };
            break;
        }
        case GET_MODULES_REQUEST: {
            newState = {
                ...state,
                status: action.status,
                error: null
            };
            break;
        }
        case GET_MODULES_SUCCESS: {
            newState = {
                ...initialState,
                status: action.status,
                data: {
                    ...keyBy(action.data, 'id')
                },
                error: null
            };
            break;
        }
        case GET_MODULES_ERROR: {
            newState = {
                ...initialState,
                error: action.error,
                status: action.status
            };
            break;
        }
        case CLEAR_MODULE: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: 'idle',
                error: null
            };
            break;
        }
        case GET_MODULE_REQUEST:
        case UPDATE_MODULE_REQUEST:
        case CREATE_MODULE_REQUEST:
        case DELETE_MODULE_REQUEST: {
            const newData = {...state.data};
            //Object.assign(newData, {[action.id]: {status: action.status}});
            newState = {
                ...state,
                // TODO we may need to add individual loading states here
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case GET_MODULE_SUCCESS:
        case UPDATE_MODULE_SUCCESS:
        case CREATE_MODULE_SUCCESS: {
            const newData = {...state.data};
            Object.assign(newData, {[action.id]: action.data});
            newState = {
                ...state,
                status: action.status,
                data: {...newData},
                error: null
            };
            break;
        }
        case GET_MODULE_ERROR:
        case UPDATE_MODULE_ERROR:
        case CREATE_MODULE_ERROR:
        case DELETE_MODULE_ERROR: {
            newState = {
                ...state,
                status: action.status,
                error: action.error
            };
            break;
        }
        case DELETE_MODULE_SUCCESS: {
            const {[action.id]: removed, ...newData} = state.data;
            newState = {
                ...state,
                data: {...newData},
                status: action.status,
                error: null
            };
            break;
        }
        case LOGOUT:
            newState = {
                ...initialState
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}
