import {AlertDecagram, AlertOctagonOutline, ExclamationThick} from 'mdi-material-ui';
import React from 'react';
import * as PropTypes from 'prop-types';
import {Typography, withStyles} from '@material-ui/core';
import classNames from 'classnames';
import Config from 'common/Config';
import {useTranslation} from 'react-i18next';

const NotAuthorized = ({theme, classes, ...props}) => {
    const {t} = useTranslation('not-authorized');
    return (
        <div className={classes.root}>
            <AlertOctagonOutline style={{fontSize: theme.typography.fontSize * 6}}/>
            <Typography gutterBottom variant={'h4'}>{t('title')}</Typography>
            <Typography variant={'body2'}>{t('body1')}</Typography>
            <Typography variant={'body2'}>{t('body2', {appt_title: Config.get('app.title')})}</Typography>
        </div>
    );
};

NotAuthorized.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object

};
const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        height: '100%'
    }
});
export default withStyles(styles, {withTheme: true})(NotAuthorized);
