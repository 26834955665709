import keyBy from 'lodash/keyBy';
import findIndex from 'lodash/findIndex';

/**
 * Normalizes list data
 * @param data - list to normalize
 * @param key - key name
 * @returns {{byId: Object, list: Array}}
 */
export const getNormalizeState = (data, key) => {
    return {
        byId: keyBy(data, key),
        list: data
    };
};

/**
 * Updates or deletes normalized data
 * @param state - previous state
 * @param id - id to update
 * @param data - new data
 * @param idKeyName - key name, if you need to find an object by a different key than "id"
 * @returns {{byId: Object, list: Array}}
 */
export const updateNormalizeState = (state = {}, id, data, idKeyName = 'id') => {
    const list = [...state.list || []];
    const byId = {...state.byId || {}};
    const index = findIndex(list, {[idKeyName]: id});
    if (index !== -1) {
        if (data) {
            list.splice(index, 1, data);
        } else {
            list.splice(index, 1);
        }
    } else {
        list.push(data);
    }

    if (data) {
        byId[id] = data;
    } else {
        delete byId[id];
    }
    return {
        byId,
        list
    };
};
