/**
 * medication Reducer definitions
 */

export const CLEAR_MEDICATIONS = 'medications/clear';
export const GET_MEDICATIONS_REQUEST = 'medications/request';
export const GET_MEDICATIONS_ERROR = 'medications/error';
export const GET_MEDICATIONS_SUCCESS = 'medications/success';
export const CLEAR_MEDICATION = 'medication/clear';
export const GET_MEDICATION_REQUEST = 'medication/get/request';
export const GET_MEDICATION_ERROR = 'medication/get/error';
export const GET_MEDICATION_SUCCESS = 'medication/get/success';
export const UPDATE_MEDICATION_REQUEST = 'medication/update/request';
export const UPDATE_MEDICATION_ERROR = 'medication/update/error';
export const UPDATE_MEDICATION_SUCCESS = 'medication/update/success';
export const CREATE_MEDICATION_REQUEST = 'medication/create/request';
export const CREATE_MEDICATION_ERROR = 'medication/create/error';
export const CREATE_MEDICATION_SUCCESS = 'medication/create/success';
export const DELETE_MEDICATION_REQUEST = 'medication/delete/request';
export const DELETE_MEDICATION_ERROR = 'medication/delete/error';
export const DELETE_MEDICATION_SUCCESS = 'medication/delete/success';

