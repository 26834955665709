import Config from 'common/Config';
import Events from 'common/helpers/Events';
import SessionStorage from 'common/SessionStorage';
import debounce from 'lodash/debounce';
import moment from 'moment';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import ScreenWarn from 'views/components/ScreenLock/ScreenWarn';
import AuthSelectors from 'store/auth/AuthSelectors';
import {logout} from 'store/auth/AuthActions';
import AuthService from 'store/auth/AuthService';
import OrganizationSelectors from 'store/organization/OrganizationSelectors';
import UserSelectors from 'store/user/UserSelectors';
import ScreenLock from 'views/components/ScreenLock/ScreenLock';

// WebWorker
import worker from 'workerize-loader?inline!./TimerWorker.js';

class ScreenLockContainer extends PureComponent {
    method
    state = {
        screen: 0,
        lock: false,
        logout: 0
    };

    timerWebWorker = null;

    constructor(props) {
        super(props);

        this.state.lock = SessionStorage.get('lock') === 'true' || false;
        this.state.logout = SessionStorage.has('logout') ? moment(SessionStorage.get('logout')).utc().diff(moment().utc(), 'seconds') : 0;
    }

    componentDidMount() {
        const _self = this;

        this.timerWebWorker = worker();

        Events.on(document, 'mousemove keydown keyup', debounce(() => {
            _self.timerWebWorker.reset();
        }, 300, {
            leading: true,
            trailing: false,
            maxWait: 1000
        }));

        if (!this.state.lock) {
            this.setScreenCounter();
        }
        else {
            // encrypted token does not have refresh_token. in that case,
            // token stored in sessionStorage is already encrypted.
            if (this.props.refresh_token) {
                AuthService.lockScreen().then(() => {
                    this.setLogoutCounter();
                });
            }
            else {
                this.setLogoutCounter();
            }
        }
    }

    componentWillUnmount() {
        this.timerWebWorker.terminate();
        Events.off(document, 'mousemove keydown keyup');
    }

    setScreenCounter = () => {

        this.timerWebWorker.onerror = (e) => {
            console.log('Worker Error', e);
        };

        this.timerWebWorker.start(this.props.session_timeout);

        this.timerWebWorker.onmessage = e => {
            if (isNumber(e.data)) {
                const timeLeft = parseInt(e.data);
                this.setState({
                    screen: timeLeft
                }, () => {
                    if (this.state.screen <= 0) {
                        this.timerWebWorker.stop();
                        SessionStorage.set('lock', true);
                        SessionStorage.set('logout', moment().add(this.props.logout_timeout, 'seconds').utc().toISOString());
                        AuthService.lockScreen()
                            .then(() => {
                                this.setState({
                                    screen: 0,
                                    lock: true,
                                    logout: this.props.logout_timeout
                                });
                                this.setLogoutCounter();
                            });
                    }
                });
            }
        };
    };

    setLogoutCounter = () => {
        const _self = this;

        Events.off(document, 'mousemove keydown keyup');

        this.timerWebWorker.start(this.state.logout);

        this.timerWebWorker.onerror = (e) => {
            console.log('Worker Error', e);
        };

        this.timerWebWorker.onmessage = e => {
            if (isNumber(e.data)) {
                const timeLeft = parseInt(e.data);
                _self.setState({
                    logout: timeLeft
                }, () => {
                    if (timeLeft <= 0) {
                        SessionStorage.set('logout', null);
                        SessionStorage.set('lock', false);
                        _self.props.logout();
                    }
                });
            }
        };
    };

    handleUnlockScreen = async (values) => {
        try {
            const response = await AuthService.unlockScreen({
                ...values,
                userId: this.props.user.id,
                validationType: this.method
            });

            this.setState({
                lock: false,
                logout: 0
            }, () => {
                SessionStorage.remove('logout');
                SessionStorage.remove('lock');
                AuthService.loginSuccessAction(response);
            });
        }
        catch (e) {
            throw new SubmissionError({code: e.message});
        }
    };

    handleSendMFACode = (method) => {
        this.method = method;
        if (method !== 'authenticator') {
            return AuthService.sendMFACode({method, userId: this.props.user?.id});
        }
    };

    render() {
        if (this.state.lock) {
            return (
                <ScreenLock
                    user={this.props.user}
                    logoutTimeout={this.props.logout_timeout}
                    logoutTimeLeft={this.state.logout}
                    onSubmit={this.handleUnlockScreen}
                    onClickChangeMethod={this.handleSendMFACode}
                    {...this.state}
                />
            );
        }
        return (
            <ScreenWarn
                screenTimeout={this.props.session_timeout}
                screentimeLeft={this.state.screen}
            />
        );
    }
}

ScreenLockContainer.propTypes = {
    user: PropTypes.object,
    session_timeout: PropTypes.number,
    logout_timeout: PropTypes.number,
    logout: PropTypes.func,
    refresh_token: PropTypes.string
};

ScreenLockContainer.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
    const user = UserSelectors.getUser(state);
    const org = UserSelectors.getMyOrganization(state);
    return ({
        refresh_token: AuthSelectors.getRefreshToken(state),
        user,
        userId: AuthSelectors.getCurrentUser(state),
        session_timeout: get(org, 'preferences.session_inactivity_timeout', 15) * 60, // in seconds
        logout_timeout: get(org, 'preferences.session_logout_timeout', 30) * 60 // in seconds
    });
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenLockContainer);
