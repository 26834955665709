/**
 * location Reducer definitions
 */

export const CLEAR_LOCATIONS = 'locations/clear';
export const GET_LOCATIONS_REQUEST = 'locations/request';
export const GET_LOCATIONS_ERROR = 'locations/error';
export const GET_LOCATIONS_SUCCESS = 'locations/success';
export const CLEAR_LOCATION = 'location/clear';
export const GET_LOCATION_REQUEST = 'location/get/request';
export const GET_LOCATION_ERROR = 'location/get/error';
export const GET_LOCATION_SUCCESS = 'location/get/success';
export const UPDATE_LOCATION_REQUEST = 'location/update/request';
export const UPDATE_LOCATION_ERROR = 'location/update/error';
export const UPDATE_LOCATION_SUCCESS = 'location/update/success';
export const CREATE_LOCATION_REQUEST = 'location/create/request';
export const CREATE_LOCATION_ERROR = 'location/create/error';
export const CREATE_LOCATION_SUCCESS = 'location/create/success';
export const DELETE_LOCATION_REQUEST = 'location/delete/request';
export const DELETE_LOCATION_ERROR = 'location/delete/error';
export const DELETE_LOCATION_SUCCESS = 'location/delete/success';
