import _ from 'lodash';
import {configureStore, combineReducers} from '@reduxjs/toolkit';

import Config from 'common/Config';

import initialReducers from 'store/root/rootReducers';

export function createReducerManager(initialReducers) {
    // Create an object which maps keys to reducers
    const reducers = {...initialReducers};
    const newReducers = {};
    // Create the initial combinedReducer
    let combinedReducer = combineReducers(reducers);

    // An array which is used to delete state keys when reducers are removed
    let keysToRemove = [];

    const treeReducers = {};

    return {
        getReducerMap: () => reducers,
        // The root reducer function exposed by this object
        // This will be passed to the store
        reduce: (state, action) => {
            // If any reducers have been removed, clean up their state first
            if (keysToRemove.length > 0) {
                state = {...state};
                for (const key of keysToRemove) {
                    _.unset(state, key);
                }
                keysToRemove = [];
            }

            // Delegate to the combined reducer
            return combinedReducer({...state}, action);
        },

        // Adds a new reducer with the specified key
        add: (key, reducer) => {

            if (!key || reducers[key]) {
                return;
            }
            if (!key.includes('.')) {
                // Add the reducer to the reducer mapping
                if (typeof reducer === 'function' && reducer.name === 'reducer') {
                    _.set(newReducers, key, {default: reducer});
                    reducers[key] = reducer;
                }
                else {
                    _.set(newReducers, key, reducer);
                    reducers[key] = combineReducers(reducer);
                }
            }
            else {
                _.set(newReducers, key, reducer);
                const keys = _.chain(key).split('.').value();
                const newKey = keys.pop();
                const deepKey = keys.join('.');
                reducers[deepKey] = combineReducers(newReducers[deepKey]);
            }
            // Generate a new combined reducer
            combinedReducer = combineReducers(reducers);
            // console.log(reducers);
        },
        // Removes a reducer with the specified key
        remove: key => {
            if (key.includes('.')) {
                const keys = _.chain(key).split('.').value();
                const newKey = keys.pop();
                const deepKey = keys.join('.');
                _.unset(newReducers, key);
                keysToRemove.push(key);
                reducers[deepKey] = combineReducers(newReducers[deepKey]);
            }
            else {
                if (!key || !reducers[key]) {
                    return;
                }

                // Remove it from the reducer mapping
                delete reducers[key];

                // Add the key to the list of keys to clean up
                keysToRemove.push(key);
            }
            // Generate a new combined reducer
            combinedReducer = combineReducers(reducers);
        },
        has: key => {
            return key && _.get(reducers, key);
        }
    };
}

export default (history, enhancers, middleware, initialState) => {
    const reducerManager = createReducerManager(initialReducers(history));
    const store = configureStore({
        reducer: reducerManager.reduce,
        devTools: process.env.REACT_APP_ENV === 'development' || (process.env.REACT_APP_ENV === 'local' && Config.get('app.debug', false) === true),
        enhancers,
        middleware,
        preloadedState: initialState
    });
    store.reducerManager = reducerManager;
    return store;
};
