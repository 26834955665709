/**
 * module Reducer definitions
 */

export const CLEAR_MODULES = 'modules/clear';
export const GET_MODULES_REQUEST = 'modules/request';
export const GET_MODULES_ERROR = 'modules/error';
export const GET_MODULES_SUCCESS = 'modules/success';
export const CLEAR_MODULE = 'module/clear';
export const GET_MODULE_REQUEST = 'module/get/request';
export const GET_MODULE_ERROR = 'module/get/error';
export const GET_MODULE_SUCCESS = 'module/get/success';
export const UPDATE_MODULE_REQUEST = 'module/update/request';
export const UPDATE_MODULE_ERROR = 'module/update/error';
export const UPDATE_MODULE_SUCCESS = 'module/update/success';
export const CREATE_MODULE_REQUEST = 'module/create/request';
export const CREATE_MODULE_ERROR = 'module/create/error';
export const CREATE_MODULE_SUCCESS = 'module/create/success';
export const DELETE_MODULE_REQUEST = 'module/delete/request';
export const DELETE_MODULE_ERROR = 'module/delete/error';
export const DELETE_MODULE_SUCCESS = 'module/delete/success';

