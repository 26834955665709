import SessionStorage from 'common/SessionStorage';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import ErrorBoundary from 'views/components/ErrorHandler/ErrorBoundary';

// Services
import AuthService from 'store/auth/AuthService';
import PatientHistorySelectors from 'store/patient_history/PatientHistorySelectors';

// Selectors
import RouterSelectors from 'store/root/routerSelectors';

// Actions
import {loading, toggleTheme} from 'store/ui/UIActions';
import UISelectors from 'store/ui/UISelectors';

// Components
import Alert from 'views/components/Alert';
import HistoryIndicator from 'views/components/HistoryIndicator';
import LazyDialog from 'views/components/LazyDialog';
import Loading from 'views/components/Loading';
import Notification from 'views/components/NotificationContainer';
import PreferencesMenu from 'views/components/PreferencesMenu';
import Base from 'views/pages/Base';

class Main extends React.Component {
    state = {
        ready: false
    };

    constructor(props) {
        super(props);
        this.toggleTheme = this.toggleTheme.bind(this);
        this.timer = null;
    }


    async componentDidMount() {

        const {loading, isTestAutomation} = this.props;

        if (isTestAutomation) {
            document.body.classList.add('nonBlockingBackdrop');
        }

        try {
            this.setState({ready: false}, async () => {
                loading({
                    type: 'application-loading',
                    message: 'messages:application-loading'
                });

                if (SessionStorage.has('token')) {
                    const token = JSON.parse(SessionStorage.get('token'));
                    await AuthService.saveToken(token);
                    this.setState({ready: true});
                }
            });
        }
        finally {
            this.timer = setTimeout(() => {
                this.setState({ready: true}, () => {
                    this.props.loading('application-loading');
                });
            }, 1500);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    toggleTheme = () => {
        this.props.toggleTheme(this.props.theme);
    };

    render() {
        return (
            <React.Fragment>
                <ErrorBoundary>
                    {this.state.ready && <Base routeKey={this.props.routeState.key}/>}
                </ErrorBoundary>
                <Alert/>
                <Loading/>
                <Notification/>
                <ErrorBoundary>
                    <LazyDialog/>
                </ErrorBoundary>
                <HistoryIndicator status={this.props.historyState}/>
                <PreferencesMenu onClickToggleTheme={this.toggleTheme}/>
            </React.Fragment>
        );
    }
}

Main.defaultProps = {
    theme: 'light'
};
Main.propTypes = {
    loading: PropTypes.func,
    theme: PropTypes.string,
    routeState: PropTypes.object,
    toggleTheme: PropTypes.func,
    isTestAutomation: PropTypes.bool,
    historyState: PropTypes.string,
    t: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    routeState: RouterSelectors.getLocation(state),
    isTestAutomation: RouterSelectors.getQueryString(state, 'test_automation'),
    theme: UISelectors.getTheme(state),
    historyState: PatientHistorySelectors.getStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
    loading: (data) => dispatch(loading(data)),
    toggleTheme: (theme) => dispatch(toggleTheme(theme))
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
