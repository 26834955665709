import {Box, Dialog, IconButton, Link, Paper, Typography, withStyles} from '@material-ui/core';
import {LockOpen} from 'mdi-material-ui';
import * as PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, reduxForm} from 'redux-form';

import {timeLeft} from 'common/helpers/date';
import Events from 'common/helpers/Events';
import {required} from 'common/validate';

import AuthService from 'store/auth/AuthService';

import Avatar from 'views/components/Avatar';
import InputField from 'views/components/fields/InputField';
import {MFAField} from 'views/components/fields/MFAField';

const ScreenLock = ({
    theme,
    classes,
    user,
    handleSubmit,
    onSubmit,
    submitting,
    invalid,
    pristine,
    error,
    logoutTimeLeft,
    onClickChangeMethod,
    ...props
}) => {

    const {t} = useTranslation(['dashboard', 'mfa']);

    useEffect(() => {
        Events.on(window, 'onbeforeunload', () => {
            return t('dashboard:reload-message');
        });
        return function () {
            Events.off(window, 'onbeforeunload');
        };
    }, []);

    const handleChangeMFAMethod = (e) => {
        return user.mfa_enabled ? onClickChangeMethod(e) : undefined;
    };

    return (
        <Dialog
            fullScreen={true}
            open={true}
            keepMounted
            maxWidth={false}
        >
            <Box className={classes.lockedWrapper}>
                <div style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        position: 'absolute',
                        zIndex: theme.zIndex.appBar + 1,
                        top: `calc(${theme.spacing(-7)}px - 3px)`,
                        left: `calc(50% - ${theme.spacing(5)} - 3px)`
                    }}>
                        <Avatar
                            style={{
                                background: theme.palette.primary.main,
                                border: `6px solid ${theme.palette.common.white}`
                            }}
                            size={'xlg'}
                            path={user.photo}
                        >{user.initials.toUpperCase()}</Avatar>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Paper
                            elevation={8}
                            style={{
                                width: theme.spacing(60),
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                position: 'relative',
                                padding: theme.spacing(6, 3, 3, 3)
                            }}
                        >
                            <div style={{padding: theme.spacing(4, 0, 2, 0)}}>
                                <Typography align={'center'} variant={'h5'} fontWeight={'bold'}>
                                    {t('dashboard:screen-locked_title')}
                                </Typography>
                            </div>
                            <div style={{padding: theme.spacing(2, 0, 4, 0)}}>
                                <Typography variant={'caption'}>
                                    {t('dashboard:screen-locked_intro')} {t('dashboard:screen-locked-intro2', {unlock_type: user.mfa_enabled ? t('screen-locked-unlock_type_mfa') : t('screen-locked-unlock_type_password')})}
                                </Typography>
                            </div>
                            <Box style={{
                                padding: theme.spacing(2, 0),
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Field
                                    component={user.mfa_enabled ? MFAField : InputField}
                                    size={'small'}
                                    variant={'outlined'}
                                    name={'code'}
                                    type={user.mfa_enabled ? 'text' : 'password'}
                                    label={user.mfa_enabled ? t('dashboard:screen-locked-unlock_type_mfa') : t('dashboard:screen-locked-unlock_type_password')}
                                    style={{marginBottom: 0}}
                                    validate={[required]}
                                    handleSendMFACode={handleChangeMFAMethod}
                                    methods={['authenticator', user?.phone && 'sms', user?.email && 'email'].filter(Boolean)}
                                    endAdornment={(
                                        <IconButton
                                            disableTouchRipple
                                            disableFocusRipple
                                            type={'submit'}
                                            edge={'end'}
                                            variant={'outlined'}
                                        >
                                            <LockOpen/>
                                        </IconButton>
                                    )}
                                />
                            </Box>
                            <Typography
                                display={'block'}
                                component={'div'}
                                align={'center'}
                                variant={'caption'}
                            >
                                {t('dashboard:screen-locked-logout_timer', {logoutCounter: timeLeft(logoutTimeLeft)})}
                            </Typography>

                            <Typography
                                display={'block'}
                                component={'div'}
                                align={'center'}
                                variant={'caption'}
                                style={{cursor: 'pointer'}}
                            >
                                <Link onClick={e => AuthService.logout(true)}>{t('login:logout')}</Link>
                            </Typography>
                        </Paper>
                    </form>
                </div>
            </Box>
        </Dialog>
    );
};

ScreenLock.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    user: PropTypes.object,
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    lock: PropTypes.bool,
    error: PropTypes.string,
    logoutTimeLeft: PropTypes.number,
    logoutTimeout: PropTypes.number,
    showAt: PropTypes.number,
    warnAt: PropTypes.number,
    open: PropTypes.bool,
    onClickChangeMethod: PropTypes.func
};
const styles = theme => ({
    root: {},
    lockedWrapper: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        background: theme.palette.primary.dark
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    errorMessage: {
        color: theme.palette.danger.main,
        textAlign: 'center',
        fontSize: '1rem'
    }
});

export const LOCK_SCREEN_FORM_NAME = 'unlock-form';

export default reduxForm({
    form: LOCK_SCREEN_FORM_NAME
})(withStyles(styles, {withTheme: true})(ScreenLock));
