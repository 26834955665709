import _ from 'lodash';
import {createSelector} from 'reselect';
import {getRootStateModule} from 'store/module/ModuleSelectors';
import {getRootConfigState} from 'store/root/rootSelectors';
import UserSelectors from 'store/user/UserSelectors';

const emptyArray = [];
const emptyObject = {};

const getOrganizationState = (state) =>
    _.get(state, 'organizations', emptyObject);
const getOrganizationData = (state) =>
    _.get(state, 'organizations.data', emptyObject);

const getId = (state, id) => id || null;

export const getRootStateOrganization = createSelector(
    getOrganizationState,
    (organization) => organization || null
);

class OrganizationSelectorFactory {
    getState = getRootStateOrganization;

    getOrganizations = createSelector(
        [getOrganizationData, getRootConfigState],
        (data, config) =>
            _.chain(data)
                .values()
                .map((organization) => ({
                    ...organization,
                    organizations: _.pick(_.get(config, 'organizations', {}), _.get(organization, 'organizations', emptyArray))
                }))
                .sortBy([o => o?.name?.toLowerCase()])
                .value()
    );
    getOrganization = createSelector(
        [getOrganizationData, getId, getRootConfigState],
        (organization, id, config) => {
            const org = _.chain(organization).get(id).value();
            const organizations = _.pick(_.get(config, 'organizations', {}), _.get(organization, 'organizations', emptyArray));
            if (id && organizations && org) {
                Object.assign(org, {organizations});
            }
            return org;
        }
    );
    getCount = createSelector(
        getOrganizationData,
        (state) => _.chain(state).values().value().length
    );
    getOrganizationsTree = createSelector(
        [this.getOrganizations, UserSelectors.getMyOrganizationId],
        (organizations, rootOrganizationId) => {
            const organizationsIdsMap = organizations.reduce((acc, item) => {
                if (rootOrganizationId === item.id) {
                    acc['root'] = [item];

                    return acc;
                }
                else if (acc[item.organization_id]) {
                    acc[item.organization_id].push(item);

                    return acc;
                }

                acc[item.organization_id] = [item];

                return acc;
            }, {});

            const buildFromIdsMap = (id) => {
                if (!organizationsIdsMap[id]) {
                    return [];
                }

                return organizationsIdsMap[id].reduce((acc, item) => {
                    item.subOrganizations = buildFromIdsMap(item.id);

                    acc.push(item);

                    return acc;
                }, []);
            };

            return buildFromIdsMap('root');
        }
    );
    getCountry = createSelector(this.getState, (state) =>
        _.chain(state).get('countryId', '').value()
    );
    getStatus = createSelector(this.getState, (state) =>
        _.chain(state).get('status', '').value()
    );
    getLoading = (state) => this.getStatus(state) === 'loading'
    getError = createSelector(this.getState, (state) =>
        _.chain(state).get('error', null).value()
    );
    getModules = createSelector([this.getState, getId, getRootStateModule], (state, id, modules) => {
        const organization = _.chain(state).get(`data.${id}`, emptyObject).value();
        return _.chain(modules)
            .get('data')
            .pick(..._.get(organization, 'availableModules', emptyArray))
            .values()
            .map(module => ({
                ...module,
                selected: _.get(organization, 'modules', emptyArray).includes(module.id)
            })).value();
    });
}

export default new OrganizationSelectorFactory();
