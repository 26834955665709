import Config from 'common/Config';
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Dialog, DialogContent, DialogTitle, LinearProgress, withStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {timeLeft} from 'common/helpers/date';

const ScreenWarn = (props) => {
    const {theme, classes, screentimeLeft, screenTimeout} = props;
    const {t} = useTranslation(['dashboard', 'mfa']);
    const warnAt = Config.get('auth.screenLock.warnAt', 180);
    const showAt = Config.get('auth.screenLock.warnAt', 30);

    return (
        <Dialog
            fullScreen={false}
            open={screentimeLeft !== 0 && screentimeLeft <= showAt}
            keepMounted={false}
            maxWidth={'sm'}
        >
            <DialogTitle>{t('dashboard:screen-timeout_title')}</DialogTitle>
            <DialogContent>
                <Box pb={2} pt={2}>
                    {t('dashboard:screen-timeout_intro', {timeLeft: timeLeft(screentimeLeft)})}
                </Box>
                <Box pt={2} pb={2}>
                    <LinearProgress
                        classes={{
                            colorPrimary: classes.bar,
                            barColorPrimary: screentimeLeft < warnAt ? classes.colorSecondary : classes.colorPrimary
                        }}
                        color={'primary'}
                        variant={'determinate'}
                        value={screentimeLeft * 100 / (screenTimeout)}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

ScreenWarn.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    screentimeLeft: PropTypes.number,
    screenTimeout: PropTypes.number
};

const styles = theme => ({
    root: {},

    bar: {
        background: theme.palette.grey[300]
    },
    colorPrimary: {
        backgroundColor: theme.palette.success.main
    },
    colorSecondary: {
        backgroundColor: theme.palette.danger.main
    }
});

export default withStyles(styles, {withTheme: true})(ScreenWarn);
