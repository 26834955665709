/**
 * medical_history Reducer definitions
 */

export const CLEAR_MEDICAL_HISTORIES = 'medical_histories/clear';
export const GET_MEDICAL_HISTORIES_REQUEST = 'medical_histories/request';
export const GET_MEDICAL_HISTORIES_ERROR = 'medical_histories/error';
export const GET_MEDICAL_HISTORIES_SUCCESS = 'medical_histories/success';
export const CLEAR_MEDICAL_HISTORY = 'medical_history/clear';
export const GET_MEDICAL_HISTORY_REQUEST = 'medical_history/get/request';
export const GET_MEDICAL_HISTORY_ERROR = 'medical_history/get/error';
export const GET_MEDICAL_HISTORY_SUCCESS = 'medical_history/get/success';
export const UPDATE_MEDICAL_HISTORY_REQUEST = 'medical_history/update/request';
export const UPDATE_MEDICAL_HISTORY_ERROR = 'medical_history/update/error';
export const UPDATE_MEDICAL_HISTORY_SUCCESS = 'medical_history/update/success';
export const CREATE_MEDICAL_HISTORY_REQUEST = 'medical_history/create/request';
export const CREATE_MEDICAL_HISTORY_ERROR = 'medical_history/create/error';
export const CREATE_MEDICAL_HISTORY_SUCCESS = 'medical_history/create/success';
export const DELETE_MEDICAL_HISTORY_REQUEST = 'medical_history/delete/request';
export const DELETE_MEDICAL_HISTORY_ERROR = 'medical_history/delete/error';
export const DELETE_MEDICAL_HISTORY_SUCCESS = 'medical_history/delete/success';
